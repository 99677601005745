/* eslint-disable import/prefer-default-export */
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const signatureFieldUpdate = (fields, newFields) => {
  // Create a map from the newFields for quick lookups
  const newFieldsMap = new Map(
    newFields.map((field) => [field.name, { ...field }]),
  );
  // Iterate over fields and update them based on newFieldsMap
  const updatedFields = fields.map((field) => {
    if (newFieldsMap.has(field.name)) {
      return {
        ...field,
        ...newFieldsMap.get(field.name),
        isVariable: true,
      };
    }
    return { ...field, isVariable: false };
  });

  // Add new fields that are not in the original fields
  newFields.forEach((newField) => {
    if (!fields.find((field) => field.name === newField.name)) {
      updatedFields.push({ ...newField, isVariable: false });
    }
  });

  return updatedFields;
};


export const isValidUrl = (url) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol (compulsory)
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-zA-Z\\d_]*)?$', 'i', // fragment locator
  );
  return !!urlPattern.test(url);
};

export const isValidEmail = email => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};