/* eslint-disable no-console */
/* eslint-disable no-undef */
import { useState } from 'react';
import { toast } from './use-toast';

const redirectUri = window.location.origin;

const loadGoogleAPI = () =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client', resolve);
    };
    document.body.appendChild(script);
  });

const useGoogleWorkspaceUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const scopes = [
    'https://www.googleapis.com/auth/admin.directory.user.readonly',
  ].join(' ');

  const clearToken = () => {
    // Reset the token in gapi client
    if (window.gapi && window.gapi.client) {
      window.gapi.client.setToken(null);
    }
  };

  const listGoogleWorkspaceUsers = (token, modelRef) => {
    window.gapi.client.setToken({ access_token: token });
    window.gapi.client.directory.users
      .list({
        customer: 'my_customer',
        maxResults: 100,
        orderBy: 'email',
      })
      .then((response) => {
        const { users } = response.result;

        const usersWithPhoto = users.map((user) => ({
          id: user.id,
          name: user?.name?.fullName,
          email: user.primaryEmail,
          profileImage: user.thumbnailPhotoUrl || null,
        }));
        setUsers(usersWithPhoto);
        modelRef.current.open();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if ([403, 400].includes(error?.result?.error?.code)) {
          toast({
            description:
              'You need to be an admin of the Google Workspace to activate this integration.',
            closeicn: 'destructive',
          });
        }
        console.error('Error fetching users:', error);
      });
  };

  const handleFetchGoogleWorkspaceUsers = (modelRef) => {
    clearToken(); // Clear any existing token before proceeding

    const handleCredentialResponse = (response) => {
      if (response.credential) {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: scopes,
          prompt: 'consent', // Force the consent screen to be shown
          callback: (tokenResponse) => {
            if (tokenResponse.error) {
              console.error('Error obtaining token:', tokenResponse.error);
            } else {
              listGoogleWorkspaceUsers(tokenResponse.access_token, modelRef);
            }
          },
          ux_mode: 'redirect',
          redirect_uri: redirectUri,
        });
        tokenClient.requestAccessToken();
      }
    };

    loadGoogleAPI().then(() => {
      window.gapi.client
        .init({
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest',
          ],
        })
        .then(() => {
          window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
          });
        });
    });

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleCredentialResponse({ credential: code });
    }
    window.google.accounts.oauth2
      .initTokenClient({
        client_id: clientId,
        scope: scopes,
        prompt: 'consent', // Force the consent screen to be shown
        callback: (tokenResponse) => {
          if (tokenResponse.error) {
            console.error('Error obtaining token:', tokenResponse.error);
          } else {
            listGoogleWorkspaceUsers(tokenResponse.access_token, modelRef);
          }
        },
        ux_mode: 'redirect',
        redirect_uri: redirectUri,
      })
      .requestAccessToken();
  };

  return { users, loading, handleFetchGoogleWorkspaceUsers };
};

export default useGoogleWorkspaceUsers;
