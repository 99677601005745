// AddTeammateDialog.js
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Button } from 'components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWorkspaceContext } from 'context/WorkspaceContext';

const addTeammateSchema = z.object({
  groupId: z.string().optional().nullable(),
});

const AssignGroupDialog = forwardRef(({ onSubmit }, ref) => {
  const {
    state: { groups },
  } = useWorkspaceContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const form = useForm({
    resolver: zodResolver(addTeammateSchema),
    defaultValues: {
      groupId: null,
    },
  });

  const handleFormSubmit = async (values) => {
    setLoading(true);
    await onSubmit(values);
    setLoading(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent hideCloseButton className="rounded-[6px] max-w-md">
        <Form {...form}>
          <h1>Assign Group</h1>
          <form
            onSubmit={form.handleSubmit(handleFormSubmit)}
            className="space-y-3"
          >
            <FormField
              control={form.control}
              name="groupId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Group</FormLabel>
                  <Select onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          className="font-[#00000]"
                          placeholder="Select Group"
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {groups
                        .filter((g) => !!g?.templateId)
                        .map((group) => (
                          <SelectItem value={group.id} key={group.id}>
                            {group.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-3">
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button disabled={loading} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default AssignGroupDialog;
