import { Empty } from 'components/ui/empty';
import { Card, CardTitle } from 'components/ui/card';
import { RocketIcon } from 'assets/svg';
import CommonAnalyticsCard from 'components/CommonAnalyticsCard';
import { get, startCase } from 'lodash';
import React from 'react';
import Chart from 'react-google-charts';

export const options = {
  hAxis: {
    title: 'Months',
    textStyle: { bold: true },
  },
  vAxis: {
    title: 'Clicks',
    textStyle: { bold: true },
    format: 0,
    viewWindow: {
      min: 0,
    },
  },
  legend: 'none',
  colors: ['#7f56d9'],
};

function Clicks({
  hideCard,
  hideGraph,
  analyticsData,
  getLinksLoading = false,
  analyticsLinksData,
  color,
}) {
  const clicksData = get(analyticsData, 'clicks', {});
  const totalClicks = get(clicksData, 'clicks', null);
  const monthWise = get(analyticsLinksData, 'clicks.monthWise', []);

  if(color){
    options.colors =  [color]
  }
  const graphData = [
    ['Month', 'Clicks'],
    ...monthWise?.map(({ month, clicks }) => [startCase(month), clicks]),
  ];

  return (
    <div>
      <CommonAnalyticsCard label="Clicks" count={totalClicks} hidden={hideCard}>
        <RocketIcon stroke={color}  className="min-w-7" />
      </CommonAnalyticsCard>

      <div hidden={hideGraph}>
        <Card loading={getLinksLoading} className="shadow-none">
          <CardTitle className="font-primary text-h7 text-neutral-1000">
            Clicks
          </CardTitle>
          <div className="flex justify-center">
            {graphData?.length > 1 ? (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height="370px"
                data={graphData}
                options={options}
              />
            ) : (
              <Empty />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Clicks;
