import React from 'react';
import { EllipsisHorizontalIcon } from 'assets/svg';
import SignatureTemplate from 'components/SignatureTemplate';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { cn } from 'lib/utils';

const TemplateCard = ({
  data = [],
  actions = {},
  count,
  hideAction = false,
  handleTemplateCardOnClick,
  templateCardClassname,
  signatureTemplateClassname,
}) => {
  const {
    handleAssignToGroup = () => {},
    handleEdit = () => {},
    handleDelete = () => {},
    handleDuplicate = () => {},
  } = actions;
  const items = [
    // {
    //   onClick: (e) => {
    //     handleAssignToGroup(data);
    //   },
    //   label: <span>Assign to group</span>,
    //   key: '0',
    //   hide: false,
    // },
    {
      onClick: (e) => {
        handleEdit(data);
      },
      label: <span>Rename</span>,
      key: '1',
      hide: false,
    },
    {
      onClick: (e) => {
        handleDuplicate(data);
      },
      label: <span>Duplicate</span>,
      key: '2',
      // hide: count === 1,
    },

    {
      onClick: (e) => {
        handleDelete(data);
      },
      label: <span>Delete</span>,
      key: '3',
      hide: count === 1,
    },
  ];

  return (
    <div
      onClick={() => handleTemplateCardOnClick(data)}
      // onClick={handleOnClick}
      className={cn(
        `bg-white-0 relative flex justify-start
max-w-[350px] min-w-[350px] h-[220px] rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2
bg-white border-[1px] border-solid border-secondary-400 hover:border-solid hover:border-[1px]  hover:border-primary hover:shadow-signature`,
        templateCardClassname,
      )}
    >
      <div className="w-full ">
        <div
          className={cn(
            'w-full transform scale-[0.7] translate-x-[-40px] pointer-events-none',
            signatureTemplateClassname,
          )}
        >
          {data && <SignatureTemplate signature={data} showAwsIcons />}
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="absolute bottom-0 w-full box-border flex justify-between items-center px-3 py-2 bg-primary-foreground"
      >
        <div>{data?.name}</div>
        {!hideAction && (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <EllipsisHorizontalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" bg-white-0 border-solid border-1 border-gray-400 ">
                {items.map(({ label, key, onClick, hide = false }) => {
                  if (hide) {
                    return;
                  }
                  return (
                    <DropdownMenuItem
                      key={key}
                      value={key}
                      onClick={onClick}
                      className="w-[auto]"
                    >
                      {label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateCard;
