/* eslint-disable no-restricted-globals */
import React from 'react';
import { useRouter } from 'hooks/router';
import ProfileAvatar from 'components/ProfileAvatar';
import HeadShot from 'components/HeadshotList/components/Headshot';
import DownloadHeadshot from 'components/DownloadHeadshot';
import Button from 'components/Button';
import CustomImageUploader from 'components/CustomImageUploader';
import { useWindowSize } from 'helpers/useWindowSize';
import { PRIMARY_COLOR } from 'common/constants';
import { useAppContext } from 'AppContext';
import { useSignatureId, useUpsertSignature } from 'hooks/signature';
import { get } from 'lodash';
import SignatureSaveButton from 'components/SignatureSaveButton';
import AiHeadshotButton from 'components/AiHeadshotButton';
import { Zap } from 'lucide-react';
import { useHeadshotUpload } from 'hooks/headshot';
import { useWorkspaceId } from 'hooks/workspace';

function Headshot() {
  const {
    state: { headshot, signature },
  } = useAppContext();

  const { size } = useWindowSize();
  const { handleGlobalHeadshotUpload } = useHeadshotUpload();

  const config = get(headshot, 'config', {});
  const style = get(headshot, 'style', {});
  const image = get(headshot, 'image', null);

  const { navigate } = useRouter();
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const [upsertSignature] = useUpsertSignature();
  const continueWithHeadshot = async () => {
    const updatedHeadshot = await handleGlobalHeadshotUpload(
      headshot,
      `${headshot?.style?.key}-round`,
    );
    const previousDownloadUrl = updatedHeadshot?.config?.output
      ? `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${updatedHeadshot?.config?.output}`
      : '';
    const { id, ...restData } = signature;
    const sigabatureClone = { ...restData };
    const updatedFields = sigabatureClone?.fields?.map((field) =>
      field.name === 'headshotUrl'
        ? { ...field, value: previousDownloadUrl }
        : field,
    );

    if (!updatedFields.some((field) => field.name === 'headshotUrl')) {
      updatedFields.push({
        name: 'headshotUrl',
        value: previousDownloadUrl,
        type: 'employee',
        isVariable: true,
        variableValue: null,
        label: 'Headshot URL',
        utagEnabled: false,
        link: '',
        shortLink: '',
      });
    }

    await upsertSignature({
      variables: {
        data: { ...restData, fields: updatedFields },
        where: {
          signatureId: id,
          workspaceId,
        },
      },
    });
    navigate(`/app/${workspaceId}/signature/${signatureId}/information`);
  };

  return (
    <div className="w-full">
      <br />
      {!['xs', 'sm', 'md'].includes(size) && (
        <header
          className={`p-4 max-h-[102px] flex items-center   ${'justify-end'}`}
        >
          {/* <div className="mr-2">
            <SignatureSaveButton />
          </div> */}
          {/* <AiHeadshotButton className=" mr-4 flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]">
            Try AI Headshot
            <Zap size={16} className="ml-[6px] fill-white-0" />
          </AiHeadshotButton> */}
          {/* <ProfileAvatar /> */}
        </header>
      )}

      {headshot?.id ? (
        <>
          <div
            className={`${'mt-[50px]'} flex justify-center w-full gap-x-12 flex-wrap gap-y-12`}
          >
            <div className="relative">
              <HeadShot
                image={image}
                type={style?.key}
                id={`${style?.key}-round`}
                currentColor={config?.['background-color'] || PRIMARY_COLOR}
                rounded
                height={337}
                width={337}
                downloadable
                modalType="round"
              />

              <DownloadHeadshot modalType="round" type={style?.key} />
            </div>
            <div className="relative">
              <HeadShot
                image={image}
                type={style?.key}
                id={`${style?.key}-square`}
                currentColor={config?.['background-color'] || PRIMARY_COLOR}
                height={337}
                width={337}
                downloadable
                modalType="square"
              />

              <DownloadHeadshot modalType="square" type={style?.key} />
            </div>
          </div>
          {!['xs', 'sm', 'md'].includes(size) ? (
            <div className="flex w-full justify-center pb-4">
              <Button
                className="h-[40px]  mt-[80px] "
                onClick={continueWithHeadshot}
              >
                <div className="  font-bold">
                  Use this as signature headshot
                </div>
              </Button>
            </div>
          ) : (
            <div className="pb-8" />
          )}
        </>
      ) : (
        <div
          className="text-darkblue font-primary text-h6 font-bold w-full  flex items-center justify-center flex-col"
          style={{ height: 'calc(90vh - 102px)' }}
        >
          <div>
            {!['xs', 'sm'].includes(size) && (
              <CustomImageUploader noImageUploadedTitle="Upload image to edit" />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Headshot;
