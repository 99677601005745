/* eslint-disable import/no-cycle */
import { Loader2, Moon, Sun, ArrowLeft } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'hooks/router';
import { useFeatures, useUserPermission } from 'hooks/user';
import {
  useSignatureId,
  useUpsertSignature,
  useSaveSignature,
} from 'hooks/signature';
import { TEXT_IDS, TEXT_IDS_ARRAY } from 'common/constants';
import { uploadAllImages } from 'common/utils';
import Button from 'components/Button';
import { useAppContext } from 'AppContext';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import ProfileAvatar from 'components/ProfileAvatar';
import SignatureSaveButton from 'components/SignatureSaveButton';
import { useWorkspaceId, useRedirectToWorkspace } from 'hooks/workspace';
import SaveSignatureDialog from 'components/SaveSignatureDialog';
import SignaturePreview from './SignaturePreview';

function Signature() {
  const {
    state: { signature, baseImages, signatureIconsUploading },
    setHeadshotUpdateStatus,
    handleSubscriptionModal,
  } = useAppContext();

  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const [isMobile] = useState(false);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { isUserUsingProFeatures } = useFeatures();
  const [upsertSignature] = useUpsertSignature();

  const { isSignatureChanged, isDesignStyleChanged,saveChanges } = useSaveSignature();
  const saveSignatureDialogRef = useRef();
  const { redirectToWorkspace } = useRedirectToWorkspace();

  const handleDarkMode = (value) => {
    const isDark = value === 'true'; // Convert string value to boolean
    setIsDarkMode(isDark);

    TEXT_IDS_ARRAY.forEach((id) => {
      const textId = TEXT_IDS[id];
      // eslint-disable-next-line no-undef
      const textElement = document.getElementById(textId);

      if (textElement) {
        textElement.style.color = isDark ? 'white' : 'black'; // Simplified fallback color
      }
    });
  };
  const getSignature = async () => {
    setUploadingSignature(true);
    const { id, ...restData } = signature;

    try {
      if(isDesignStyleChanged){
        const response = await uploadAllImages(baseImages, signatureId);
        restData.design.icons = response;
      }

      await upsertSignature({
        variables: {
          data: restData,
          where: {
            signatureId,
            workspaceId,
          },
        },
      });
      setHeadshotUpdateStatus(false);
      setUploadingSignature(false);
      navigate(`/app/${workspaceId}/signature/${signatureId}/email-signature`);
    } catch (error) {
      const response = await uploadAllImages(baseImages, signatureId);
      restData.design.icons = response;
      await upsertSignature({
        variables: {
          data: restData,
          where: {
            signatureId,
            workspaceId,
          },
        },
      });
      setHeadshotUpdateStatus(false);
      setUploadingSignature(false);
      navigate(`/app/${workspaceId}/signature/${signatureId}/email-signature`);
    }
  };

  const handleCheckSaveChanges = () => {
    if (isSignatureChanged) {
      saveSignatureDialogRef?.current?.open();
    } else {
      redirectToWorkspace();
    }
  };

  const handleSaveChanges = () => {
    saveChanges();
    saveSignatureDialogRef?.current?.close();
    if (!isUserUsingProFeatures()) {
      redirectToWorkspace();
    }
  };

  return (
    // signature-preview-section
    <>
      <SaveSignatureDialog
        handleSaveChanges={handleSaveChanges}
        ref={saveSignatureDialogRef}
      />
      <div className="h-[calc(100dvh_-_71px)] bg-[#f5f5f5]">
        <div className="h-[60px] flex items-center justify-between p-4 lg:p-0 text-regular-base font-primary  ">
          <div className="flex items-center gap-4">
            <h1 className="flex items-center font-primary text-h6 font-bold text-neutral-1000">
              <Button
                onClick={handleCheckSaveChanges}
                variant="text"
                className="px-0 py-0 mr-2"
              >
                <ArrowLeft />
              </Button>
              {signature?.name || ''}
            </h1>
          </div>
          <div className="flex gap-4">
            <SignatureSaveButton />
            {/* {templateData && (
            <TemplatesModel
              open={templateModal}
              setOpen={setTemplateModal}
              data={templateData}
              handleTemplateSelect={handleTemplateSelect}
            />
          )} */}
            <Button
              className="pl-[16px] pr-[16px] h-[40px]"
              onClick={() => {
                if (isUserUsingProFeatures()) {
                  handleSubscriptionModal(true);
                  return;
                }
                getSignature();
              }}
              disabled={signatureIconsUploading}
            >
              {uploadingSignature && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Get signature
            </Button>
            {/* <ProfileAvatar /> */}
          </div>
        </div>
        <div className="h-[102px] flex items-center justify-between p-4 pt-0">
          <div>
            <div className="font-primary text-h7 font-bold ml-6">
              Signature preview
            </div>
            <div className="text-secondary-500 mt-[8px] ml-6">
              This information will be visible on your email signature preview.
            </div>
          </div>

          <div className="flex gap-4">
            <div
              className="flex items-center cursor-pointer underline text-[#144CC7] font-primary font-bold"
              onClick={() => {
                navigate(`${pathname}?showTemplate=true&&isCreate=false`);
              }}
            >
              Change Template
            </div>
            <div className="flex items-center">
              <Tabs defaultValue="false" onValueChange={handleDarkMode} asChild>
                <TabsList className="bg-primary-foreground px-1 rounded-full ">
                  <TabsTrigger
                    value="false"
                    className="rounded-full flex items-center justify-center border-0 p-[5px] bg-[transparent] data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer"
                  >
                    <Sun size={20} />
                  </TabsTrigger>
                  <TabsTrigger
                    value="true"
                    className="rounded-full flex items-center justify-center border-0 p-[5px] bg-[transparent] data-[state=active]:bg-primary data-[state=active]:text-white-0 cursor-pointer"
                  >
                    <Moon size={20} />
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
          </div>
        </div>

        <div
          className="overflow-y-hidden"
          style={{ height: 'calc(100% - 225px)' }}
        >
          <div className=" flex justify-center mt-3 ml-8 h-full ">
            <div
              className={` overflow-hidden w-full h-full transition-all duration-200 ease-linear border-t-[1px] border-t-lightpink border-l-[1px] border-l-lightpink rounded-tl-[8px] border-tl-lightpink ${
                isDarkMode ? 'bg-neutral-900' : 'bg-white-0'
              } ${
                isMobile
                  ? '   w-[325px] border-[1px] border-lightpink rounded-[8px]'
                  : ''
              }`}
            >
              <SignaturePreview isDarkMode={isDarkMode} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signature;
