import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { useGroupId, useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';

const FormSchema = z.object({
  items: z.array(z.string()).min(1, 'You have to select at least one item.'),
});

const TeammateListing = ({ teammatesData, handleAddTeammates }) => {
  const {
    state: { workspaces },
  } = useWorkspaceContext();
  const { workspaceId } = useWorkspaceId();
  const currentWorkspace = workspaces.find((w) => w.id === workspaceId);

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: { items: [] },
  });

  const selectedItems = useWatch({
    control: form.control,
    name: 'items',
  });

  const onSubmit = ({ items }) => {
    handleAddTeammates(items);
  };

  const filteredTeammateData = teammatesData;

  const isAddButtonDisabled = () =>
    selectedItems.length === 0 ||
    selectedItems.length > currentWorkspace?.signatureLimit;

  const getAddButtonText = () =>
    `Add ${selectedItems.length} ${selectedItems.length === 1 ? 'Teammate' : 'Teammates'}`;

  const getFormMessage = () =>
    selectedItems.length > currentWorkspace?.signatureLimit
      ? `Cannot add more than ${currentWorkspace?.signatureLimit} teammates`
      : '';
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="max-h-[200px] overflow-auto relative">
            {filteredTeammateData.map(
              ({ workspaceTeammateDetails: teammate,id }) => (
                <FormField
                  key={id}
                  control={form.control}
                  name="items"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-3 space-y-0 rounded-lg hover:bg-gray-100 p-3">
                      <FormControl>
                        <Checkbox
                          checked={field.value.includes(id)}
                          onCheckedChange={(checked) =>
                            checked
                              ? field.onChange([...field.value, id])
                              : field.onChange(
                                  field.value.filter(
                                    (value) => value !== id,
                                  ),
                                )
                          }
                        />
                      </FormControl>
                      <div className="flex items-center space-x-3">
                        <Avatar className="w-8 h-8">
                          <AvatarImage
                            src={teammate.avatarUrl}
                            alt={teammate.name}
                          />
                          <AvatarFallback>
                            {teammate?.name?.charAt(0) || ''}
                          </AvatarFallback>
                        </Avatar>
                        <div>
                          <FormLabel className="font-medium text-gray-900">
                            {teammate.name}
                          </FormLabel>
                          <div className="text-sm text-gray-500">
                            {teammate.email}
                          </div>
                        </div>
                      </div>
                    </FormItem>
                  )}
                />
              ),
            )}
            <FormMessage />
          </div>

          <Button type="submit" disabled={isAddButtonDisabled()}>
            {getAddButtonText()}
          </Button>
          <FormMessage>{getFormMessage()}</FormMessage>
        </form>
      </Form>
    </div>
  );
};

export default TeammateListing;
