/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTwentyFive({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  return (
    <table
      // width={tableWidth}
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr
                  style={{
                    marginBottom: '8px',
                  }}
                >
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {(getFieldData(fields, 'headshotUrl') ||
                              getFieldData(fields, 'companyLogo')) && (
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  paddingRight: '20px',
                                }}
                              >
                                <tbody>
                                  {getFieldData(fields, 'headshotUrl') && (
                                    <tr align="center">
                                      {HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                        style: {
                                          paddingBottom: '9px',
                                        },
                                      })}
                                    </tr>
                                  )}
                                  {getFieldData(fields, 'companyLogo') && (
                                    <tr align="center">
                                      {CompanyLogo({
                                        data: getFieldData(
                                          fields,
                                          'companyLogo',
                                        ),
                                        design,
                                        style: {},
                                      })}
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            )}
                          </td>

                          {!allUrlsAreNull && (
                            <td
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 0,
                                lineHeight: '100%',
                                paddingLeft: '20px',
                                borderLeft: `1px solid rgb(221, 237, 216)`,
                              }}
                            >
                              <table
                                cellPadding="0px"
                                cellSpacing="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    {getFieldData(fields, 'name') &&
                                      Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 300,
                                          fontFamily: 'inherit',
                                          color: '#000000',
                                        },
                                      })}
                                  </tr>
                                  <tr valign="center">
                                    {getFieldData(fields, 'position') &&
                                      Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                  <tr>
                                    {getFieldData(fields, 'department') &&
                                      Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                  <tr>
                                    {getFieldData(fields, 'companyName') &&
                                      CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        paddingRight: '14px',
                                        paddingTop: '16px',
                                      }}
                                    >
                                      <table
                                        cellPadding="0px"
                                        cellSpacing="0"
                                        border="0"
                                      >
                                        <tbody>
                                          <td>
                                            <tr>
                                              {getFieldData(fields, 'phone') &&
                                                Phone({
                                                  signature,
                                                  showAwsIcons,
                                                  design,
                                                  data: getFieldData(
                                                    fields,
                                                    'phone',
                                                  ),
                                                  style: {},
                                                })}
                                            </tr>
                                            {getFieldData(fields, 'email') && (
                                              <tr>
                                                {Email({
                                                  signature,
                                                  showAwsIcons,
                                                  design,
                                                  data: getFieldData(
                                                    fields,
                                                    'email',
                                                  ),
                                                })}
                                              </tr>
                                            )}
                                            {getFieldData(
                                              fields,
                                              'website',
                                            ) && (
                                              <tr>
                                                {Website({
                                                  signature,
                                                  showAwsIcons,
                                                  design,
                                                  data: getFieldData(
                                                    fields,
                                                    'website',
                                                  ),
                                                })}
                                              </tr>
                                            )}
                                          </td>
                                        </tbody>
                                      </table>
                                    </td>

                                    <td
                                      style={{
                                        paddingTop: '16px',
                                      }}
                                    >
                                      <table
                                        cellPadding="0px"
                                        cellSpacing="0"
                                        border="0"
                                      >
                                        <tbody>
                                          {getFieldData(
                                            fields,
                                            'companyAddress',
                                          ) && (
                                            <tr>
                                              {CompanyAddress({
                                                signature,
                                                showAwsIcons,
                                                design,
                                                data: getFieldData(
                                                  fields,
                                                  'companyAddress',
                                                ),
                                                style: {},
                                                breakAtCommas: true,
                                              })}
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  {!socialFields?.every(
                                    (obj) => obj?.value === '',
                                  ) && (
                                    <tr>
                                      {SocialLinks({
                                        signature,
                                        showAwsIcons,
                                        data: socialFields,
                                        design,
                                        style: { paddingTop: '8px' },
                                      })}
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table ref={extraContentRef}>
                      <tr>
                        {getFieldData(fields, 'ctaUrl') &&
                          getFieldData(fields, 'ctaTitle') &&
                          CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                            style: {
                              paddingTop: '10px',
                            },
                          })}
                      </tr>

                      <tr>
                        {getFieldData(fields, 'ctaBannerImg') &&
                          getFieldData(fields, 'ctaBannerUrl') &&
                          CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left', paddingTop: '10px' },
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table width={tableWidth}>
                      <tr>
                        {getFieldData(fields, 'footer') &&
                          Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleTwentyFive;
