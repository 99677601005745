import { useAppContext } from 'AppContext'
import { useQuery } from '@apollo/client'
import { Switch } from 'components/ui/switch'
import { get, isString } from 'lodash'
import React from 'react'
import CustomImageUploader from 'components/CustomImageUploader'
import ColorPicker from 'components/ColorPicker'
import { useWindowSize } from 'helpers/useWindowSize'
import HeadshotList from 'components/HeadshotList'
import UserProfile from 'components/UserProfile'
import { GET_STYLES } from '../graphql/Queries'

function HeadshotControl () {
  const {
    state: { headshot, isTablet },
    setHeadshot,
  } = useAppContext()

  const config = get(headshot, 'config', {});

  const { size } = useWindowSize();

  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      fetchPolicy: 'network-only',
      onCompleted (res) {
        if (!headshot?.style?.id && headshot?.id) {
          const { __typename, ...styles } = res?.styles?.data?.[0] || {}

          setHeadshot({
            ...headshot,
            style: styles,
            styleId: styles?.id,
          })
        }
      },
      onError() {},
    },
  )

  const debouncedHandleColorChange = async newHeadShot => {
    setHeadshot(newHeadShot)
  }

  const handleColorChange = value => {
    const color = isString(value) ? value : `#${value?.toHex()}`
    const newHeadShot = {
      ...headshot,
      config: {
        ...headshot?.config,
        'background-color': color,
      },
    }
    debouncedHandleColorChange(newHeadShot)
  }
  const handleMonochromeChange = value => {
    const newHeadShot = {
      ...headshot,
      config: {
        ...headshot?.config,
        grayScale: value,
      },
    }
    debouncedHandleColorChange(newHeadShot)
  }

  const handleSelect = async ref => {
    const { __typename, ...updatedStyleData } = stylesData?.find(
      d => d.key === ref.current.id,
    )

    const newHeadShot = {
      ...headshot,
      style: { ...headshot?.style, ...updatedStyleData },
      styleId: updatedStyleData?.id,
    }

    debouncedHandleColorChange(newHeadShot)
  }

  return (
    <div>
      <header
        className={`max-w-[400] px-[16px]   ${
          isTablet ? 'h-[80px]' : 'h-[102px]'
        } flex items-center justify-between  `}
      >
        <h1 className='font-primary text-h6 font-bold text-neutral-1000'>
          Headshot editor
        </h1>
        {/* {['xs', 'sm', 'md'].includes(size) && <SignatureSaveButton />} */}
        {['xs', 'sm', 'md'].includes(size) && <UserProfile />}
      </header>

      <div className="p-4 ">
        <div className="text-darkblue font-semibold text-[12px]">
          Upload Image{' '}
        </div>
        <CustomImageUploader />
        <section className=" flex justify-between gap-[48px] mt-[24px]">
          <div className="text-darkblue font-semibold text-[12px]">
            Monochrome
          </div>
          <div className="flex justify-center gap-1 ">
            <Switch
              onCheckedChange={handleMonochromeChange}
              checked={headshot?.config?.grayScale}
              disabled={!headshot?.id}
              className="h-6"
            />
          </div>
        </section>
        {headshot?.id && (
          <div className='mt-[24px]'>
            <div className="text-darkblue font-semibold text-[12px]">
              Theme color
            </div>
            <section className="flex gap-[12px]">
              <div className="flex justify-center gap-1 w-full">
                <ColorPicker
                  value={config?.['background-color']}
                  onChange={handleColorChange}
                  isInputShow
                  isPresetShow
                  hideApplyButton
                  className='signature-color-picker'
                  disabled={!headshot?.id}
                />
              </div>
            </section>
          </div>
        )}

        {headshot?.id ? (
          <div>
            <div className="text-darkblue font-semibold text-[12px] my-6">
              Select Style{' '}
            </div>
            <HeadshotList
              colorPicked={config?.['background-color']}
              svgColor='white'
              rounded
              height={164}
              width={164}
              afterLogin
              clickable
              handleSelect={handleSelect}
              stylesData={stylesData}
              headshotData={headshot}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default HeadshotControl
