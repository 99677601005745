/* eslint-disable no-undef */
/* eslint-disable no-lone-blocks */
import { useAppContext } from 'AppContext';
import { Loader2, ChevronRight } from 'lucide-react';
import { useMutation } from '@apollo/client';
import { Separator } from 'components/ui/separator';
import { toast } from 'hooks/use-toast';
import React from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import LoginCover from 'assets/images/login-cover.png';
// import { formValidatorRules } from 'common/utils';
import { useRouter } from 'hooks/router';
import { GoogleIcon } from 'assets/svg';
import darkLogo from 'assets/png/logo-dark.png';
import aiSignup from 'assets/images/ai-headhsot-signup.jpg';
import { Input } from 'components/ui/input';
import { EMAIL_PLACEHOLDER, JOURNEYS, REGEX, TOKEN } from 'common/constants';
import { usePosthogEvents } from 'hooks/posthog';
import { cn } from 'lib/utils';
import { useUserJourney } from 'hooks/auth';
import { useGetBrandDetails } from 'hooks/brand';
import { Button } from 'components/ui/button';
import { Password } from 'components/Inputs';
import { signInWithGooglePopup } from './firebase';
import { LOGIN, SIGNUP } from './graphql/Mutations';

// const { required, email } = formValidatorRules;

const formSchema = z.object({
  email: z.string().min(1, { message: 'Email is required.' }).email({
    message: 'Please enter a valid email address.',
  }),
  password: z.string().min(1, { message: 'Password is required.' }),
});

const Signup = () => {
  const { logoUrl, name } = useGetBrandDetails();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '', password: '' },
  });

  const { navigate } = useRouter();
  const { initializeAuth } = useAppContext();
  const { capture } = usePosthogEvents();
  const { journey } = useUserJourney();

  const captureSigupEvent = () => {
    const storedJourney = localStorage.getItem('journey');
    if (storedJourney === JOURNEYS.AI_HEADSHOT) {
      capture('Signup through Headshot URL');
    }
  };

  const [signupMutate, { loading: signupLoading }] = useMutation(SIGNUP, {
    onError() {}, // Always write this method for error handling in all mutation.
  });
  const [SocialLogin, { loading: socialLoading }] = useMutation(LOGIN, {
    onCompleted({ loginUser }) {
      const { accessToken, data, refreshToken } = loginUser;
      const userData = data;
      const tokenRefresh = refreshToken;
      localStorage.setItem(TOKEN, accessToken);
      initializeAuth(accessToken, userData, tokenRefresh, { isSignUp: true });
      captureSigupEvent();
    },
    onError() {},
  });
  function successCallback(accessToken, userData, refreshToken) {
    localStorage.setItem(TOKEN, accessToken);
    initializeAuth(accessToken, userData, refreshToken, { isSignUp: true });
  }
  function successCallbackSocialAuth(accessToken) {
    SocialLogin({
      variables: {
        data: { token: accessToken },
      },
    });
  }
  const handleGoogleSignIn = async () => {
    if (signupLoading) return;
    try {
      const { user } = await signInWithGooglePopup();
      if (user?.accessToken) {
        const accessToken = user?.accessToken;
        if (successCallbackSocialAuth) {
          successCallbackSocialAuth(accessToken);
        }
      }
    } catch (error) {
      // do nothing
    }
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await signupMutate({
        variables: { data: { ...formValues } },
      });

      const { accessToken, data, refreshToken } = response?.data?.signUp || {};

      if (response?.data?.signUp) {
        const userData = data;
        const tokenRefresh = refreshToken;
        captureSigupEvent();

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.reset(values);
      }
    } catch (error) {
      if (error?.message) {
        // message?.error(error?.message);
        toast({
          closeicn: 'destructive',
          description: error?.message,
        });
      } else {
        // message?.error('Something went wrong');
        toast({
          closeicn: 'destructive',
          description: 'Something went wrong',
        });
      }
    }
  };
  return (
    <div className="flex min-h-screen h-full max-h-screen">
      <div
        className={cn(
          'flex flex-col justify-center items-center w-full bg-primary-foreground p-[16px] overflow-auto',
          { 'bg-[#0A0B0C]': journey === JOURNEYS.AI_HEADSHOT },
        )}
      >
        <div className="w-full  max-w-[600px] flex flex-col items-center justify-center">
          <img
            src={
              journey === JOURNEYS.AI_HEADSHOT
                ? darkLogo
                : logoUrl || '/logo.svg'
            }
            height={40}
            width="auto"
            alt="logo"
          />
          <h1
            level={1}
            className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mt-8"
          >
            Get started with {name || 'SyncSignature'}
          </h1>
          {/* <div
            className={cn(
              'text-darkblue text-medium-l font-primary  bg-primary-foreground mb-2 mt-8',
              {
                'bg-[#0A0B0C] text-white-0 text-[18px]':
                  journey === JOURNEYS.AI_HEADSHOT,
              },
            )}
          >
            Sign Up
          </div> */}

          <Button
            variant="outline"
            onClick={handleGoogleSignIn}
            className={cn(
              'h-[40px] w-full font-primary text-button-s  bg-white-0  !mt-[16px] flex items-center   justify-center',
              {
                ' bg-[#0A0B0C] outline-none px-[16px] py-[8px] border-1 border-primary-400 text-white-0':
                  journey === JOURNEYS.AI_HEADSHOT,
              },
            )}
            disabled={signupLoading}
          >
            <div className="flex justify-center items-center ">
              <GoogleIcon />
              <span className="ml-2">Continue with Google</span>
            </div>
          </Button>
          <div className="flex items-center my-4 w-full">
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
            <div className="text-secondary-400 mx-2 text-center text-xs font-semibold leading-[24px] font-primary whitespace-nowrap">
              OR
            </div>
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onFinish)}
              form={form}
              initialValues={{ remember: true }}
              className="space-y-[27px] w-full"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem
                    // rules={[
                    //   { required, message: 'Please enter email!' },
                    //   email,
                    // ]}
                    className=" font-medium  text-[12px]"
                  >
                    <FormLabel
                      className={
                        (cn(' text-darkblue'),
                        {
                          ' text-white-0 ': journey === JOURNEYS.AI_HEADSHOT,
                        })
                      }
                    >
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                        placeholder={EMAIL_PLACEHOLDER}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="  font-medium  text-[12px]">
                    <FormLabel
                      className={
                        (cn(' text-darkblue'),
                        {
                          ' text-white-0 ': journey === JOURNEYS.AI_HEADSHOT,
                        })
                      }
                    >
                      {/* <span className="text-[#FF0000] text-[16px]"> * </span> */}
                      Password
                    </FormLabel>
                    <FormControl>
                      <Password
                        type="password"
                        className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                        placeholder="Minimum 8 characters"
                        maxLength={16}
                        loading={signupLoading || socialLoading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button htmlType="submit" className="h-[40px] w-full ">
                {(signupLoading || socialLoading) && (
                  <Loader2 className="animate-spin mr-2 h-5 w-5 " />
                )}
                <div className="flex items-center space-x-1">
                  <span>Sign up</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
            </form>
          </Form>

          <p className=" text-secondary-400 text-medium-base font-primary  font-[500]  flex justify-center">
            Already have an account?
            <div
              className={cn('text-secondary-400 pl-1 cursor-pointer', {
                ' text-primary ': journey === JOURNEYS.AI_HEADSHOT,
              })}
              onClick={() =>
                navigate(
                  `/auth/login${
                    journey === JOURNEYS.AI_HEADSHOT
                      ? `?r=${JOURNEYS.AI_HEADSHOT}`
                      : ''
                  }`,
                )
              }
            >
              <u>Login</u>
            </div>
          </p>

          {/* <Divider>
            <span className="text-secondary-400 text-center text-xs font-semibold leading-[24px] font-primary">
              Or, sign up in with email
            </span>
          </Divider> */}

          <div className="mt-10 text-secondary-400 text-center font-[500] font-primary text-xs  leading-[24px]">
            <div>By clicking the sign up button above,</div>
            <div className="text-secondary-400 text-center font-primary text-xs font-[500] leading-[24px]">
              you agree to our{' '}
              <a
                href="https://syncsignature.com/terms"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                }}
              >
                <span
                  className={cn('text-secondary-600 text-12 font-medium', {
                    'text-primary': journey === JOURNEYS.AI_HEADSHOT,
                  })}
                >
                  Terms of Use{' '}
                </span>
              </a>
              and{' '}
              <a
                href="https://syncsignature.com/privacy"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: 'none',
                }}
              >
                <span
                  className={cn('text-secondary-600 text-12 font-medium', {
                    'text-primary': journey === JOURNEYS.AI_HEADSHOT,
                  })}
                >
                  Privacy Statement
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full h-[53px]" />
      </div>
      {/* <div
        style={{
          backgroundImage: `url(${
            journey === JOURNEYS.AI_HEADSHOT ? aiSignup : LoginCover
          })`,
        }}
        className="lg:hidden flex w-1/2 min-h-full gap-2.5 shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center "
      /> */}
    </div>
  );
};

export default Signup;
