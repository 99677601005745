/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { aiHeadshotRoutes } from 'routes';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { useRouter } from 'hooks/router';
import { useFeatureChecks } from 'hooks/brand';
import DarkBg from '../../assets/svg/darkbg.svg';
import Header from './Header';

const AiHeadshotLayout = () => {
  const { getRoutes } = useGetRoutes();
  const { navigate } = useRouter();
  const { isAIHeadshotsAllowed } = useFeatureChecks();

  useEffect(() => {
    localStorage.removeItem('journey');
  }, []);

  useEffect(() => {
    if (!isAIHeadshotsAllowed) {
      navigate('/app/user/profile');
    }
  }, [isAIHeadshotsAllowed]);

  return (
    <div
      style={{
        backgroundImage: `url(${DarkBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Header />
      <div className="h-[calc(100dvh_-_71px)] overflow-auto">
        <Routes>
          {getRoutes(aiHeadshotRoutes)}
          <Route path="*" element={<Navigate to="/app/ai-headshot" />} />
        </Routes>
      </div>
    </div>
  );
};

export default AiHeadshotLayout;
