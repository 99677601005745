/* eslint-disable arrow-body-style */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useCreateWorkspace } from 'hooks/workspace';
import { get } from 'lodash';
import { useRouter } from 'hooks/router';

const schema = z.object({
  workspaceName: z.string().min(1, 'Workspace name is required'),
  signatureLimit: z
    .string()
    .min(1, 'Signature limit is required')
    .regex(/^\d+$/, 'Signature limit must be a number'),
});

const CreateWorkspaceDialog = forwardRef((_,ref) => {
  const {  navigate } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const [createWorkspace] = useCreateWorkspace();

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      workspaceName: '',
      signatureLimit: '',
    },
  });


  const { handleSubmit, control } = form;

  const handleContinue = async (values = {}) => {
    const res = await createWorkspace({
      variables: {
        data: {
          ...values,
        },
      },
    });

    const createWorkspaceId = get(res, 'data.createWorkspace.data.id', null);
    if (createWorkspaceId) {
      navigate(`/app/workspace/${createWorkspaceId}/assets`);
    }
    setIsOpen(false);
  };

  const onSubmit = (data) => {
    handleContinue({
      name: data.workspaceName,
      signatureLimit: parseInt(data.signatureLimit, 10),
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="rounded-[6px] max-w-[600px]" hideCloseButton>
        <DialogHeader>
          <DialogTitle>Add Workspace</DialogTitle>
          <DialogDescription>
            Add a new team to manage products and customers.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4 py-2 pb-4">
              <FormField
                name="workspaceName"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="workspaceName">Workspace name</FormLabel>
                    <FormControl>
                      <Input
                        id="workspaceName"
                        className="h-[23px] py-1"
                        placeholder="My Workspace"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="signatureLimit"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="signatureLimit">Signature Limit</FormLabel>
                    <FormControl>
                      <Input
                        id="signatureLimit"
                        className="h-[23px] py-1"
                        placeholder="Signature Limit"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <div className="flex w-full justify-end gap-3 ">
                  <Button
                    type="button"
                    className="w-[max-content]"
                    onClick={() => setIsOpen(false)}
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Continue</Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
})

export default CreateWorkspaceDialog;
