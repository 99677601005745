import { Progress } from 'components/ui/progress';
import React, { useEffect, useState } from 'react';
import { cn } from 'lib/utils';

const PreparingHeadshot = ({
  percentage = 0,
  className = '',
  textClassName = '',
  headerFontSize = 32,
  wrapperHeight = 'h-screen',
}) => {
  const [progress, setProgress] = useState(percentage);
  useEffect(() => {
    if (progress >= 100) {
      return;
    }
    const interval = setInterval(() => {
      setProgress((prev) => prev + 15);
    }, 500);

    return () => clearInterval(interval);
  }, [progress]);

  return (
    <div
      className={cn(
        `${wrapperHeight} w-full flex items-center bg-primary-foreground justify-center flex-col`,
        className,
      )}
    >
      <h4
        className={cn(
          textClassName,
          `text-primary font-bold text-[${headerFontSize}px] md:text-[18px] table`,
        )}
      >
        Generating professional headshot styles...
      </h4>
      <Progress
        className="max-w-[500px] md:max-w-[300px] mt-[22px] bg-primary-foreground"
        value={progress}
        showInfo={false}
      />
    </div>
  );
};

export default PreparingHeadshot;
