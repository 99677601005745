import SubsciptionPlans from 'components/SubsciptionPlans';
import React from 'react';
import { useAppContext } from 'AppContext';

function SubsriptionPage() {
  const {
    state: { currentUser },
  } = useAppContext();

  // Get the active plan ID
  const activePlanID = currentUser?.activePlan?.id;
  // List of LTD plans
  const ltdPlans = ['2', '3', '4', '5'];

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] overflow-y-visible">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          <div className="mr-4">Plans</div>
        </div>
        <SubsciptionPlans />
      </div>
    </div>
  );
}

export default SubsriptionPage;
