/* eslint-disable import/prefer-default-export */
export const SIGNATURE_FIELDS = [
  {
    link: '',
    name: 'headshotUrl',
    type: 'employee',
    label: 'Headshot URL',
    value: '',
    shortLink: '',
    isVariable: true,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'name',
    type: 'employee',
    label: 'Name',
    value: 'Olivia Bolton',
    shortLink: '',
    isVariable: true,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'position',
    type: 'employee',
    label: 'Position',
    value: 'Marketing Manager',
    shortLink: '',
    isVariable: true,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'department',
    type: 'employee',
    label: 'Department',
    value: '',
    shortLink: '',
    isVariable: true,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'companyName',
    type: 'company',
    label: 'Company Name',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'companyAddress',
    type: 'company',
    label: 'Company Address',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'companyLogo',
    type: 'company',
    label: 'Company Logo',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'footer',
    type: 'company',
    label: 'Footer',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: 'dummyemail@dummy.com',
    name: 'email',
    type: 'employee',
    label: 'Email',
    value: 'dummyemail@dummy.com',
    shortLink: '',
    isVariable: true,
    utagEnabled: true,
    variableValue: null,
  },
  {
    link: '',
    name: 'phone',
    type: 'employee',
    label: 'Phone',
    value: '212-232-3233',
    shortLink: '',
    isVariable: true,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'website',
    type: 'company',
    label: 'Website',
    value: 'https://yourwebsite.com',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
  {
    link: '',
    name: 'ctaTitle',
    type: 'company',
    label: 'CTA Title',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'ctaUrl',
    type: 'company',
    label: 'CTA URL',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
  {
    link: '',
    name: 'facebook',
    type: 'company',
    label: 'FACEBOOK',
    value: 'https://facebook.com/#',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
  {
    link: '',
    name: 'instagram',
    type: 'company',
    label: 'INSTAGRAM',
    value: 'https://www.instagram.com/#',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
  {
    link: '',
    name: 'linkedin',
    type: 'company',
    label: 'LINKEDIN',
    value: 'https://www.linkedin.com/company/#',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
  // {
  //   link: '',
  //   name: 'twitter',
  //   type: 'company',
  //   label: 'TWITTER',
  //   value: 'https://twitter.com/#',
  //   shortLink: '',
  //   isVariable: false,
  //   utagEnabled: true,
  //   variableValue: null,
  // },
  {
    link: '',
    name: 'ctaBannerImg',
    type: 'company',
    label: 'CTA Banner',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: false,
    variableValue: null,
  },
  {
    link: '',
    name: 'ctaBannerUrl',
    type: 'company',
    label: 'CTA Banner Url',
    value: '',
    shortLink: '',
    isVariable: false,
    utagEnabled: true,
    variableValue: null,
  },
];

export const industryOptions = [
  'Marketing and Sales',
  'Real Estate and Construction',
  'E-commerce',
  'Software and Internet',
  'HR and Recruiting Services',
  'Non-profit',
  'Education',
  'Financial Services',
  'Design',
  'Healthcare',
  'Content Creators and Bloggers',
  'Retail',
  'Transport and Logistic',
  'Consulting',
  'Music and Entertainment',
  'Arts and Photography',
  'Wellness and Fitness',
  'Motion Pictures and Film',
  'Legal',
  'Fashion and Beauty',
  'Business Coaching',
  'Other',
].map((value) => ({ value, label: value }));
