import React from 'react';
import { Zap, MonitorCheck } from 'lucide-react';

import AiHeadshotButton from 'components/AiHeadshotButton';
// import ProfilePictureButton from 'components/ProfilePictureButton'; // Assume this component exists
import { useFeatureChecks } from 'hooks/brand';

const MobileOnlyWarning = () => {
  const { isAIHeadshotsAllowed } = useFeatureChecks();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6 text-center">
        <div className="flex flex-col items-center">
          <div className="text-4xl text-gray-500 mb-4">
            {' '}
            <MonitorCheck size={200} className="" />
          </div>
          <h1 className="text-lg font-bold mb-2">
            Only compatible on Web/Desktop
          </h1>
          <p className="text-sm text-gray-600 mb-6">
            Some features of SyncSignature are only accessible through laptop or
            desktop. You can use the features below on a mobile browser.
          </p>
        </div>
        {isAIHeadshotsAllowed && (
          <div className="space-y-4">
            {/* <ProfilePictureButton className="w-full py-2 bg-purple-600 text-white rounded-md">
              Profile Picture Maker
            </ProfilePictureButton> */}
            <AiHeadshotButton className="w-full py-2 bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3] text-white rounded-md flex items-center justify-center">
              Try AI Headshot
              <Zap size={16} className="ml-2 fill-white-0" />
            </AiHeadshotButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileOnlyWarning;
