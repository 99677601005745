import { useAppContext } from 'AppContext';
import { onboardingStatus } from 'common/constants';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { useGetGroup } from 'hooks/group';
import { useGetHeadshot } from 'hooks/headshot';
import { useGetSignature, useSignatureId } from 'hooks/signature';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import React, { useEffect, useState } from 'react';
import { Navigate, Routes } from 'react-router-dom';
import { onboardingRoutes } from 'routes';

const OnboardingLayout = () => {
  const { getRoutes } = useGetRoutes();
  const {
    state: { signature, currentUser },
  } = useAppContext();
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();

  const [loading, setLoading] = useState(false);
  const [getSignature] = useGetSignature();
  const [getGroups] = useGetGroup();
  const [getHeadshot] = useGetHeadshot();
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };
  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceTeammates();
      getGroups({
        variables: { workspaceId },
      });
    }
  }, [workspaceId]);

  const loadSignatureData = async () => {
    setLoading(true);
    const res = await getSignature({
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      }, // signature id for QUERY
    });
    const headshotId = res?.data?.signature?.headshotId;
    if (headshotId && res?.data?.signature?.headshot) {
      await getHeadshot({
        variables: {
          where: {
            id: headshotId,
          },
        },
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (signatureId) {
      loadSignatureData();
    }
  }, [signatureId]);

  if (currentUser?.onboardingStatus === onboardingStatus?.COMPLETED) {
    return <Navigate to="/" replace />;
  }
  return <Routes>{getRoutes(onboardingRoutes)}</Routes>;
};

export default OnboardingLayout;
