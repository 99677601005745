/* eslint-disable arrow-body-style */
import React from 'react';
import { DialogContent } from 'components/ui/dialog';
import { Button } from 'components/ui/button';

const DeleteForm = ({ onClose, handleDeleteGroup }) => {
  return (
    <DialogContent
      className="rounded-[6px] max-w-md px-[21px] py-[22px]"
      hideCloseButton
    >
      <div>
        <h1>Delete Group</h1>
        <p>Are you sure you want to delete this group?</p>
        <div className="flex justify-end">
          <div className="flex gap-3">
            <Button
              className="w-[50%]"
              variant="outline"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteGroup}
              className="w-[50%]"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

export default DeleteForm;
