import React from 'react';

const Divider = ({ style = {}, signature = {} }) => (
  <td aria-label="divider">
    <hr
      style={{
        marginTop: 4,
        marginBottom: 4,
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: `1px solid ${signature?.design?.primaryBrandColor}`,
        ...style,
      }}
    />
  </td>
);

export default Divider;
