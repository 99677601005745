import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from 'components/ui/dialog';

import EditForm from './components/EditForm';
import DeleteForm from './components/DeleteForm';
import SendSignatureForm from './components/SendSignatureForm';
import RemoveTeammate from './components/RemoveTeammate';
import RoleChangeForm from './components/RoleChangeForm';

const ActionDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState(null);
  const [data, setData] = useState(null);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      setData(null);
    },
    onTypeChange: (t) => setType(t),
    onDataChnage: (d) => setData(d),
    onFetchTeammates: () => {},
  }));

  const getChildrenComponent = () => {
    switch (type) {
      case 'delete':
        return <DeleteForm data={data} onClose={ref?.current?.close} />;
      case 'send-signature':
        return <SendSignatureForm onClose={ref?.current?.close} data={data} />;
      case 'remove-teammate':
        return (
          <RemoveTeammate
            onClose={ref?.current?.close}
            data={data}
          />
        );
      case 'update-role':
        return (
          <RoleChangeForm
            onClose={ref?.current?.close}
            data={data}
          />
        );
      default:
        return null;
    }
  };

  return (
    isOpen && (
      <Dialog
        open={isOpen}
        onOpenChange={(e) => {
          setIsOpen(e);
          setData(null);
        }}
      >
        {getChildrenComponent()}
      </Dialog>
    )
  );
});

export default ActionDialog;
