/* eslint-disable no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import LoaderComponent from 'components/LoaderComponent';
import { filter, get, map } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { ArrowLeft } from 'lucide-react';
import TemplateStyleOne from 'components/TemplatesModel/Templates/TemplateStyleOne';
import TemplateStyleTwo from 'components/TemplatesModel/Templates/TemplateStyleTwo';
import TemplateStyleThree from 'components/TemplatesModel/Templates/TemplateStyleThree';
import TemplateStyleFour from 'components/TemplatesModel/Templates/TemplateStyleFour';
import TemplateStyleFive from 'components/TemplatesModel/Templates/TemplateStyleFive';
import TemplateStyleSix from 'components/TemplatesModel/Templates/TemplateStyleSix';
import TemplateStyleSeven from 'components/TemplatesModel/Templates/TemplateStyleSeven';
import TemplateStyleEight from 'components/TemplatesModel/Templates/TemplateStyleEight';
import TemplateStyleNine from 'components/TemplatesModel/Templates/TemplateStyleNine';
import TemplateStyleTen from 'components/TemplatesModel/Templates/TemplateStyleTen';
import TemplateStyleEleven from 'components/TemplatesModel/Templates/TemplateStyleEleven';
import TemplateStyleTwelve from 'components/TemplatesModel/Templates/TemplateStyleTwelve';
import TemplateStyleThirteen from 'components/TemplatesModel/Templates/TemplateStyleThirteen';
import TemplateStyleFourteen from 'components/TemplatesModel/Templates/TemplateStyleFourteen';
import TemplateStyleFifthteen from 'components/TemplatesModel/Templates/TemplateStyleFifthteen';
import TemplateStyleSixteen from 'components/TemplatesModel/Templates/TemplateStyleSixteen';
import TemplateStyleSeventeen from 'components/TemplatesModel/Templates/TemplateStyleSeventeen';
import TemplateStyleEightteen from 'components/TemplatesModel/Templates/TemplateStyleEightteen';
import TemplateStyleNineteen from 'components/TemplatesModel/Templates/TemplateStyleNineteen';
import TemplateStyleTwenty from 'components/TemplatesModel/Templates/TemplateStyleTwenty';
import TemplateStyleTwentyOne from 'components/TemplatesModel/Templates/TemplateStyleTwentyOne';
import TemplateStyleTwentyTwo from 'components/TemplatesModel/Templates/TemplateStyleTwentyTwo';
import TemplateStyleTwentyThree from 'components/TemplatesModel/Templates/TemplateStyleTwentyThree';
import TemplateStyleTwentyFour from 'components/TemplatesModel/Templates/TemplateStyleTwentyFour';
import TemplateStyleTwentyFive from 'components/TemplatesModel/Templates/TemplateStyleTwentyFive';
import TemplateStyleTwentySix from 'components/TemplatesModel/Templates/TemplateStyleTwentySix';
import TemplateStyleTwentySeven from 'components/TemplatesModel/Templates/TemplateStyleTwentySeven';
import TemplateStyleTwentyEight from 'components/TemplatesModel/Templates/TemplateStyleTwentyEight';
import TemplateStyleTwentyNine from 'components/TemplatesModel/Templates/TemplateStyleTwentyNine';
import TemplateStyleThirty from 'components/TemplatesModel/Templates/TemplateStyleThirty';
import TemplateStyleThirtyOne from 'components/TemplatesModel/Templates/TemplateStyleThirtyOne';
import TemplateStyleThirtyTwo from 'components/TemplatesModel/Templates/TemplateStyleThirtyTwo';
import TemplateStyleThirtyThree from 'components/TemplatesModel/Templates/TemplateStyleThirtyThree';
import TemplateStyleThirtyFour from 'components/TemplatesModel/Templates/TemplateStyleThirtyFour';
import TemplateStyleThirtyFive from 'components/TemplatesModel/Templates/TemplateStyleThirtyFive';
import TemplateStyleThirtySix from 'components/TemplatesModel/Templates/TemplateStyleThirtySix';
import TemplateStyleThirtySeven from 'components/TemplatesModel/Templates/TemplateStyleThirtySeven';
import TemplateStyleThirtyEight from 'components/TemplatesModel/Templates/TemplateStyleThirtyEight';
import TemplateStyleThirtyNine from 'components/TemplatesModel/Templates/TemplateStyleThirtyNine';
import TemplateStyleFourty from 'components/TemplatesModel/Templates/TemplateStyleFourty';
import copy from 'copy-to-clipboard';
import { useAppContext } from 'AppContext';
import { useTemplateData } from 'hooks/template';
import { HADSHOT_QUERY } from 'modules/Headshot/graphql/Queries';
import { useRouter } from 'hooks/router';
import { useFeatures, useUserPermission } from 'hooks/user';
import {
  useGetSignature,
  useSendSignature,
  useShowBranding,
  useSignatureId,
} from 'hooks/signature';
import { addIconsInLinks, download } from 'common/utils';
import SendSignatureModal from 'modules/Signature/SendSignatureModal';
import { Button } from 'components/ui/button';
import ProfileAvatar from 'components/ProfileAvatar';
import { FEATURE_KEYS, FONT_FAMILY } from 'common/constants';
import {
  AppleMail,
  ClipboardIcon,
  CodeIcon,
  Gmail,
  Outlook,
  Yahoo,
} from 'assets/svg';
import { useWorkspaceId } from 'hooks/workspace';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import InstallGuide from './components/InstallGuide';

const CurrentTemplate = ({signature}) => {
  const { state } = useAppContext();

  const FinalTemplate = dynamicTemplate({
    templateKey: signature?.template?.key,
  });;

  return FinalTemplate ? (
    <FinalTemplate signature={state.signature} showAwsIcons />
  ) : null;
};

function EmailSignature() {
  const {
    state: { signature },
  } = useAppContext();

  const { navigate } = useRouter();

  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId();
  const { isFeaturesAllowed } = useUserPermission();
  const { isUserUsingProFeatures } = useFeatures();
  const [sendSignature] = useSendSignature();
  const [open, setOpen] = useState(false);
  useShowBranding(false);
  const closeModal = () => {
    setOpen(false);
  };
  const font = get(signature, 'design.font', FONT_FAMILY);
  const contentRef = useRef();
  const [getSignature] = useGetSignature();

  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  // const linkArray = [
  //   {
  //     icon: <Gmail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Gmail',
  //     url: 'https://syncsignature.com/blog/add-signature-gmail',
  //   },
  //   {
  //     icon: <Outlook height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Outlook',
  //     url: ' https://syncsignature.com/blog/add-signature-outlook',
  //   },
  //   {
  //     icon: <AppleMail height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Apple mail',
  //     url: 'https://syncsignature.com/blog/add-signature-apple-mail',
  //   },
  //   {
  //     icon: <Yahoo height={28} width={28} className="mr-2 h-4 w-4" />,
  //     label: 'Add to Yahoo',
  //     url: 'https://syncsignature.com/blog/add-signature-yahoo',
  //   },
  //   {
  //     label: 'Add to other clients',
  //     url: ' https://syncsignature.com/blog/install-email-signature',
  //   },
  // ];

  const linkArray = [
    {
      icon: <Gmail height={28} width={28} className="mr-2 h-4 w-4" />,
      label: 'Add to Gmail',
      contentId: 'gmail',
    },
    {
      icon: <Outlook height={28} width={28} className="mr-2 h-4 w-4" />,
      label: 'Add to Outlook',
      contentId: 'outlook',
    },
    {
      icon: <AppleMail height={28} width={28} className="mr-2 h-4 w-4" />,
      label: 'Add to Apple Mail',
      contentId: 'applemail',
    },
    {
      icon: <Yahoo height={28} width={28} className="mr-2 h-4 w-4" />,
      label: 'Add to Yahoo',
      contentId: 'yahoo',
    },
    // {
    //   label: 'Add to other clients',
    //   contentId: 'other',
    // },
  ];

  const handleCopyold = () => {
    const contentToCopy = contentRef?.current;
    if (contentToCopy) {
      const tempElement = document.createElement('div');
      tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
      tempElement.style.padding = '16px'; // Add padding if necessary
      tempElement.innerHTML = contentToCopy.innerHTML;

      // Create and append the meta tags to the head
      const metaCharset = document.createElement('meta');
      metaCharset.setAttribute('http-equiv', 'Content-Type');
      metaCharset.setAttribute('content', 'text/html; charset=UTF-8');
      document.head.appendChild(metaCharset);

      const metaViewport = document.createElement('meta');
      metaViewport.setAttribute('name', 'viewport');
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
      document.head.appendChild(metaViewport);

      // Create and append the title tag
      const title = document.createElement('title');
      title.textContent = 'Email Signature';
      document.head.appendChild(title);

      // Append the temporary element to the document body
      document.body.appendChild(tempElement);

      // Create a range and select the temporary element's content
      const range = document.createRange();
      range.selectNode(tempElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand('copy');
        toast({
          closeicn: 'success',
          description: 'Email signature copied to clipboard!',
        });
      } catch (err) {
        toast({
          closeicn: 'destructive',
          description: 'Please try again',
        });
      }
      // Clean up by removing the temporary element
      document.body.removeChild(tempElement);
      window.getSelection().removeAllRanges();
    }
  };

  const handleCopy = () => {
    const contentToCopy = contentRef?.current;
    if (contentToCopy) {
      // Create a temporary element to hold the content to copy
      const tempElement = document.createElement('div');
      tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
      tempElement.style.padding = '16px'; // Add padding if necessary
      tempElement.innerHTML = contentToCopy.innerHTML;

      // Create and append the meta tags to the head (if needed for the HTML content)
      const metaCharset = document.createElement('meta');
      metaCharset.setAttribute('http-equiv', 'Content-Type');
      metaCharset.setAttribute('content', 'text/html; charset=UTF-8');
      tempElement.appendChild(metaCharset);

      const metaViewport = document.createElement('meta');
      metaViewport.setAttribute('name', 'viewport');
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
      tempElement.appendChild(metaViewport);

      // // Create and append the title tag (if needed for the HTML content)
      // const title = document.createElement('title');
      // title.textContent = 'Email Signature';
      // tempElement.appendChild(title);

      // Use Clipboard API to copy HTML content
      if (navigator.clipboard && navigator.clipboard.write) {
        const blob = new Blob([tempElement.innerHTML], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });

        navigator.clipboard
          .write([clipboardItem])
          .then(() => {
            toast({
              closeicn: 'success',
              description: 'Email signature copied to clipboard!',
            });
          })
          .catch((err) => {
            toast({
              closeicn: 'destructive',
              description: 'Please try again',
            });
          });
      } else {
        // Fallback to execCommand('copy') if Clipboard API is not supported
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        try {
          document.execCommand('copy');
          toast({
            closeicn: 'success',
            description: 'Email signature copied to clipboard!',
          });
        } catch (err) {
          toast({
            closeicn: 'destructive',
            description: 'Please try again',
          });
        }

        // Clean up by removing the temporary element
        selection.removeAllRanges();
      }
    }
  };

  // const handleCopyforoutlook = () => {
  //   const contentToCopy = contentRef?.current;

  //   if (contentToCopy) {
  //     const tempElement = document.createElement('div');
  //     tempElement.style.backgroundColor = '#ffffff'; // Set the desired background color (white)
  //     tempElement.style.padding = '16px'; // Add padding if necessary

  //     // Build the HTML content with tables
  //     const tableContent = `
  //       <table border="0" cellpadding="0" cellspacing="0" width="100%"">
  //         <tr>
  //           <td style="padding: 16px; background-color: #ffffff;">
  //             ${contentToCopy.innerHTML}
  //           </td>
  //         </tr>
  //       </table>
  //     `;

  //     tempElement.innerHTML = tableContent;

  //     // Append the temporary element to the document body
  //     document.body.appendChild(tempElement);

  //     // Create a range and select the temporary element's content
  //     const range = document.createRange();
  //     range.selectNode(tempElement);
  //     window.getSelection().removeAllRanges();
  //     window.getSelection().addRange(range);

  //     try {
  //       document.execCommand('copy');
  //       toast({
  //         closeicn: 'success',
  //         description: 'Email signature copied to clipboard!',
  //       });
  //     } catch (err) {
  //       toast({
  //         closeicn: 'destructive',
  //         description: 'Please try again',
  //       });
  //     }

  //     // Clean up by removing the temporary element
  //     document.body.removeChild(tempElement);
  //     window.getSelection().removeAllRanges();
  //   }

  // };

  const copyHtmlCode = () => {
    download('signature.html', contentRef?.current?.innerHTML);
  };

  const handleSendSignature = (email) => {
    sendSignature({
      variables: {
        data: {
          email,
          html: contentRef?.current?.innerHTML,
        },
      },
    });
    closeModal();
  };
  const dynamicSocialLinks = useMemo(
    () =>
      map(signature?.information?.shortSocialLinks, (link) => {
        const key = Object?.keys(link)?.[0];
        const filteredData = filter(
          addIconsInLinks(),
          (iconLinks) => iconLinks?.option === key,
        )?.[0];
        return {
          ...filteredData,
          url: link?.[key] || null,
        };
      }),
    [signature?.information?.shortSocialLinks],
  );

  useEffect(() => {
    if (isUserUsingProFeatures()) {
      navigate(`/app/${workspaceId}/signature/${signatureId}/information`);
      return;
    }
    getSignature({
      // add signature id from context for public journey
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      }, // signature id for QUERY
    });
  }, []);

  const handleLinkClick = (content) => {
    setDialogContent(content);
    setOpenContentDialog(true);
  };

  return (
    <>
      <SendSignatureModal
        open={open}
        closeModal={closeModal}
        onSignatureSend={handleSendSignature}
      />
      <InstallGuide
        open={openContentDialog}
        closeModal={() => setOpenContentDialog(false)}
        content={dialogContent}
      />
      <div className="w-full bg-primary-foreground relative h-[calc(100dvh_-_71px)] overflow-auto">
        <header className="flex justify-between items-center p-4">
          <div className="flex gap-x-[16px] items-center">
            <Button
              variant="outline"
              className="h-[40px]"
              label="Back"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
        </header>
        <div
          style={{ height: 'calc(100vh - 190px)' }}
          className="p-[16px] overflow-auto"
        >
          <div className="flex justify-center mb-[32px]">
            <div className="text-center max-w-[508px]">
              <h4 className="font-primary text-h4 mb-[8px] mt-0 text-primary">
                Here's your new email signature
              </h4>
              <div className="font-medium text-[14px] leading-[20px] text-secondary-500">
                It's high time to put it to work. Copy it by clicking one of the
                buttons below the preview, and add it to your email client with
                our step-by-step guide.
              </div>
            </div>
          </div>
          <div className="flex justify-center my-[32px]">
            <div
              ref={contentRef}
              className="bg-white-0 w-max rounded shadow-template p-6 min-h-[350px] h-full flex justify-center items-center"
              style={{ flexDirection: 'column' }}
            >
              <CurrentTemplate signature={signature} />
            </div>
          </div>

          <div className="flex my-[32px] gap-x-[28px] w-full justify-center">
            {isFeaturesAllowed(FEATURE_KEYS.DOWNLOAD_HTML) && (
              <Button className="h-[54px]" onClick={copyHtmlCode}>
                <CodeIcon className="mr-2 h-4 w-4" />
                Download HTML
              </Button>
            )}
            <Button className="h-[54px]" onClick={handleCopyold}>
              <ClipboardIcon className="mr-2 h-4 w-4" /> Copy Signature{' '}
            </Button>
            {isFeaturesAllowed(FEATURE_KEYS.SHARE_VIA_EMAIL) && (
              <Button className="h-[54px] " onClick={() => setOpen(true)}>
                <SendOutlined className="mr-2 h-4 w-4" />
                Send Signature
              </Button>
            )}
            {/* kept for future */}
            {/* <Button
                label=" Send Via Email"
                icon={<EnvelopeIcon />}

                variant="custom"
                className="h-[54px] flex items-center font-primary text-button-s"
              /> */}
          </div>

          <div className="flex gap-x-[28px] w-full justify-center">
            {map(linkArray, (link) => (
              <Button
                variant="outline"
                className="h-[54px] w-auto text-secondary-500"
                // onClick={() => {
                //   // eslint-disable-next-line no-undef
                //   const win = window?.open(link?.url, '_blank');
                //   win?.focus();
                // }}
                onClick={() => handleLinkClick(link.contentId)}
              >
                {link?.icon} {link?.label}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailSignature;
