/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import ImageUploadPreview from 'components/ImageUploadPreview';
import Cropper from 'components/Cropper';
import ThemeSelect from 'components/ThemeSelect';
import { useTheme } from 'hooks/theme';
import {
  useGetBrandLogoImageUrl,
  useGetFaviconUrl,
  useUpsertBrand,
} from 'hooks/brand';
import { useBrandContext } from 'context/BrandContext';
import { toast } from 'hooks/use-toast';
import { cloneAndRemoveTypename } from 'common/utils';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';

// Define the zod schema for form validation
const brandSchema = z.object({
  brandName: z.string().min(1, 'Brand name is required'),
  supportEmail: z.string().optional().or(z.literal('')),
  logoUrl: z.string().optional().or(z.literal('')),
  theme: z.object({
    color: z.string(),
    foregroundColor: z.string(),
  }),
  url: z.string().url('Invalid URL').min(1, 'URL is required'),
  favicon: z.any().optional(),
});

const WhiteLabel = () => {
  const {
    state: { brand },
    setBrand,
  } = useBrandContext();
  const {
    state: { currentUser },
  } = useAppContext();
  const { navigate } = useRouter();
  const { handleBrandLogoImageUpload } = useGetBrandLogoImageUrl();
  const { handleFaviconUpload } = useGetFaviconUrl();
  const { updatePrimaryColor } = useTheme();

  const form = useForm({
    resolver: zodResolver(brandSchema),
  });

  const [upsertBrand] = useUpsertBrand();
  const watch = form.watch();

  useEffect(() => {
    const restrictedUrls = [
      'https://app.syncsignature.com',
      'https://app.dev.syncsignature.com',
    ];
    if (
      restrictedUrls.includes(brand?.url) ||
      Number(currentUser?.planId) !== 5
    ) {
      navigate('/app/user/profile');
    }
  }, [brand?.url]);

  useEffect(() => {
    if (brand) {
      form.reset({
        brandName: brand?.name || '',
        supportEmail: brand?.supportEmail || '',
        logoUrl: brand?.logoUrl || '',
        theme: {
          color: brand?.config?.colors?.primary || '',
          foregroundColor: brand?.config?.colors?.['primary-foreground'] || '',
        },
        url: brand?.url || '',
        favicon: brand?.favicon || null,
      });
    }
  }, [brand]);
  const handleFormSubmit = async (data) => {
    // Prepare data for the mutation
    const brandData = {
      ...brand,
      name: data?.brandName,
      supportEmail: data?.supportEmail,
      logoUrl: data?.logoUrl || '',
      config: {
        colors: {
          primary: data?.theme?.color,
          'primary-foreground': data?.theme?.foregroundColor,
        },
      },
      favicon: data?.favicon || null,
    };

    try {
      const res = await upsertBrand({
        variables: { data: brandData },
      });
      if (res) {
        updatePrimaryColor({
          color: data?.theme?.color,
          foregroundColor: data?.theme?.foregroundColor,
        });
        setBrand(cloneAndRemoveTypename(res?.data?.upsertBrand?.data));
        toast({
          closeicn: 'success',
          description: 'Brand Updated Successfully',
        });
      }
    } catch (error) {
      toast({
        description: error?.message,
        closeicn: 'destructive',
      });
    }
  };

  const handleBrandLogoImageChange = async (file, field) => {
    if (file) {
      field.onChange(file);
      const url = (await handleBrandLogoImageUpload(file)) || null;
      form.setValue(field.name, url);
    }
  };
  const handleFaviconImageChange = async (file, field) => {
    if (file) {
      field.onChange(file);
      const url = (await handleFaviconUpload(file)) || null;
      form.setValue(field.name, url);
    }
  };

  return (
    <div className="flex justify-center bg-primary-foreground h-[calc(100vh_-_71px)] overflow-y-hidden">
      <Form {...form}>
        <div className="p-8 rounded-lg shadow-lg w-full max-w-md">
          <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
            White Label
          </div>

          <form
            onSubmit={form.handleSubmit(handleFormSubmit)}
            className="space-y-6"
          >
            <FormField
              control={form.control}
              name="brandName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Brand Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Brand Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="logoUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Logo</FormLabel>
                  <FormControl>
                    {field.value ? (
                      <ImageUploadPreview
                        text="Clear"
                        onChange={() => {
                          form.setValue(field.name, '');
                        }}
                        imageUrl={watch.logoUrl}
                        className="w-full h-24 object-contain"
                      />
                    ) : (
                      <Cropper
                        onOk={(file) =>
                          handleBrandLogoImageChange([file], field)
                        }
                        showAspectbuttons
                        cropperProps={{ circularCrop: false }}
                        draggerContent={
                          <div className="text-center">Upload Logo</div>
                        }
                        baseDraggerClassname="h-[102px] bg-[rgba(0,0,0,0.02)]"
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="theme"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Theme</FormLabel>
                  <ThemeSelect
                    value={field.value}
                    onChange={(theme) => {
                      field.onChange(theme);
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="supportEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span>Support Email</span>
                        </TooltipTrigger>
                        <TooltipContent side="top" align="center">
                          <p>
                            We use generic domain(sendsig.com) to send
                            transactional emails(reset password, user invite
                            etc.) that doesn't have any connection to
                            SyncSignature brand. You can setup your support
                            email here which will be added as a reply-to for all
                            our emails. So when your user replies, you will get
                            receive an email on your support email.
                          </p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Support Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="favicon"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Favicon</FormLabel>
                  <FormControl>
                    {field.value ? (
                      <ImageUploadPreview
                        text="Clear"
                        onChange={() => {
                          form.setValue(field.name, null);
                        }}
                        imageUrl={watch?.favicon}
                        className="w-full h-24 object-contain"
                      />
                    ) : (
                      <Cropper
                        onOk={(file) => {
                          handleFaviconImageChange([file], field);
                        }}
                        cropperProps={{ circularCrop: false }}
                        showAspectbuttons
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>URL</FormLabel>
                  <FormControl>
                    <div className="flex items-center">
                      <Input
                        placeholder="Enter URL"
                        {...field}
                        className="w-full"
                      />
                      <span className="ml-2">.esign.live</span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}

            <div className="flex space-x-4">
              <Button type="submit">Save</Button>
              {/* <Button type="button">CNAME configuration</Button> */}
            </div>
          </form>
        </div>
      </Form>
    </div>
  );
};

export default WhiteLabel;
