/* eslint-disable no-undef */
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_PADDLE_SUBSCRIPTION_PLAN } from 'modules/SubscriptionPage/graphql/Mutations';
import {
  GET_CANCEL_SUBSCRIPTION_URL,
  GET_LM_LTD_PLAN_CHECKOUT_LINK,
  GET_PADDLE_CHECKOUT_TRANSACTION_ID,
  GET_RECURRING_PLANS,
  GET_SUBSCRIPTION_DETAILS,
} from 'modules/SubscriptionPage/graphql/Queries';

// eslint-disable-next-line import/prefer-default-export
export const useGetLmLtdPlanCheckoutLink = () => {
  const [getCheckoutLink, { data, loading, error }] = useLazyQuery(
    GET_LM_LTD_PLAN_CHECKOUT_LINK,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  return [getCheckoutLink, { data, loading, error }];
};

export const useGetPaddleCheckoutTransactionId = () => {
  const [getPaddleCheckoutTransactionId, { data, loading, error }] =
    useLazyQuery(GET_PADDLE_CHECKOUT_TRANSACTION_ID, {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    });

  return [getPaddleCheckoutTransactionId, { data, loading, error }];
};

export const useGetRecurringPlans = () => {
  const [getRecurringPlans, { data, loading, error }] = useLazyQuery(
    GET_RECURRING_PLANS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {},
      onError: () => {},
    },
  );

  return [getRecurringPlans, { data, loading, error }];
};

export const useUpdatePaddleSubscriptionPlan = () => {
  const [updatePaddleSubscriptionPlan, { data, loading, error }] = useMutation(
    UPDATE_PADDLE_SUBSCRIPTION_PLAN,
    {
      onCompleted: (data) => {
        // Add any logic that you want to execute when the mutation is successful
      },
      onError: (error) => {
        // Add any logic to handle the error
      },
    },
  );

  return [updatePaddleSubscriptionPlan, { data, loading, error }];
};

export const useCancelSubscriptionUrl = () => {
  // Lazy query for fetching the cancel subscription URL based on the user ID
  const [getCancelSubscriptionUrl, { data, loading, error }] = useLazyQuery(
    GET_CANCEL_SUBSCRIPTION_URL,
    {
      fetchPolicy: 'network-only', // Always fetch fresh data from the server
      onCompleted: (data) => {},
      onError: (error) => {},
    },
  );

  // This returns the query trigger function and the result states
  return [
    getCancelSubscriptionUrl, // Function to trigger the query
    { data, loading, error },
  ];
};

export const useSubscriptionDetails = () => {
  // Lazy query for fetching the subscription details based on the user ID
  const [getSubscriptionDetails, { data, loading, error }] = useLazyQuery(
    GET_SUBSCRIPTION_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        // window.location.href = '/app/user/billing'
      },
      onError: (error) => {},
    },
  );
  const fetchSubscriptionDetails = (userId) => {
    if (!userId) {
      return;
    }
    getSubscriptionDetails({
      variables: {
        where: { userId },
      },
    });
  };
  // Extract specific billing details from the subscription data
  const getBillingDetails = () => {
    // Check if data and data.getSubscriptionDetails exist before destructuring
    if (!data || !data.getSubscriptionDetails) return null;

    const { billingPeriod, nextBilledAt } = data.getSubscriptionDetails;

    return {
      billingPeriod,
      nextBilledAt,
    };
  };
  return {
    fetchSubscriptionDetails,
    getBillingDetails,
    data,
    loading,
    error,
  };
};
