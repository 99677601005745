/* eslint-disable arrow-body-style */
import { DialogContent } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import React from 'react';

const DeleteForm = ({ onClose, handleOnClick }) => {
  return (
    <DialogContent hideCloseButton className="sm:max-w-[425px] max-w-sm">
      <div>
        <h1 className='text-[20px]'>Delete Signature</h1>
        <p className="font-medium text-[14px] m-0">
          Are you sure you want to delete this Signature?
        </p>
        <div className="flex justify-end gap-3 pt-4">
          <Button
            className="w-[max-content]"
            onClick={() => onClose()}
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleOnClick}>Delete</Button>
        </div>
      </div>
    </DialogContent>
  );
};

export default DeleteForm;
