import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const VERIFY_COUPON = gql`
mutation VerifyCoupon(
  $data: CouponInput!
) {
  verifyCoupon(data: $data) {
    message
    data {
      id
      email
      firstName
      lastName
      name
      profileImage
      role
      isActive
      lastActiveOn
      earlyAccess
      onboardingStatus
      isCouponUsed
      signatureLimit
      workspaces
      phone
      position
      activePlan {
        id
        name
        price
        allowedSignatures
        allowedTemplates
        features {
          key
          label
        }
      }
    }
  }
}
`;
