/* eslint-disable no-undef */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { useAssignGroupToTeammates } from 'hooks/teammate';
import { useGroupId, useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import TeammateListing from './components/TeammateListing';

const AddExistingTeammateDialog = forwardRef(({ group }, ref) => {
  const [searchTerm, setSearchTerm] = useState('');
  const {
    state: { workspaceTeammates },
    setWorkspaceTeammates,
  } = useWorkspaceContext();
  const [assignGroupToTeammates] = useAssignGroupToTeammates();
  const { workspaceId } = useWorkspaceId();
  const { groupId } = useGroupId();

  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      setSearchTerm('');
    },
  }));

  const otherGroupTeammates =
    workspaceTeammates?.filter((wt) => wt.groupId !== groupId) ?? [];

  const handleAddTeammates = async (values) => {
    const res = await assignGroupToTeammates({
      variables: {
        data: {
          groupId,
          workspaceId,
          ids: values.map((t) => t),
        },
      },
    });
    if (res?.data?.assignGroupToTeammates) {
      setWorkspaceTeammates([
        ...workspaceTeammates,
        ...(res?.data?.assignGroupToTeammates?.data ?? []),
      ]);
      ref.current.close();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = otherGroupTeammates.filter(
    (item) =>
      item?.workspaceTeammateDetails?.email
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.workspaceTeammateDetails?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
  );

  return (
    <Dialog open={isOpen} onOpenChange={() => ref?.current?.close()}>
      <DialogContent className="max-w-md p-6 rounded-md">
        <DialogTitle className="text-lg font-semibold">
          Add Teammates
        </DialogTitle>
        {/* <DialogDescription>
            Select teammate's emails to assign to{' '}
            <span className=" font-bold">{group?.name}</span>
          </DialogDescription> */}

        <div className="mt-4">
          <Input
            placeholder="Search email, name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <TeammateListing
          teammatesData={filteredData}
          handleAddTeammates={handleAddTeammates}
        />
      </DialogContent>
    </Dialog>
  );
});

export default AddExistingTeammateDialog;
