import { Input } from 'components/ui/input';
import { Slider } from 'components/ui/slider';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Label } from 'components/ui/label';
import { REGEX, IMAGE_TYPE } from 'common/constants';
import { get, isString } from 'lodash';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import React, { useEffect, useState } from 'react';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { ClearableInput } from 'components/Inputs';
import { fileUpload, getLiveUrl, resizeImage } from 'common/utils';
import { useGetCtaBannerSignedUrl } from 'hooks/cta';
import Cropper from 'components/Cropper';

const WorkspaceTemplateCallToAction = ({
  workspaceTemplate,
  handleSetWorkspaceTemplate,
}) => {
  const [getCtaBannerSignUrl] = useGetCtaBannerSignedUrl();

  const ctaFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaTitle', 'ctaUrl'].includes(field.name),
  );
  const ctaBannerImgFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaBannerImg'].includes(field.name),
  );
  const ctaBannerUrlFields = workspaceTemplate?.fields?.filter((field) =>
    ['ctaBannerUrl'].includes(field.name),
  );

  const ctaFieldsSchema = ctaFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerImageSchema = ctaBannerImgFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const ctaBannerUrlSchema = ctaBannerUrlFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const schema = z.object({
    ...(ctaFieldsSchema ?? {}),
    ...(ctaBannerImageSchema ?? {}),
    ...(ctaBannerUrlSchema ?? {}),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    values: [
      ...ctaFields,
      ...ctaBannerImgFields,
      ...ctaBannerUrlFields,
    ]?.reduce((acc, field) => {
      acc[field.name] = field.value || '';
      return acc;
    }, {}),
  });

  const watch = form.watch();

  const [errors, setErrors] = useState({});

  const { styles } = workspaceTemplate?.design || {};
  const { cta, ctaBanner } = styles || {};

  const ctaBannerWidth = isString(ctaBanner?.style?.width)
    ? 0
    : ctaBanner?.style?.width || 250;

  const ctabannerborderRadius = ctaBanner?.style?.ctaborderRadius || 0;
  const ctabuttontype = cta?.style?.type || 'colored';

  const validateWebsite = (website) => REGEX.WEB_URL.test(website);

  const handleDesignStyleChange = (key, values) => {
    const design = get(workspaceTemplate, 'design', {});
    const designStyles = get(design, 'styles', {});
    const data = {
      ...workspaceTemplate,
      design: {
        ...workspaceTemplate?.design,
        styles: {
          ...designStyles,
          [key]: {
            ...values,
          },
        },
      },
    };
    handleSetWorkspaceTemplate(data);
  };

  const generateSignUrl = async (variables) => {
    const res = await getCtaBannerSignUrl({
      variables: {
        data: {
          ...variables,
        },
      },
    });
    return res?.data?.getCtaBannerUploadSignedUrl;
  };

  const handleFileUpload = async (files, field) => {
    const file = files?.[0];
    if (file) {
      const isGif = file.type === IMAGE_TYPE.gif;
      let uploadFile = file;
      if (!isGif) {
        uploadFile = await resizeImage(file, 200);
      }
      const { name } = uploadFile || {};
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const filename = name.split('.').slice(0, -1).join('.');
      const newFilename = `${filename}.${ext}`;
      const res = await generateSignUrl({
        fileName: newFilename,
        id: workspaceTemplate?.id,
        type: 'template',
      });
      if (res) {
        const { signedUrl, key } = res;
        try {
          await fileUpload(signedUrl, file);
          const liveUrl = getLiveUrl(key);
          form.setValue(field.name, liveUrl);
          form.setValue('ctaBannerUrl', 'https://yourwebsite.com');
        } catch (error) {
          return false;
        }
      }
    }
  };

  const handleFormChange = () => {
    const values = form.getValues();
    const updatedArray = [...(workspaceTemplate?.fields ?? [])]?.map((item) => {
      if (values[item.name] !== undefined) {
        return {
          ...item,
          value: values[item.name],
        };
      }
      return item;
    });

    if (
      JSON.stringify(updatedArray) !== JSON.stringify(workspaceTemplate.fields)
    ) {
      handleSetWorkspaceTemplate({
        ...workspaceTemplate,
        fields: updatedArray,
      });
    }
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, workspaceTemplate]);

  return (
    <div>
      <header className=" px-[16px] h-[102px] flex items-center">
        <h1 className="font-primary text-h6 font-bold text-neutral-1000">
          Call to action
        </h1>
      </header>

      <div className="p-[16px] pl-4 pr-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(() => {})} className="w-full">
            <div>
              {ctaFields?.map((field) => (
                <FormField
                  key={field.name}
                  control={form.control}
                  name={field.name}
                  render={({ field: formField }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          className="max-w-full mb-2"
                          placeholder={field.label}
                          {...formField}
                          type={field.type}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </div>
            <div>
              {watch?.ctaUrl && (
                <div className="rounded mb-4 py-1 px-2 mt-4">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>CTA Button Style</span>
                    </div>
                    <RadioGroup
                      value={ctabuttontype}
                      onValueChange={(value) =>
                        handleDesignStyleChange('cta', {
                          style: {
                            ...cta?.style,
                            type: value,
                          },
                        })
                      }
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="colored"
                          id="colored-ctaButtonStyle"
                        />
                        <Label
                          htmlFor="colored-ctaButtonStyle"
                          className="px-4 py-1 rounded-full bg-primary text-white-0"
                        >
                          Colored
                        </Label>
                        <RadioGroupItem
                          value="bordered"
                          id="bordered-ctaButtonStyle"
                        />
                        <Label
                          htmlFor="bordered-ctaButtonStyle"
                          className="px-4 py-1 rounded-full bg-transparent border-1 border-solid border-primary"
                        >
                          Bordered
                        </Label>
                        <RadioGroupItem value="text" id="text-ctaButtonStyle" />
                        <Label
                          htmlFor="text-ctaButtonStyle"
                          className="px-4 py-1 rounded-full"
                        >
                          Text
                        </Label>
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              )}
            </div>
            <div>
              {watch?.ctaUrl && (
                <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Width</span>
                      <span>{parseFloat(cta?.style?.width)}px</span>
                    </div>
                    <Slider
                      min={50}
                      max={200}
                      value={[parseFloat(cta?.style?.width)]}
                      onValueChange={([value]) =>
                        handleDesignStyleChange('cta', {
                          style: {
                            ...cta?.style,
                            width: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
              {watch?.ctaUrl && ctabuttontype !== 'text' && (
                <div className="bg-white-0 rounded mb-4 py-1 px-2">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Border Radius</span>
                      <span>{cta?.style?.borderRadius}px</span>
                    </div>
                    <Slider
                      min={0}
                      max={15}
                      value={[cta?.style?.borderRadius]}
                      onValueChange={([value]) =>
                        handleDesignStyleChange('cta', {
                          style: {
                            ...cta?.style,
                            borderRadius: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <h1 className="font-primary text-h6 font-bold text-neutral-1000">
              Call to action Banner
            </h1>
            <div>
              {ctaBannerImgFields?.map((field) => (
                <FormField
                  key={field.name}
                  control={form.control}
                  name={field.name}
                  render={({ field: formField }) => (
                    <FormItem>
                      <FormControl>
                        {watch.ctaBannerImg ? (
                          <ImageUploadPreview
                            className="rounded-none mb-4"
                            onChange={() => {
                              form.setValue(field.name, '');
                            }}
                            imageUrl={watch.ctaBannerImg}
                          />
                        ) : (
                          <Cropper
                            onOk={(file) => {
                              handleFileUpload([file], formField);
                            }}
                            showAspectbuttons
                            allowGif
                            cropperProps={{ aspect: null }}
                            baseDraggerClassname="mb-4"
                          />
                        )}
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}

              {ctaBannerUrlFields?.map((field) => (
                <FormField
                  key={field.name}
                  control={form.control}
                  name={field.name}
                  render={({ field: formField }) => (
                    <FormItem>
                      <FormControl>
                        <ClearableInput
                          className="max-w-full mb-2"
                          placeholder={field.label}
                          {...formField}
                          type={field.type}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
            </div>
            <div>
              {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
                <div className="bg-white-0 rounded mb-4 py-1 px-2 mt-4">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Width</span>
                      <span>{ctaBannerWidth}px</span>
                    </div>
                    <Slider
                      min={50}
                      max={600}
                      value={[ctaBannerWidth]}
                      onValueChange={([value]) =>
                        handleDesignStyleChange('ctaBanner', {
                          style: {
                            ...cta?.style,
                            width: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
            <div>
              {watch?.ctaBannerUrl && watch?.ctaBannerImg && (
                <div className="bg-white-0 rounded mb-4 py-1 px-2">
                  <div className="m-0">
                    <div className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Border Radius</span>
                      <span>{ctabannerborderRadius}px</span>
                    </div>
                    <Slider
                      min={0}
                      max={15}
                      value={[ctabannerborderRadius]}
                      onValueChange={([value]) =>
                        handleDesignStyleChange('ctaBanner', {
                          style: {
                            ...ctaBanner?.style,
                            ctaborderRadius: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default WorkspaceTemplateCallToAction;
