import { get } from 'lodash';
import React from 'react';

function CompanyLogo({ data, style = {}, toProps = {}, design = {}, height= "" }) {

  const width = get(design, 'styles.companyLogo.style.width',80);
  return (
    <td
      style={{
        ...style,
      }}
      {...toProps}
    >
      <img
            src={data?.value}
            alt="company-logo"
            width={width}
            title="Company logo"
            style={{
              display: 'block',
            }}
        />
    </td>
  );
}

export default CompanyLogo;
