import React from 'react';
import { Button } from 'components/ui/button';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { useRouter } from 'hooks/router';
import { useAppContext } from 'AppContext';

const WorkspaceTemplatePreview = ({
  workspaceTemplate,
  openTemplateSwitcher,
}) => {
  const {
    navigate,
    params: { workspaceId },
  } = useRouter();

  const FinalTemplate = dynamicTemplate({
    templateKey: workspaceTemplate?.template?.key,
  });

  return (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div className=" h-full overflow-y-scroll w-full  bg-white-0 rounded-[8px]">
        <div className="pt-4 px-4">
          {FinalTemplate ? (
            <div>
              <FinalTemplate signature={workspaceTemplate} showAwsIcons/>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-gray-500 mb-4 text-regular-xl">
                You don't have a signature template
              </p>
              <Button
                onClick={() => {
                  openTemplateSwitcher();
                }}
              >
                Select signature template
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkspaceTemplatePreview;
