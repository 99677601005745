import { useAppContext } from 'AppContext';
import { CheckBoxIcon } from 'assets/svg';
import Button from 'components/Button';
import Divider from 'components/TemplatesModel/Templates/components/Divider';
import { useRouter } from 'hooks/router';
import { get } from 'lodash';
import React from 'react';

function Profile() {
  const { navigate } = useRouter();
  const {
    state: { currentUser },
  } = useAppContext();

  const signatureLimit = get(currentUser, 'signatureLimit', null);
  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)]">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          Profile
        </div>
        <div className="items-start flex justify-center gap-[25px] lg:gri">
          <div className="rounded-lg max-w-[600px] h-full bg-white-0 p-4">
            <div className="mb-1 text-[20px] font-[600]">Email Address</div>
            <div className="mb-4 text-[15px]  font-[500] ">
              {currentUser?.email}
            </div>
            {/* <div className="flex items-start flex-col gap-[4px]">
            <Divider className="my-4 bg-secondary-400" />
          </div> */}
          </div>
          {/* <div className="rounded-lg  h-full bg-white-0 p-4">
            <div className="flex w-full justify-between items-center text-primary-1000 font-[700] mb-[10px] ">
              <div className="text-[20px] font-[700]">Active Plan</div>
              <div className="text-[12px] rounded-full font-[700] bg-primary-foreground text-primary  py-2 px-4">
                {currentUser?.activePlan?.name}
              </div>
            </div>

            <div className="flex items-start flex-col gap-[4px]">
              <Divider className="my-4 bg-secondary-400" />
              {currentUser?.activePlan?.features?.map((feature, index) => {
                if (index === 0) return null;

                const { label } = feature;
                return (
                  <div
                    key={label}
                    className="grid grid-cols-[30px,1fr] items-center text-[14px] font-medium"
                  >
                    <CheckBoxIcon className="mr-6 bg-primary h-4 w-4 rounded" />
                    <span>{label}</span>
                  </div>
                );
              })}
            </div>
            <Button
              className="leading-[25px] rounded-md py-1 px-5 h-[40px] w-full font-primary text-button-s mt-5 font-[500] text-white-0"
              onClick={() => navigate('/app/user/subscriptions')}
            >
              Upgrade
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
