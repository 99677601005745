/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
import {
  ChartBarIcon,
  FourSquareIcon,
  MagicWandIcon,
  NoteBookIcon,
  UserCircleIcon,
} from 'assets/svg';
import { useAppContext } from 'AppContext';
import WorkspaceSwitcher from 'components/WorkspaceSwitcher';
import { useRouter } from 'hooks/router';
import { useTheme } from 'hooks/theme';
import { useIsUserBrandOwner } from 'hooks/brand';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { workspaceRoutes } from 'routes';
import { useBrandContext } from 'context/BrandContext';
import { FEATURE_KEYS } from 'common/constants';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const EXCLUDED_PATHS = [
  '/subscriptions',
  '/profile',
  '/groups/:groupId',
  '/teammates/:workspaceTeammateId',
  '/restrictedPage',
];

const getIconForPath = (path) => {
  switch (path) {
    case '/assets':
      return UserCircleIcon;
    case '/templates':
      return FourSquareIcon;
    case '/groups':
      return ChartBarIcon;
    case '/teammates':
      return NoteBookIcon;
    case '/analytics':
      return ChartBarIcon;
    case '/integrations':
      return MagicWandIcon;
    default:
      return UserCircleIcon;
  }
};

const getFilteredItems = (role, routes, brand) => {
  return routes
    .filter((route) => {
      if (route.path === '/integrations') {
        if (
          ![
            'http://localhost:3000',
            'https://app.syncsignature.com',
            'https://app.dev.syncsignature.com',
          ].includes(brand?.url)
        )
          return false;
      }

      // Ensure the route is permitted for the role and not in excluded paths
      return (
        route.permission.includes(role) && !EXCLUDED_PATHS.includes(route.path)
      );
    })
    .map((route) => {
      const { path, name, title } = route;
      return {
        key: name.toLowerCase(),
        path,
        label: title,
        disabled: false,
        showCrown: false,
        icon: getIconForPath(path),
      };
    });
};

const SideBar = () => {
  const {
    location: { pathname },
    navigate,
    params,
  } = useRouter();
  const {
    state: { currentUser },
  } = useAppContext();
  const {
    state: { brand },
  } = useBrandContext();
  const { color, foregroundColor } = useTheme();
  const { role = 'ALL' } = useGetRoleForWorkspace();
  const workspaceId = params?.workspaceId;
  const { isUserBrandOwner } = useIsUserBrandOwner();
  const { isFeaturesAllowed } = useUserPermission();
  const originalPathRef = useRef(null);

  const items = getFilteredItems(role, workspaceRoutes, brand);
  const isAcitivatedPlan = currentUser?.activePlan?.id;

  const restrictedPaths = ['/templates', '/groups', '/teammates', '/analytics'];

  const isActiveTab = (path) => {
    if (pathname.includes('/restrictedPage') && originalPathRef.current) {
      return originalPathRef.current === path;
    }
    return pathname.includes(path);
  };

  const handleMenuClick = (path) => {
    if (
      !isFeaturesAllowed(FEATURE_KEYS.TEAM_FEATURE) &&
      restrictedPaths.includes(path)
    ) {
      if (isUserBrandOwner(currentUser?.id, workspaceId)) {
        originalPathRef.current = path;
        const pathToRedirect = `/app/workspace/${workspaceId}/restrictedPage`;
        navigate(pathToRedirect);
      } else {
        const pathToRedirect = `/app/workspace/${workspaceId}${path}`;
        navigate(pathToRedirect);
      }
    } else {
      const pathToRedirect = `/app/workspace/${workspaceId}${path}`;
      navigate(pathToRedirect);
    }
  };

  return (
    <div
      className="h-[calc(100vh_-_71px)] flex justify-between flex-col"
      collapsible
      theme="light"
      trigger={null}
      width={277}
    >
      <div>
        {items.map(({ icon: Icon, path, label }) => {
          // const onClick = () => {
          //   // if (key === 'dashboard') {
          //   //   const pathToRedirect = `/app/dashboard`;
          //   //   navigate(pathToRedirect);
          //   //   return;
          //   // }
          //   const pathToRedirect = `/app/workspace/${workspaceId}${path}`;
          //   navigate(pathToRedirect);
          // };

          const isActive = isActiveTab(path);

          return (
            <div
              className="h-[70px]"
              style={{
                ...itemStyle,
                ...(isActive
                  ? {
                      backgroundColor: foregroundColor,
                      borderLeft: `4px solid ${color}`,
                    }
                  : {}),
              }}
              key={path}
              onClick={() => handleMenuClick(path)}
            >
              <div
                className="flex items-center w-full "
                style={{ marginLeft: isActive ? '30px' : '35px' }}
              >
                <Icon
                  fill={isActive ? color : '#7a7289'}
                  className="h-[30px] w-[30px] mr-3"
                />
                <div
                  className="text-[18px]"
                  style={{ color: isActive ? color : '#7a7289' }}
                >
                  {label}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="px-[10px] py-[14px]">
        <WorkspaceSwitcher />
      </div>
    </div>
  );
};

export default SideBar;
