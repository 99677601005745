// gmail.js
// eslint-disable-next-line import/prefer-default-export
export const gmailContent = `
  <div class="bg-gray-100 border border-gray-100 p-4 rounded-md text-yellow-800 mb-4">
    <strong>WARNING:</strong>
    Gmail will often prevent you from installing signatures around 10,000 characters and over. Unfortunately, for our templates, we need every single one of these characters to ensure that your email signature looks good across all email clients. If Gmail is serving you a "The signature is too long. Please try a shorter signature" error, please try reducing the amount of information in your signature or using another template.
  </div>
  <div class="mt-6 steps">
    <ol class="list-none p-0 m-0">
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">1</div>
        <span>Once logged into your Gmail account, click the Settings in the top right corner.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">2</div>
        <span>Select "See all settings" option</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">3</div>
        <span>Scroll down to the Signature section and click "Create new".</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">4</div>
        <span>Enter your new signature name into the modal and click "Create".</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">5</div>
        <span>Copy and paste your signature into the signature editor.</span>
      </li>
    </ol>
  </div>
  <div class="mt-6 bg-gray-100 border border-gray-100 p-4 rounded-md text-yellow-800 mb-4">
    <strong>WARNING:</strong> There is currently a bug with Chrome and the way it performs the copy/paste that causes your signature's links to appear underlined. Please complete the install process using a different browser such as <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline">Mozilla Firefox</a> to resolve the issue. You can go back to using Chrome once it is setup.
  </div>
  <div class="mt-6 steps">
    <ol class="list-none p-0 m-0">
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">6</div>
        <span>Select your new signature in the "For New Emails Use" dropdown.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">7</div>
        <span>IMPORTANT: The Gmail iOS app will look at this setting for which signature to use when sending email.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 bg-orange-500 text-white font-bold rounded-full mr-4">8</div>
        <span>Ensure you scroll to the bottom of the page and click Save Changes.</span>
      </li>
    </ol>
  </div>
  <div class="mt-6">
    <p>Compose a new email and enjoy your new signature!</p>
  </div>
`;
