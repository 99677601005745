import React from 'react';
import { useAppContext } from 'AppContext';
import { useRouter } from 'hooks/router';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES, TEXT_IDS } from 'common/constants';
import Button from 'components/Button';

function Branding() {
  const {
    state: { showBranding },
    handleSubscriptionModal,
  } = useAppContext();
  const {
    location: { search },
  } = useRouter();
  const params = new URLSearchParams(search);
  const showTemplate = params.get('showTemplate');
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  if (
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER
  ) {
    return <></>;
  }

  if (!showTemplate) {
    return (
      <table
        style={{
          fontSize: '10px',
          borderTopColor: 'rgb(238, 238, 238)',
          marginTop: '8px',
        }}
      >
        <tr>
          <td>
            <img
              src="/logo32.png"
              height={16}
              width={16}
              alt="logo"
              style={{ display: 'block', margin: 'auto' }}
            />
          </td>
          <td id={TEXT_IDS.BRANDING} style={{ paddingTop: '2px' }}>
            <p>
              Created with
              <span
                style={{
                  color: '#7F56D9',
                  fontWeight: '800',
                  paddingLeft: '2px',
                }}
              >
                SyncSignature
              </span>
            </p>
          </td>
        </tr>
        {showBranding && (
          <tr>
            <td colSpan="2" style={{ paddingTop: '8px' }}>
              <Button
                onClick={() => {
                  handleSubscriptionModal(true);
                }}
              >
                Remove Branding
              </Button>
            </td>
          </tr>
        )}
      </table>
    );
  }
}

export default Branding;
