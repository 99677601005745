/* eslint-disable import/no-cycle */
import LoaderComponent from 'components/LoaderComponent';
import { Button } from 'components/ui/button';
import { filter, map } from 'lodash';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from 'AppContext';

import { useTemplateData } from 'hooks/template';
import { addIconsInLinks } from 'common/utils';
import { useFeatures } from 'hooks/user';
import { useSignatureId } from 'hooks/signature';
import SignatureWarning from 'components/SignatureWarning';
import { Badge } from 'components/ui/badge';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';

const CurrentTemplate = memo(() => {
  const {
    state: { signature },
  } = useAppContext();

  const { data: { templates: { data } = {} } = {}, loading } =
    useTemplateData();

  const getCurrentTemplateData = useMemo(
    () =>
      filter(data, (template) => template?.key === signature?.template?.key),
    [data, signature],
  )?.[0];

  if (loading) {
    return <LoaderComponent setWidth="100%" />;
  }

  let FinalTemplate;

  if (getCurrentTemplateData && getCurrentTemplateData?.key) {
    if (dynamicTemplate({ templateKey: getCurrentTemplateData?.key })) {
      FinalTemplate = dynamicTemplate({
        templateKey: getCurrentTemplateData?.key,
      });
    } else {
      return <>No template selected</>;
    }
  }

  return FinalTemplate ? (
    <FinalTemplate signature={signature} showAwsIcons={false} />
  ) : null;
});

function SignaturePreview({ isDarkMode }) {
  const {
    state: { signatureWidth, signature, signaturecta },
    formRef,
  } = useAppContext();

  const { information, design, templateId } = signature || {};
  const { signatureId } = useSignatureId();
  const { socialLinks } = information || {};

  const dynamicSocialLinks = useMemo(
    () =>
      map(socialLinks, (link) => {
        const key = Object?.keys(link)?.[0];
        const filteredData = filter(
          addIconsInLinks(),
          (iconLinks) => iconLinks?.option === key,
        )?.[0];
        return {
          ...filteredData,
          url: link?.[key] || null,
        };
      }),
    [socialLinks, addIconsInLinks, design],
  );

  const { isUserUsingProFeatures, handleRevertToFree } = useFeatures();

  const isProFeature = isUserUsingProFeatures();
  return (
    <div className="min-h-full max-h-screen overflow-y-scroll w-full h-full">
      <header
        className={`px-4 py-[10px] ${
          isDarkMode ? 'bg-secondary-700' : ' bg-primary-foreground'
        }`}
      >
        {isProFeature ? (
          <div
            className={`${
              isDarkMode ? 'text-neutral-0' : 'text-primary'
            } text-regular-base font-primary  `}
          >
            You are using{' '}
            <Badge className="bg-primary border-[transparent] px-2 mr-[3px] h-auto  text-white-0 rounded-4">
              PRO
            </Badge>
            features{'  '}
            <Button
              onClick={() => {
                handleRevertToFree();
              }}
              style={{ padding: 0 }}
              variant="link"
            >
              <u>Revert to free</u>
            </Button>
          </div>
        ) : (
          <div
            className={`${
              isDarkMode ? 'text-neutral-0' : ''
            } text-regular-base font-primary  `}
          >
            New Message
          </div>
        )}
      </header>
      {signatureWidth > 600 && (
        <div>
          <SignatureWarning width={signatureWidth} />
        </div>
      )}
      {signaturecta > 600 && (
        <div>
          <SignatureWarning width={signaturecta} />
        </div>
      )}

      {/* signature-body */}
      <div className="pt-4 px-4">
        {templateId ? (
          <div>
            <CurrentTemplate dynamicSocialLinks={dynamicSocialLinks} />
          </div>
        ) : (
          <>
            <Link to={`/app/signature/${signatureId}/information`}>
              <Button>Please select signature</Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default SignaturePreview;
