import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleThree({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');

  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr style={{}}>
        <td>
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
            }}
          >
            <tr>
              <td>
                <table cellPadding="0px" ref={mainContentRef}>
                  {getFieldData(fields, 'headshotUrl') && (
                    <tr>
                      {HeadshotImage({
                        data: getFieldData(fields, 'headshotUrl'),
                        design,
                        align: 'left',
                        style: {
                          padding: '0 0 16px 0',
                          textAlign: 'left',
                        },
                      })}
                    </tr>
                  )}

                  {!section2 && (
                    <tr>
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                        >
                          {getFieldData(fields, 'name') && (
                            <tr>
                              {Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  color: design?.primaryBrandColor,
                                  fontWeight: '700',
                                },
                              })}
                            </tr>
                          )}
                          {(getFieldData(fields, 'position') ||
                            getFieldData(fields, 'department')) && (
                            <tr>
                              {getFieldData(fields, 'position') &&
                                Position({
                                  signature,
                                  data: getFieldData(fields, 'position'),
                                  style: {
                                    paddingRight: '16px',
                                    paddingTop: '2px',
                                  },
                                })}
                              {getFieldData(fields, 'department') &&
                                Department({
                                  signature,
                                  data: getFieldData(fields, 'department'),
                                  style: {
                                    paddingTop: '2px',
                                  },
                                })}
                            </tr>
                          )}

                          {getFieldData(fields, 'companyName') && (
                            <tr>
                              {CompanyName({
                                signature,
                                data: getFieldData(fields, 'companyName'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingTop: '2px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  )}

                  {!(allUrlsAreNull || section2) && (
                    <tr>
                      <Divider
                        signature={signature}
                        style={{ marginTop: 8, marginBottom: 8 }}
                      />
                    </tr>
                  )}
                  {!allUrlsAreNull && (
                    <>
                      {getFieldData(fields, 'phone') && (
                        <tr>
                          {Phone({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'phone'),
                          })}
                        </tr>
                      )}
                      {getFieldData(fields, 'email') && (
                        <tr>
                          {Email({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'email'),
                          })}
                        </tr>
                      )}
                      {getFieldData(fields, 'website') && (
                        <tr>
                          {Website({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'website'),
                          })}
                        </tr>
                      )}
                      {getFieldData(fields, 'companyAddress') && (
                        <tr>
                          {CompanyAddress({
                            signature,
                            showAwsIcons,
                            design,
                            data: getFieldData(fields, 'companyAddress'),
                          })}
                        </tr>
                      )}
                      {!!socialFields?.length && (
                        <tr>
                          {SocialLinks({
                            signature,
                            showAwsIcons,
                            data: socialFields,
                            design,
                            // style: { paddingBottom: '8px' },
                          })}
                        </tr>
                      )}
                    </>
                  )}
                </table>
              </td>
            </tr>
            {getFieldData(fields, 'companyLogo') && (
              <tr>
                <td>
                  <table cellPadding="0px" cellSpacing="0" border="0">
                    <tr>
                      {CompanyLogo({
                        data: getFieldData(fields, 'companyLogo'),
                        design,
                        style: { paddingBottom: '6px' },
                      })}
                    </tr>
                  </table>
                </td>
              </tr>
            )}

            {!(allUrlsAreNull || ctadivider) && (
              <tr>
                <td>
                  <table width={tableWidth}>
                    <tr>
                      <Divider
                        signature={signature}
                        style={{ marginTop: 8, marginBottom: 8 }}
                      />
                    </tr>
                  </table>
                </td>
              </tr>
            )}

            <tr>
              <td>
                {!ctadivider && (
                  <table ref={extraContentRef}>
                    {getFieldData(fields, 'ctaUrl') &&
                      getFieldData(fields, 'ctaTitle') && (
                        <tr>
                          {CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                            style: {
                              margin: '0px 0px',
                            },
                          })}
                        </tr>
                      )}
                    <tr>
                      {getFieldData(fields, 'ctaBannerImg') &&
                        getFieldData(fields, 'ctaBannerUrl') &&
                        CallToActionBanner({
                          data: {
                            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                          },
                          design,
                          style: { textAlign: 'left', paddingTop: '8px' },
                        })}
                    </tr>
                  </table>
                )}
              </td>
            </tr>

            <tr>
              <td>
                <table width={tableWidth}>
                  <tr>
                    {getFieldData(fields, 'footer') &&
                      Footer({
                        data: getFieldData(fields, 'footer'),
                        design,
                      })}
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <Branding />
    </table>
  );
}

export default TemplateStyleThree;
