/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_WORKSPACES = gql`
  query GetWorkspaces {
    getWorkspaces {
      data {
        id
        name
        signatureLimit
        signatureUsed
      }
    }
  }
`;
