/* eslint-disable no-undef */
import { ChevronRight } from 'lucide-react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { Separator } from 'components/ui/separator';
import { toast } from 'hooks/use-toast';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'hooks/router';
import { LOGIN, SIGNUP, UPDATE_USER } from 'modules/Auth/graphql/Mutations';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInWithGooglePopup } from 'modules/Auth/firebase';
import { Input } from 'components/ui/input';
import { GoogleIcon } from 'assets/svg';
import { Button } from 'components/ui/button';
import { useHeadshotUpload } from 'hooks/headshot';
import { useAppContext } from 'AppContext';
import { useUpsertSignature } from 'hooks/signature';
import { useGetTemplates } from 'hooks/template';
import { get } from 'lodash';
import { useWorkspaceId } from 'hooks/workspace';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import {
  DEFAULT_DESIGN_STYLE,
  DISPLAY_MODE,
  EMAIL_PLACEHOLDER,
  FONT_FAMILY,
  PRIMARY_COLOR,
  TOKEN,
  onboardingStatus,
} from 'common/constants';
import { SIGNATURE_FIELDS } from 'modules/Onboarding/constants';
import { GET_USER } from 'modules/Auth/graphql/Queries';
import { cloneAndRemoveTypename } from 'common/utils';

const formSchema = z.object({
  email: z
    .string()
    .email({ message: 'Please enter a valid email address.' })
    .nonempty({ message: 'Email is required.' }),
  password: z.string().min(1, 'Password is required'),
});

const LoginModal = ({ open = false, setOpen }) => {
  const { data: templates } = useGetTemplates();
  const { templateId, template } = templates[0];
  const [upsertSignature] = useUpsertSignature();

  const [getUserProfile] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
  });

  const fetchWorkspaceId = async () => {
    const userProfileResponse = await getUserProfile();
    const user = get(userProfileResponse, 'data.me', null);
    const workspace = user?.workspaces?.[0];
    return workspace?.id;
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '', password: '' },
  });

  const closeModal = () => {
    setOpen(false);
  };

  const { navigate } = useRouter();
  const {
    state: { headshot, signature },
    setSignature,
    initializeAuth,
    setUserLocalData,
  } = useAppContext();

  const { handleGlobalHeadshotUpload } = useHeadshotUpload();

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (res) => {
      setUserLocalData(res?.updateUser?.data);
    },
  });

  const [setOnboard, { loading: setOnboardLoading }] = useUpsertSignature();

  const [signupMutate, { loading: signupLoading }] = useMutation(SIGNUP);

  const redirectToHeadshot = async (token) => {
    localStorage.setItem(TOKEN, token);

    const data = {
      design: {
        primaryBrandColor: PRIMARY_COLOR,
        theme: DISPLAY_MODE.LIGHT,
        font: FONT_FAMILY,
        styles: DEFAULT_DESIGN_STYLE,
        icons: [],
      },
      fields: SIGNATURE_FIELDS,
      templateId,
      template,
    };

    const workspaceId = await fetchWorkspaceId();

    const signatureResponse = await setOnboard({
      variables: {
        data: { ...signature, templateId, fields: SIGNATURE_FIELDS },
        where: { workspaceId },
      },
    });

    if (signatureResponse) {
      await updateUser({
        variables: {
          data: { onboardingStatus: onboardingStatus.COMPLETED },
        },
      });
      const updatedHeadshot = await handleGlobalHeadshotUpload(
        headshot,
        `${headshot?.style?.key}`,
      );
      const previousDownloadUrl = updatedHeadshot?.config?.output
        ? `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${updatedHeadshot?.config?.output}`
        : '';
      const signatureRes = cloneAndRemoveTypename(
        get(signatureResponse, 'data.upsertSignature', null),
      );
      const { id, ...restData } = signatureRes;
      const sigabatureClone = { ...restData };
      const updatedFields = sigabatureClone?.fields?.map((field) =>
        field.name === 'headshotUrl'
          ? { ...field, value: previousDownloadUrl }
          : field,
      );
      if (!updatedFields.some((field) => field.name === 'headshotUrl')) {
        updatedFields.push({
          name: 'headshotUrl',
          value: previousDownloadUrl,
          type: 'employee',
          isVariable: true,
          variableValue: null,
          label: 'Headshot URL',
          utagEnabled: false,
          link: '',
          shortLink: '',
        });
      }

      await upsertSignature({
        variables: {
          data: { ...restData, fields: updatedFields },
          where: {
            signatureId: id,
            workspaceId,
          },
        },
      });

      await updateUser({
        variables: {
          data: { onboardingStatus: onboardingStatus?.COMPLETED },
        },
      });

      navigate(`/app/${workspaceId}/signature/${id}/headshot`);
    }
  };

  const [socialLogin, { loading: socialLoading }] = useMutation(LOGIN, {
    onCompleted: ({ loginUser }) => {
      const { accessToken, data, refreshToken } = loginUser;
      initializeAuth(accessToken, data, refreshToken, false);
      redirectToHeadshot(accessToken);
    },
  });

  const successCallback = async (accessToken, userData, refreshToken) => {
    initializeAuth(accessToken, userData, refreshToken, false);
    redirectToHeadshot(accessToken);
  };

  const successCallbackSocialAuth = async (accessToken) => {
    await socialLogin({ variables: { data: { token: accessToken } } });
  };

  const handleGoogleSignIn = async () => {
    try {
      const { user } = await signInWithGooglePopup();

      if (user?.accessToken) {
        const { accessToken } = user;
        await successCallbackSocialAuth(accessToken);
      }
    } catch (error) {
      // console.error('Google sign-in error', error);
    }
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
      };
      const response = await signupMutate({
        variables: { data: formValues },
      });

      const { accessToken, data, refreshToken } =
        response?.data?.signUp || response?.data?.loginUser || {};

      if (response?.data?.signUp || response?.data?.loginUser) {
        await successCallback(accessToken, data, refreshToken);
      } else {
        form.reset(values);
      }
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: error?.message || 'Something went wrong',
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={closeModal}>
      <DialogContent
        hideCloseButton
        className="h-[500px] sm:max-w-md max-w-lg sm:p-6"
      >
        <header>
          <div className="text-medium-l font-primary">Sign up with email</div>
        </header>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onFinish)}
            className="space-y-[27px] mt-[8px]"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="text-darkblue font-medium text-[12px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                      placeholder={EMAIL_PLACEHOLDER}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="text-darkblue font-medium text-[12px]">
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground hover:shadow-custom"
                      placeholder="Minimum 8 characters"
                      maxLength={16}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="h-[40px] w-full mt-2"
              loading={signupLoading || socialLoading}
            >
              <div className="flex items-center space-x-1">
                <span>Sign Up</span>
                <ChevronRight width="20" height="20" />
              </div>
            </Button>
          </form>
        </Form>
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex items-center my-4 w-full">
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
            <div className="text-secondary-400 mx-2 text-center text-xs font-semibold leading-[24px] font-primary whitespace-nowrap">
              OR
            </div>
            <div className="flex-1">
              <Separator className="bg-secondary-400 h-px" />
            </div>
          </div>
          <Button
            onClick={handleGoogleSignIn}
            variant="outline"
            className="h-[40px]"
            disabled={signupLoading || socialLoading}
          >
            <div className="flex justify-center items-center">
              <GoogleIcon />
              <span className="ml-2">Continue with Google</span>
            </div>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
