import { Button } from 'components/ui/button';
import React, { useRef } from 'react';

import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import { useAppContext } from 'AppContext';
import { useWorkspaceTemplateFeatures } from 'hooks/workspace-template';
import { Badge } from 'components/ui/badge';
import { Loader2 } from 'lucide-react';
import TemplatesModel from 'components/TemplatesModel';
import { keyBy, partition } from 'lodash';

const WorkspaceTempatePreview = ({
  isWorkspaceTemplateChanged,
  workspaceTemplate,
  handleSaveWorkspaceTemplate,
  handlePublishWorkspaceTemplate,
  installSignatureLoading,
  handleSetWorkspaceTemplate,
}) => {
  const { handleSubscriptionModal } = useAppContext();
  const templatesModalRef = useRef();
  const { isUserUsingProFeatures } = useWorkspaceTemplateFeatures({
    workspaceTemplate,
  });

  const isProFeature = isUserUsingProFeatures();
  const FinalTemplate = dynamicTemplate({
    templateKey: workspaceTemplate?.template?.key,
  });

  const handleTemplateSelect = async (data) => {
    const updatedSignature = { ...workspaceTemplate };
    // Create a new fields array
    const newFields = data.fields;

    const variableFieldsName = [
      'name',
      'position',
      'department',
      'phone',
      'email',
      'companyLogo',
      'headshotUrl',
    ];
    const signatureFields = [...(workspaceTemplate?.fields || [])];
    const [variableFields, nonVariableFields] = partition(newFields, (field) =>
      variableFieldsName.includes(field.name),
    );

    const nonVariableFieldsMap = keyBy(nonVariableFields, 'name');

    // Replace objects in nonVariableFields with those in variableFields if they have the same name
    const updatedFields = signatureFields.map((field) => {
      if (nonVariableFieldsMap[field.name]) {
        return nonVariableFieldsMap[field.name];
      }
      return field;
    });

    const submutData = {
      ...updatedSignature,
      fields: updatedFields,
      cta: data?.cta,
      template: data?.template || updatedSignature.template,
      design:
        {
          ...updatedSignature.design,
          font: data?.design?.font,
          styles: data?.design?.styles,
        } || data?.design,
      templateId: data?.templateId || updatedSignature.templateId,
    };
    // if (isCreate === 'true') {
    //   submutData.headshotUrl = data?.headshotUrl;
    //   submutData.fields = newFields;
    //   submutData.design = data?.design;
    // }
    submutData.fields = signatureFields;
    submutData.design = data?.design;
    // if (isCreate === 'false') {

    // }

    // setSignature(submutData);
    handleSetWorkspaceTemplate(submutData);
    templatesModalRef?.current?.toggle();
  };

  return (
    <>
      <TemplatesModel
        dialogProps={{ onOpenChange: templatesModalRef?.current?.toggle }}
        ref={templatesModalRef}
        handleTemplateSelect={handleTemplateSelect}
      />
      <div className="flex flex-col h-full w-full overflow-hidden">
        <header className="p-4 flex items-center justify-end gap-3">
          <div
            className="flex items-center cursor-pointer underline text-[#144CC7] font-primary font-bold"
            onClick={() => {
              templatesModalRef?.current?.open();
            }}
          >
            Change Template
          </div>
          {isProFeature ? (
            <div className={`text-regular-base font-primary  `}>
              You are using{' '}
              <Badge className="bg-primary border-[transparent] px-2 mr-[3px] h-auto  text-white-0 rounded-4">
                PRO
              </Badge>
              features{'  '}
              {/* <Button
              onClick={handleRevertToFree}
              style={{ padding: 0 }}
              variant="link"
            >
              <u>Revert to free</u>
            </Button> */}
            </div>
          ) : (
            <div />
          )}
          <div className="flex gap-4">
            <Button
              onClick={() => {
                if (isUserUsingProFeatures()) {
                  handleSubscriptionModal(true);
                  return;
                }
                handleSaveWorkspaceTemplate();
              }}
              disabled={installSignatureLoading || !isWorkspaceTemplateChanged}
            >
              Save Template
              {installSignatureLoading && (
                <Loader2 className="animate-spin ml-2 h-5 w-5" />
              )}
            </Button>
            {/* <Button
              onClick={() => {
                if(installSignatureLoading) return
                if (isUserUsingProFeatures()) {
                  handleSubscriptionModal(true);
                  return;
                }
                handlePublishWorkspaceTemplate();
              }}
            >
              Publish Changes
            </Button> */}
          </div>
        </header>
        <div className=" h-full overflow-y-scroll w-full  bg-white-0 rounded-[8px] mt-5 ml-5">
          {/* signature-body */}
          <div className="pt-4 px-4">
            {FinalTemplate ? (
              <div>
                <FinalTemplate
                  signature={workspaceTemplate}
                  showAwsIcons={false}
                />
              </div>
            ) : (
              <>
                <Button>Please select signature</Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceTempatePreview;
