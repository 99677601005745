/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_WORKSPACE_MUTATION = gql`
  mutation createWorkspace($data: CreateWorkspaceInput!) {
    createWorkspace(data: $data) {
      data {
        id
        name
        ownerId
        signatureLimit
        signatureUsed
      }
    }
  }
`;

export const UPDATE_WORKSPACE_MUTATION = gql`
  mutation updateWorkspace(
    $data: UpdateWorkspaceInput!
    $where: UpdateWorkspaceWhereInput!
  ) {
    updateWorkspace(data: $data, where: $where) {
      data {
        id
        name
        ownerId
        signatureLimit
        signatureUsed
      }
    }
  }
`;

export const GET_WORKSPACE_OWNER = gql`
  query GetWorkspaceOwner($where: GetWorkspaceByIdInput!) {
    getWorkspaceOwner(where: $where) {
      id
      email
      firstName
      lastName
      name
      profileImage
      role
      isActive
      lastActiveOn
      earlyAccess
      onboardingStatus
      isCouponUsed
      planId
      groupId
      signatureLimit
      workspaces
      phone
      department
      position
      activePlan {
        id
        name
        price
        allowedSignatures
        allowedTemplates
        features {
          key
          label
        }
      }
    }
  }
`;
