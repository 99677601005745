import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { omit, set } from 'lodash';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from 'components/ui/form';
import { useGetSignatureList } from 'hooks/signature';
import { z } from 'zod';
import { cloneAndRemoveTypename } from 'common/utils';
import { useWorkspaceId } from 'hooks/workspace';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { DUMMY_HEADSHOT_URL } from 'common/constants';
import SignatureCard from 'modules/Assets/components/Signatures/SignatureCard';
import { useAppContext } from 'AppContext';
import { useFeatures } from 'hooks/user';

const templateSchema = z.object({
  templateName: z.string().min(1, 'Template name is required'),
});

const CreateTemplate = ({ onClose, handleOnClick }) => {
  const {
    state: { groups },
  } = useWorkspaceContext();
  const { handleSubscriptionModal } = useAppContext();

  const { isUserUsingProFeatures } = useFeatures();
  const [getSignatureListing] = useGetSignatureList();
  const [signatures, setSignatures] = useState([]);
  const { workspaceId } = useWorkspaceId();

  const [selectedSignature, setSelectedSignature] = useState(null);

  const fetchSignatures = async (skip = 0) => {
    const list = await getSignatureListing({
      variables: {
        pagination: {
          skip,
          limit: 'max',
        },
        where: {
          workspaceId,
        },
      },
    });
    setSignatures(list?.data?.signatures?.data);
    setSelectedSignature(list?.data?.signatures?.data[0]);
  };

  useEffect(() => {
    fetchSignatures();
  }, []);

  const form = useForm({
    resolver: zodResolver(templateSchema),
    defaultValues: {
      templateName: '',
      signatureId: '',
      groupId: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const handleDataOnSubmit = (data) => {
    if (isUserUsingProFeatures()) {
      handleSubscriptionModal(true);
      return;
    }
    const { templateName: name } = data;
    const formatedSignature = omit(cloneAndRemoveTypename(selectedSignature), [
      'id',
      'name',
      'installation',
    ]);
    const updatedFields = selectedSignature?.fields?.map((field) =>
      field.name === 'headshotUrl'
        ? { ...field, value: field?.value ? DUMMY_HEADSHOT_URL : '' }
        : field,
    );
    handleOnClick({
      name,
      signature: { ...formatedSignature, fields: updatedFields },
    });
  };

  const handleSignatureSelect = (signature) => {
    setSelectedSignature(signature);
  };

  return (
    <Form {...form}>
      <form
        className="max-w-[inherit] p-0"
        onSubmit={handleSubmit(handleDataOnSubmit)}
      >
        <DialogHeader>
          <DialogTitle>Create Template</DialogTitle>
        </DialogHeader>
        <div>
          <div className="">
            <FormField
              control={control}
              name="templateName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Template Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      id="templateName"
                      placeholder="Enter Template Name"
                      className="h-[22px]"
                    />
                  </FormControl>
                  <FormMessage>{errors.templateName?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div>
          <div className="flex gap-4 mt-4 overflow-auto custom-scrollbar">
            <div>
              <p>Import from Signature</p>
              <div className="overflow-auto max-w-[855px] ">
                {signatures.length && (
                  <div>
                    <div className="flex p-2 gap-3">
                      {signatures?.map((signature) => (
                        <SignatureCard
                          signature={signature}
                          key={signature?.id}
                          handleSignatureCardOnClick={handleSignatureSelect}
                          hideAction
                          signatureTemplateClassname="scale-[0.4] translate-x-[-20px] translate-y-[-30px]"
                          signatureCardClassname={`max-w-[200px] min-w-[200px] h-[130px] m-0 ${
                            selectedSignature?.id === signature?.id
                              ? 'shadow-signature'
                              : ''
                          }`}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div>
            <FormField
              control={control}
              name="groupId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Assign Group</FormLabel>
                  <FormControl>
                    <Select
                      value={field.value}
                      onValueChange={(val) => field.onChange(val)}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select a Group" />
                      </SelectTrigger>
                      <SelectContent>
                        {groups?.map((group) => (
                          <SelectItem
                            value={group.id}
                            className="mb-0 h-full w-full"
                            key={group.id}
                          >
                            {group.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage>{errors.groupId?.message}</FormMessage>
                </FormItem>
              )}
            />
          </div> */}
          </div>
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <Button
            type="button"
            className="w-[max-content]"
            onClick={() => onClose()}
            variant="outline"
          >
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </Form>
  );
};

export default CreateTemplate;
