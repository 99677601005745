import { useAppContext } from 'AppContext';
import UserProfile from 'components/UserProfile';
import { UserOutlined } from '@ant-design/icons';
import { useRouter } from 'hooks/router';
import ApplyCoupon from 'modules/Common/CouponAction/ApplyCoupon';
import { useIsUserBrandOwner } from 'hooks/brand';
import { useWorkspaceId } from 'hooks/workspace';
import React from 'react';
import { Tag, Tags } from 'lucide-react';
import { useBrandContext } from 'context/BrandContext';

function ProfileAvatar() {
  const { navigate } = useRouter();
  const {
    state: { currentUser },
  } = useAppContext();
  const {
    state: { brand },
  } = useBrandContext();
  const { isUserBrandOwner } = useIsUserBrandOwner();
  const { workspaceId } = useWorkspaceId();
  const brandOwnerTabs = isUserBrandOwner(currentUser?.id, workspaceId);

  const extraItems = [
    {
      key: 'Profile',
      label: (
        <div
          onClick={() => navigate('/app/user/profile')}
          className="flex w-64 cursor-pointer"
        >
          <div className="flex-grow">Profile</div>
          <div className="flex items-start justify-center">
            <UserOutlined />
          </div>
        </div>
      ),
    },
    ...(brandOwnerTabs
      ? [
          {
            key: 'subscription',
            label: (
              <div
                onClick={() => navigate('/app/user/subscriptions')}
                className="flex w-64 cursor-pointer"
              >
                <div className="flex-grow">Plans</div>
                <div className="flex items-start justify-center">
                  <Tag size={15} />
                </div>
              </div>
            ),
          },
        ]
      : []),
    ...(brandOwnerTabs &&
    ![
      'https://app.syncsignature.com',
      'https://app.dev.syncsignature.com',
    ].includes(brand?.url)
      ? [
          {
            key: 'whitelabel',
            label: (
              <div
                onClick={() => navigate('/app/user/whitelabel')}
                className="flex w-64 cursor-pointer"
              >
                <div className="flex-grow">WhiteLabel</div>
                <div className="flex items-start justify-center">
                  <Tags size={15} />
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];

  const isFreeUser = currentUser?.activePlan?.id === '1';

  const isCouponAvailable = !currentUser?.isCouponUsed;

  return (
    <div className="text-primary-1000   flex items-center   gap-[22px]">
      {isCouponAvailable && isFreeUser && <ApplyCoupon />}
      <UserProfile extraItems={extraItems} />
    </div>
  );
}

export default ProfileAvatar;
