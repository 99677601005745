/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTwelve({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';

  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'companyLogo');

  const section3 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td>
          <table
            ref={mainContentRef}
            cellPadding="0px"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
              borderBottom: !allUrlsAreNull
                ? `1px solid ${design?.primaryBrandColor}`
                : '',
              paddingBottom: '8px',
            }}
          >
            {getFieldData(fields, 'headshotUrl') && (
              <tr align="center">
                <td align="left">
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                    style={{
                      borderCollapse: 'collapse !important',
                      fontSize: 'inherit',
                      marginBottom: '10px',
                    }}
                  >
                    <tr>
                      {HeadshotImage({
                        data: getFieldData(fields, 'headshotUrl'),
                        design,
                        style: {},
                        align: 'left',
                      })}
                    </tr>
                  </table>
                </td>
              </tr>
            )}
            <tr>
              {(getFieldData(fields, 'name') ||
                getFieldData(fields, 'position') ||
                getFieldData(fields, 'department') ||
                getFieldData(fields, 'companyName')) && (
                <td
                  style={{
                    borderTop: `1px solid ${design?.primaryBrandColor}`,
                    paddingRight: '14px',
                    paddingTop: '8px',
                  }}
                >
                  <table>
                    <tr>
                      <td
                        style={{
                          fontStyle: 'normal',
                        }}
                      >
                        <table cellPadding="0" cellSpacing="0" border="0">
                          {getFieldData(fields, 'name') && (
                            <tr>
                              {Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  fontWeight: 700,
                                  color: design?.primaryBrandColor,
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'position') && (
                            <tr>
                              {Position({
                                signature,
                                data: getFieldData(fields, 'position'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'department') && (
                            <tr>
                              {Department({
                                signature,
                                data: getFieldData(fields, 'department'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingRight: '8px',
                                },
                              })}
                            </tr>
                          )}

                          {getFieldData(fields, 'companyName') && (
                            <tr>
                              {CompanyName({
                                signature,
                                data: getFieldData(fields, 'companyName'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              )}

              {(getFieldData(fields, 'phone') ||
                getFieldData(fields, 'email') ||
                getFieldData(fields, 'website') ||
                getFieldData(fields, 'companyAddress')) && (
                <td
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 0,
                    lineHeight: '100%',
                    borderTop: `1px solid ${design?.primaryBrandColor}`,
                    paddingTop: '10px',
                  }}
                >
                  <table cellPadding="0" cellSpacing="0" border="0">
                    {getFieldData(fields, 'phone') && (
                      <tr>
                        {Phone({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'phone'),
                          style: {},
                        })}
                      </tr>
                    )}

                    {getFieldData(fields, 'email') && (
                      <tr>
                        {Email({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'email'),
                          style: {},
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'website') && (
                      <tr>
                        {Website({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'website'),
                          style: {},
                        })}
                      </tr>
                    )}
                    {getFieldData(fields, 'companyAddress') && (
                      <tr>
                        {CompanyAddress({
                          signature,
                          showAwsIcons,
                          design,
                          data: getFieldData(fields, 'companyAddress'),
                          style: {},
                        })}
                      </tr>
                    )}
                  </table>
                </td>
              )}
            </tr>
          </table>

          {!allUrlsAreNull && (
            <table
              cellPadding="0"
              cellSpacing=""
              border="0"
              width="100%"
              role="presentation"
              style={{
                borderCollapse: 'collapse !important',
                marginTop: '10px',
              }}
            >
              <tr>
                {!!socialFields?.length &&
                  SocialLinks({
                    signature,
                    showAwsIcons,
                    data: socialFields,
                    design,
                    style: { paddingBottom: '8px', verticalAlign: 'center' },
                    tdProps: {
                      valign: 'center',
                    },
                  })}

                {getFieldData(fields, 'companyLogo') &&
                  CompanyLogo({
                    data: getFieldData(fields, 'companyLogo'),
                    design,
                    style: {
                      paddingBottom: '8px',
                    },
                    toProps: {
                      colSpan: 2,
                      align: 'right',
                      valign: 'center',
                    },
                  })}
              </tr>
            </table>
          )}
        </td>
      </tr>
      <tr>
        <td>
          <table>
            <tr>
              {getFieldData(fields, 'ctaUrl') &&
                getFieldData(fields, 'ctaTitle') &&
                CallToAction({
                  signature,
                  showAwsIcons,
                  design,
                  data: {
                    ctaUrl: getFieldData(fields, 'ctaUrl'),
                    ctaTitle: getFieldData(fields, 'ctaTitle'),
                  },
                  style: {
                    paddingBottom: '8px',
                  },
                })}
            </tr>
            <tr>
              {getFieldData(fields, 'ctaBannerImg') &&
                getFieldData(fields, 'ctaBannerUrl') &&
                CallToActionBanner({
                  data: {
                    ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                    ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                  },
                  design,
                  style: { textAlign: 'left' },
                })}
            </tr>

            <tr>
              <td>
                <table width={tableWidth}>
                  <tr>
                    {getFieldData(fields, 'footer') &&
                      Footer({
                        data: getFieldData(fields, 'footer'),
                        design,
                      })}
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <Branding />
      </tr>
    </table>
  );
}
export default TemplateStyleTwelve;
