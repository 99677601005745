/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Separator } from 'components/ui/separator';
import { Slider } from 'components/ui/slider';
import { Label } from 'components/ui/label';
import React, { useEffect, useRef } from 'react';
import { useAppContext } from 'AppContext';
import { PRIMARY_COLOR } from 'common/constants';
import ColorPicker from 'components/ColorPicker';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import {
  useGetSignature,
  useSaveSignature,
  useSignatureId,
} from 'hooks/signature';
import Button from 'components/Button';
import { useWorkspaceId } from 'hooks/workspace';

const designSchema = z.object({
  font: z.string().default('Arial, Helvetica, sans-serif'),
  themeColor: z.string().default(PRIMARY_COLOR),
  iconType: z.string().default('rounded'),
  iconSize: z.number().min(11).max(30).default(20),
  socialIconType: z.string().default('rounded'),
  socialIconSize: z.number().min(11).max(30).default(20),
  fontSize: z.number().min(11).max(18).default(14),
  headerFontSize: z.number().min(11).max(18).default(13),
  lineHeight: z.number().min(0.8).max(2).default(1.5),
  textColor: z.string().default('#000000'),
});

const Fonts = [
  { value: 'Arial, Helvetica, sans-serif', label: 'Arial' },
  { value: 'Comic Sans MS , cursive, sans-serif', label: 'Comic Sans MS' },
  { value: 'Courier new, courier, monospace', label: 'Courier New' },
  { value: 'Georgia, serif', label: 'Georgia' },
  { value: 'Lucida Console, Monaco, monospace', label: 'Lucida Console' },
  { value: 'Tahoma,Geneva,Sans-serif', label: 'Tahoma' },
  { value: 'Times New Roman, Times, serif', label: 'Times New Roman' },
  { value: 'Trebuchet MS, Helvetica, sans-serif', label: 'Trebuchet MS' },
  { value: 'Verdana, Geneva, sans-serif', label: 'Verdana' },
];

function DesignControl() {
  const {
    state: { signature },
    setSignature,
  } = useAppContext();
  const { workspaceId } = useWorkspaceId();

  const form = useForm({
    resolver: zodResolver(designSchema),
    values: {
      font: signature?.design?.font || 'Arial, Helvetica, sans-serif',
      themeColor: signature?.design?.primaryBrandColor || PRIMARY_COLOR,
      iconType: signature?.design?.styles?.icon?.type || 'rounded',
      iconSize: parseFloat(signature?.design?.styles?.icon?.style?.size) || 20,
      socialIconType: signature?.design?.styles?.socialIcon?.type || 'rounded',
      socialIconSize:
        parseFloat(signature?.design?.styles?.socialIcon?.style?.size) || 20,
      fontSize:
        parseFloat(signature?.design?.styles?.font?.style?.fontSize) || 14,
      headerFontSize:
        parseFloat(signature?.design?.styles?.headerFont?.style?.fontSize) ||
        parseFloat(signature?.design?.styles?.font?.style?.headerfontsize) ||
        13,
      lineHeight:
        parseFloat(signature?.design?.styles?.font?.style?.lineHeight) || 1.5,
      textColor: signature?.design?.styles?.font?.style?.color || '#000000',
    },
  });

  const { isDesignStyleChanged } = useSaveSignature();
  const [getSignature] = useGetSignature();
  const { signatureId } = useSignatureId();

  const previousValuesRef = useRef({});

  const watchFields = form.watch();


  const handleFormChange = () => {
    const {
      font,
      themeColor,
      iconType,
      iconSize,
      socialIconType,
      socialIconSize,
      fontSize,
      headerFontSize,
      lineHeight,
      textColor,
    } = form.getValues();

    const updatedStyles = {
      icon: { type: iconType, style: { size: iconSize } },
      socialIcon: { type: socialIconType, style: { size: socialIconSize } },
      font: {
        style: {
          fontSize,
          lineHeight,
          color: textColor,
        },
      },
      headerFont: {
        style: {
          fontSize: headerFontSize,
        },
      },
    };

    const newSignature = {
      ...signature,
      design: {
        ...signature?.design,
        primaryBrandColor: themeColor,
        font,
        styles: { ...(signature?.design?.styles ?? {}), ...updatedStyles },
      },
    };

    if (
      JSON.stringify(previousValuesRef.current) !== JSON.stringify(newSignature)
    ) {
      setSignature(newSignature);
      previousValuesRef.current = newSignature;
    }
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, signature]);

  const resetToDefault = () => {
    getSignature({
      variables: {
        where: { signatureId: signatureId || signature?.id || '', workspaceId },
      },
    });
  };

  return (
    <div className="text-neutral-1000">
      <header className=" px-[16px] h-[102px] flex items-center">
        <h1 className="font-primary text-h6 font-bold">Design</h1>
      </header>

      <Form
        onValueChange={(value) => {
          console.log('value', value);
        }}
        {...form}
      >
        <div className="p-4">
          <div className="bg-white-0 rounded mb-4 pb-1 px-2">
            <FormField
              name="font"
              control={form.control}
              render={({ field }) => (
                <FormItem className="mb-0 h-full flex items-center justify-between">
                  <div className="mr-2">Font</div>
                  <FormControl>
                    <Select
                      value={field.value}
                      onValueChange={(val) => field.onChange(val)}
                      className="bg-white-0"
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select Your Font" />
                      </SelectTrigger>
                      <SelectContent>
                        {Fonts.map((item) => (
                          <SelectItem
                            value={item.value}
                            className="mb-0 h-full w-full"
                            key={item.label}
                          >
                            {item.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded p-0 px-2">
            <FormField
              control={form.control}
              name="themeColor"
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px] mb-0">
                    Theme color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.primaryBrandColor || PRIMARY_COLOR
                      }
                      value={field.value}
                      colorPickerWrapperClassName="justify-end mb-2"
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="iconType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="leading-[36px] text-[14px]">Icon Type</div>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="none" id="none" />
                        <Label htmlFor="none">None</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="rounded" id="rounded" />
                        <Label htmlFor="rounded">Rounded</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="square" id="square" />
                        <Label htmlFor="square">Square</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="letter" id="letter" />
                        <Label htmlFor="letter">Letter</Label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="word" id="word" />
                        <Label htmlFor="word">Word</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {(watchFields.iconType === 'rounded' ||
              watchFields.iconType === 'square') && (
              <>
                <Separator className="mt-4 mb-4 bg-primary-foreground" />
                <FormField
                  name="iconSize"
                  control={form.control}
                  render={({ field }) => (
                    <FormItem className="m-0">
                      <div className="mb-2 flex justify-between text-medium-base font-primary">
                        <span>Icon size</span>
                        <span>{field.value}px</span>
                      </div>
                      <FormControl>
                        <Slider
                          {...field}
                          min={14}
                          max={22}
                          value={[field.value]}
                          onValueChange={([value]) => field.onChange(value)}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>

          <div className="bg-white-0 mb-4 rounded pt-1 pb-4 px-2">
            <FormField
              name="socialIconType"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="leading-[36px] text-[14px]">
                    Social Media Icon Type
                  </div>
                  <FormControl>
                    <RadioGroup
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="rounded"
                          id="rounded-socialIconType"
                        />
                        <Label htmlFor="rounded-socialIconType">Rounded</Label>
                        <RadioGroupItem
                          value="square"
                          id="square-socialIconType"
                        />
                        <Label htmlFor="square-socialIconType">Square</Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="socialIconSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Social Media Icon size</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={14}
                      max={22}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="bg-white-0 mb-4 rounded pt-4 pb-1 px-2">
            <FormField
              name="fontSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Font Size</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={11}
                      max={18}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="headerFontSize"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Name Font Size</span>
                    <span>{field.value}px</span>
                  </div>
                  <FormControl>
                    <Slider
                      min={11}
                      max={20}
                      step={1}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-4 bg-primary-foreground" />
            <FormField
              name="lineHeight"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0">
                  <div className="mb-2 flex justify-between text-medium-base font-primary">
                    <span>Line Height</span>
                    <span>{field.value}</span>
                  </div>
                  <FormControl>
                    <Slider
                      {...field}
                      min={0.8}
                      max={2.5}
                      step={0.01}
                      value={[field.value]}
                      onValueChange={([value]) => field.onChange(value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="mt-4 mb-3 bg-primary-foreground" />
            <FormField
              name="textColor"
              control={form.control}
              render={({ field }) => (
                <FormItem className="m-0 flex items-center">
                  <div className="whitespace-nowrap text-[14px]">
                    Text Color
                  </div>
                  <FormControl>
                    <ColorPicker
                      defaultValue={
                        signature?.design?.styles?.font?.style?.color ||
                        '#000000'
                      }
                      colorPickerWrapperClassName="justify-end mb-2"
                      color={field.value}
                      onChange={(value) =>
                        field.onChange(`#${value.toHex().slice(0, 6)}`)
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button
            variant="outline"
            className="w-[50%]"
            disabled={!isDesignStyleChanged}
            onClick={resetToDefault}
          >
            Reset to Default
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DesignControl;
