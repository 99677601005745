/* eslint-disable no-undef */
import { useAppContext } from 'AppContext';
import { Card } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { Badge } from 'components/ui/badge';
import { ChevronRight } from 'lucide-react';
import React, { useEffect } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import { CheckBoxIcon } from 'assets/svg';
import { get } from 'lodash';
import { useGetLmLtdPlanCheckoutLink } from 'hooks/plans';
import Button from './Button';

const PRODUCTS_NAME = {
  AI_HEADSHOT_CREDITS: 'AI_HEADSHOT_CREDITS',
  AI_HEADSHOT_PACKAGE: 'AI_HEADSHOT_PACKAGE',
  AGENCY_LTD: 'AGENCY_LTD',
  RESELLER_LTD: 'RESELLER_LTD',
  SOLO_LTD: 'SOLO_LTD',
  TEAM_LTD: 'TEAM_LTD',
};

const mapPlanNameToProductName = (planName) => {
  switch (planName) {
    case 'SOLO_LTD':
      return PRODUCTS_NAME.SOLO_LTD;
    case 'TEAM_LTD':
      return PRODUCTS_NAME.TEAM_LTD;
    case 'AGENCY_LTD':
      return PRODUCTS_NAME.AGENCY_LTD;
    case 'RESELLER_LTD':
      return PRODUCTS_NAME.RESELLER_LTD;
    case 'AI_HEADSHOT_CREDITS':
      return PRODUCTS_NAME.AI_HEADSHOT_CREDITS;
    case 'AI_HEADSHOT_PACKAGE':
      return PRODUCTS_NAME.AI_HEADSHOT_PACKAGE;
    default:
      return null;
  }
};

const LifeTimeAccessCard = ({
  bordered = true,
  plan,
  isPlanActivated = false,
}) => {
  const {
    state: { currentUser },
  } = useAppContext();

  const [getCheckoutLink, { data, loading, error }] =
    useGetLmLtdPlanCheckoutLink();
  const isAcitivated = plan?.id === currentUser?.activePlan?.id;
  const signatureLimit = get(currentUser, 'signatureLimit', null);
  const isAleardyHaveAcitivated = Number(currentUser?.planId) > 1;
  const handleBuyNowClick = async () => {
    try {
      const productName = mapPlanNameToProductName(plan.name);
      if (!productName) {
        // console.error('No product name found for this plan');
        return;
      }

      const response = await getCheckoutLink({ variables: { productName } });
      const checkoutUrl = response?.data?.getLmLtdPlanCheckoutLink?.url;
      if (checkoutUrl) {
        window.location.href = checkoutUrl; // Redirect to the fetched URL
      }
    } catch (error) {
      // console.error('Error fetching checkout URL:', error);
    }
  };

  return (
    <Card
      bordered={bordered}
      className="rounded-lg grid p-6 h-full shadow-none"
    >
      <div className="flex justify-between h-full w-full flex-col">
        <div>
          <div className="mb-4 text-primary text-[24px] leading-[36px] font-semibold">
            {plan?.name}
          </div>
          <div className="text-primary-1000 text-[40px] leading-[48px] font-semibold mb-[1px]">
            ${plan?.price}
          </div>
          <div>
            <Badge className="text-primary">One time Payment</Badge>
          </div>
          <div className="flex items-start flex-col gap-[4px]">
            <Separator className="my-4 bg-secondary-400" />
            {plan?.features?.map(({ label }) => (
              <div
                key={label}
                className="grid grid-cols-[30px,1fr] items-center mb-1 text-[14px] font-medium"
              >
                <CheckBoxIcon className="mr-6 bg-primary h-4 w-4 rounded" />
                <span>{label}</span>
              </div>
            ))}
          </div>
          {isAcitivated && (
            <div className="flex text-[16px] items-start flex-row gap-[4px] mt-6 ">
              Signature Limit :
              <span className="text-primary font-[700]">{signatureLimit}</span>
            </div>
          )}
        </div>
        <div className="mt-auto">
          <Separator className="mt-4 mb-4 bg-secondary-400" />

          {!isAcitivated ? (
            <Button
              className="leading-[25px] !text-white-0 rounded-md py-1 px-5 h-[40px] w-full font-primary text-button-s mt-[6px] font-medium"
              onClick={handleBuyNowClick}
              disabled={loading || isAleardyHaveAcitivated}
            >
              {loading ? 'Processing...' : 'BUY NOW'} <ChevronRight />
            </Button>
          ) : (
            <Button
              onClick={() => {}}
              className="leading-[25px] rounded-md py-[4px] px-[20px] h-[40px] w-full text-[white]  mt-[6px] font-medium"
            >
              ACTIVATED
            </Button>
          )}
          {error && <p className="text-red-500 mt-2">Error: {error.message}</p>}
        </div>
      </div>
    </Card>
  );
};

export default LifeTimeAccessCard;
