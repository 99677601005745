// appleMail.js
// eslint-disable-next-line import/prefer-default-export
export const appleMailContent = `
  <div className="flex items-center">
    <h1 className="ml-2 text-xl font-normal">Add to Apple Mail (Mac OS)</h1>
  </div>
  <div class="mt-6 steps">
    <ol class="list-none p-0 m-0">
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">1</div>
        <span>With the Mail application open, Click Mail and then Preferences in the top left corner of your screen.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">2</div>
        <span>From the preferences pop-up, click the Signatures tab.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">3</div>
        <span>Click the + button at the bottom of the middle column.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">4</div>
        <span>Copy and paste your new email signature into the right-hand column and close the pop-up window to save.</span>
      </li>
      <div class="bg-gray-100 border border-gray-100 p-4 rounded-md text-yellow-800 mb-4">
        <strong>IMPORTANT:</strong> Ensure that "Always match my default message font" is unchecked, otherwise your signature will appear in plain text.
      </div>
      <div class="bg-gray-100 border border-gray-100 p-4 rounded-md text-yellow-800 mb-4">
        <strong>IMPORTANT:</strong> Still seeing a plain text version? Try toggling the "Place signature above quoted text" checkbox and pasting in your signature again.
      </div>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">5</div>
        <span>Select your new signature in the "Choose Signature" field near the bottom of the pop-up.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">6</div>
        <span>Close the Preferences pop-up, compose a new email, and enjoy your new signature!</span>
      </li>
      <div class="bg-gray-100 border border-gray-100 p-4 rounded-md text-yellow-800 mb-4">
        <strong>IMPORTANT:</strong> Images not appearing when you compose an email? Go to your Privacy settings and ensure that Block All Remote Content is unchecked.
      </div>
    </ol>
  </div>
`;
