/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useAppContext } from 'AppContext';
import { get } from 'lodash';
import { cn } from 'lib/utils';
import { getLiveUrl } from 'common/utils';
import ImageCard from './ImageCard';

const ImageGrid = ({
  previousImages = [],
  getPrediction,
  warpperClassNames = '',
  isSolo = false,
  removeoverlay = false,
}) => {
  const {
    state: { aiHeadshot },
    setAiHeadshot,
  } = useAppContext();

  const handleDownload = async (url) => {
    const imageUrl = getLiveUrl(url);
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `ai-headshot-${new Date().getTime()}.jpeg`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const cloneImages = Array.isArray(previousImages)
    ? [...previousImages].reverse()
    : [];

  const updatePreviousImages = (
    previousImages = aiHeadshot?.previousImages,
  ) => {
    setAiHeadshot({
      ...aiHeadshot,
      previousImages,
    });
  };

  const fetchImage = async (id) => {
    const response = await getPrediction({ variables: { data: { id } } });
    const imageUrl = get(response, 'data.getPrediction.data.image.url');
    const status = get(response, 'data.getPrediction.data.image.status');
    const previousImages = get(
      response,
      'data.getPrediction.data.previousImages',
    );
    if (imageUrl && status === 'completed') {
      updatePreviousImages(previousImages);
    }
  };

  return (
    <div className={cn('w-full justify-center flex flex-wrap gap-2', warpperClassNames)}>
      {cloneImages.map((image) => (
        <ImageCard
          key={image.id}
          id={image.id}
          url={image.url}
          status={image.status}
          input={image.input}
          model={image.model}
          handleDownload={handleDownload}
          getPrediction={getPrediction}
          fetchImage={fetchImage}
          isSolo={isSolo}
          removeoverlay={removeoverlay}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
