import React from 'react';
import { useDropzone } from 'react-dropzone';
import { cn } from 'lib/utils'; // Ensure you have this utility function for classnames
import { toast } from 'hooks/use-toast';

const BaseDragger = ({
  handleChange = () => {},
  className,
  dropZoneProps = {
    multiple: false,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 2MB in bytes
  },
  allowGif = false,
  children,
}) => {
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast({
        closeicn: 'destructive',
        description:
          'Some files were rejected due to size limit(2 MB) or unsupported format.',
      });
    }
    const newFiles = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    handleChange(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      ...(allowGif && { 'image/gif': ['.gif'] }), // Conditionally include GIFs
    },
    ...dropZoneProps,
  });

  return (
    <div
      {...getRootProps()}
      className={cn(
        `rounded-md py-4 text-center flex items-center justify-center text-[16px] border-[1px] border-dashed border-secondary-400 hover:border-primary bg-transparent ${
          isDragActive ? 'border-blue-500' : ''
        }`,
        className,
      )}
    >
      <input {...getInputProps()} />
      {children || <p>Drag & drop some files here, or click to select files</p>}
    </div>
  );
};

export default BaseDragger;
