import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table';
import EditWorkspaceDialog from 'components/WorkspaceSwitcher/components/EditWorkspaceDialog';
import { useRouter } from 'hooks/router';
import { Pencil } from 'lucide-react';
import { useFetchWorkspaces } from 'hooks/workspace';
import Pagination from './components/Pagination'; // Import the Pagination component

const WorkspaceSignatureTable = () => {
  const { navigate } = useRouter();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [fetchWorkspaces, { data, loading, error }] = useFetchWorkspaces();
  const [workspaces, setWorkspaces] = useState([]);

  useEffect(() => {
    // Fetch all workspaces initially
    fetchWorkspaces({ variables: {} }).then((response) => {
      setWorkspaces(response.data.getWorkspaces.data || []);
    });
  }, []);

  const handleWorkspaceClick = (id) => {
    navigate(`/app/workspace/${id}/assets`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const editWorkspaceDialogRef = useRef(); // Add this line

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Calculate the workspaces to display on the current page
  const offset = (currentPage - 1) * itemsPerPage;
  const currentWorkspaces = workspaces.slice(offset, offset + itemsPerPage);
  const totalCount = workspaces.length;

  return (
    <>
      <EditWorkspaceDialog ref={editWorkspaceDialogRef} />
      <Table>
        <TableHeader>
          <TableRow className="max-w-[1110px]">
            <TableHead>Workspace name</TableHead>
            <TableHead>Signature limit</TableHead>
            <TableHead>Used signatures</TableHead>
            <TableHead>Remaining signatures</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {currentWorkspaces.map((item) => (
            <TableRow key={item.id}>
              <TableCell
                className="font-medium cursor-pointer"
                onClick={() => handleWorkspaceClick(item.id)}
              >
                {item.name}
              </TableCell>
              <TableCell>{item.signatureLimit}</TableCell>
              <TableCell>{item.signatureUsed}</TableCell>
              <TableCell>{item.signatureLimit - item.signatureUsed}</TableCell>
              <TableCell
                className="cursor-pointer"
                onClick={() => {
                  editWorkspaceDialogRef.current.open();
                  editWorkspaceDialogRef.current.onDataChange(item);
                }}
              >
                <Pencil size={16} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(totalCount / itemsPerPage)}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default WorkspaceSignatureTable;
