/* eslint-disable arrow-body-style */
import React from 'react';
import { Button } from 'components/ui/button';
import { LockKeyhole } from 'lucide-react';
import { useRouter } from 'hooks/router';

// Component for the content inside the restriction message
const RestrictionContent = ({ onPurchasePlan }) => (
  <div className="max-w-xs p-4 bg-white-0 rounded-lg shadow-md text-center">
    <LockKeyhole className="mx-auto h-8 w-8 text-primary" />
    <h2 className="mt-2 text-lg font-medium">This requires a paid plan!</h2>
    <p className="mt-1 text-sm text-gray-600">
      This feature is not available since it requires a paid plan. Purchase a
      plan or contact us for more details.
    </p>
    <div className="mt-4">
      <Button onClick={onPurchasePlan} variant="default">
        Purchase Plan
      </Button>
    </div>
  </div>
);

const RestrictionDialog = ({ isVisible = true }) => {
  const { navigate } = useRouter();

  if (!isVisible) return null;

  const handlePurchasePlan = () => {
    navigate('/app/user/subscriptions');
  };

  return (
    <div className="w-full h-[calc(100vh_-_71px)] flex justify-center items-center bg-primary-foreground">
      <RestrictionContent onPurchasePlan={handlePurchasePlan} />
    </div>
  );
};

export default RestrictionDialog;
