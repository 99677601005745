/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import {
  useGetPaddleCheckoutTransactionId,
  useGetRecurringPlans,
  useSubscriptionDetails,
  useUpdatePaddleSubscriptionPlan,
} from 'hooks/plans';
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs';
import { Card } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { Slider } from 'components/ui/slider';
import { useAppContext } from 'AppContext';
import {
  useTotalSignatureWorkspace,
  useFetchWorkspaces,
} from 'hooks/workspace';
import { getBaseURL } from 'common/utils';

const SubscriptionPlans = () => {
  const {
    state: {
      currentUser: { id: userId },
    },
  } = useAppContext();

  const baseUrl = getBaseURL();
  const { totalSignatureUsed } = useTotalSignatureWorkspace();
  const [selectedTab, setSelectedTab] = useState('solo');
  const [plans, setPlans] = useState([]);
  const [paddle, setPaddle] = useState(null);
  const [userCount, setUserCount] = useState(5);
  const [activaPlan, setactivaPlan] = useState(null);
  const [isPlanActive, setIsPlanActive] = useState(false);
  const [getRecurringPlans] = useGetRecurringPlans();
  const { fetchSubscriptionDetails, data: subscriptionData } =
    useSubscriptionDetails();
  const [fetchWorkspaces] = useFetchWorkspaces();

  const paddleClientToken =
    process.env.PADDLE_CLIENT_TOKEN || 'test_d515d7f79b8325b6ee5b55c8db5';

  useEffect(() => {
    const fetchPlansData = async () => {
      const { data } = await getRecurringPlans();
      const filteredPlans = data.getRecurringPlans.filter((plan) =>
        ['SOLO', 'TEAMS'].includes(plan.name),
      );
      // eslint-disable-next-line no-console
      console.log(filteredPlans);
      setPlans(filteredPlans);
    };

    fetchPlansData();

    initializePaddle({
      environment: 'sandbox',
      token: paddleClientToken,
      eventCallback(data) {
        if (data.name === 'checkout.completed') {
          if (data.name === 'checkout.completed') {
            fetchSubscriptionDetails(userId);
          }
        }
      },
    }).then(setPaddle);
    if (userId) {
      fetchSubscriptionDetails(userId);
    }
  }, [getRecurringPlans]);

  const [getPaddleCheckoutTransactionId] = useGetPaddleCheckoutTransactionId();
  const [updatePaddleSubscriptionPlan] = useUpdatePaddleSubscriptionPlan();

  useEffect(() => {
    if (subscriptionData?.getSubscriptionDetails?.priceId) {
      const planid = subscriptionData?.getSubscriptionDetails?.planId;
      setactivaPlan(planid);

      const activePlan = plans
        .flatMap((plan) => plan.paddlePrices)
        .find(
          (price) =>
            price.id === subscriptionData.getSubscriptionDetails.priceId,
        );

      if (activePlan) {
        setIsPlanActive(true);

        let customData = activePlan.custom_data;

        // Parse the custom data if it's a string
        if (typeof customData === 'string') {
          try {
            customData = JSON.parse(customData);
          } catch (e) {
            customData = {};
          }
        }

        // Update the user count from signature_limit
        const signatureLimit = Number(customData?.signature_limit);
        if (signatureLimit && userCount !== signatureLimit) {
          setUserCount(signatureLimit);
        }
      }
    } else {
      setactivaPlan(null);
    }
  }, [subscriptionData, plans]);

  useEffect(() => {
    // Fetch workspaces on component mount
    fetchWorkspaces();
  }, [fetchWorkspaces]);

  const isActivePlan = (planPriceId, planid) => {
    if (subscriptionData?.getSubscriptionDetails?.priceId) {
      return planPriceId === subscriptionData.getSubscriptionDetails.priceId;
    }
    return false;
  };

  // eslint-disable-next-line no-console
  console.log(subscriptionData);

  const openCheckout = async (priceId, planId) => {
    // Check if the plan is already active

    if (isPlanActive) {
      // If plan is active, call the mutation to update the plan
      try {
        const { data: updatedPlan } = await updatePaddleSubscriptionPlan({
          variables: { where: { priceId } }, // No need to pass userId
        });

        if (updatedPlan?.updatePaddleSubscriptionPlan?.success) {
          fetchSubscriptionDetails(userId); // Refresh subscription details
        } else {
          // console.error('Failed to update the subscription plan.');
        }
      } catch (error) {
        // console.error('Error updating subscription plan:', error);
      }
      return;
    }

    // If not active, proceed with Paddle Checkout
    const res = await getPaddleCheckoutTransactionId({
      variables: { where: { priceId } },
    });
    const transactionId =
      res?.data?.getpaddleCheckoutTransactionId?.transactionId;

    if (paddle && transactionId) {
      paddle.Checkout.open({
        transactionId,
        settings: {
          successUrl: `${baseUrl}/app/user/billing`,
        },
        customData: {
          userId,
          planId,
        },
      });
    }
  };

  const renderPlanCard = (plan, price) => {
    const isActive = isActivePlan(price.id);
    if (
      subscriptionData?.getSubscriptionDetails?.priceId === price.id &&
      !isActive
    ) {
      return null;
    }
    return (
      <Card
        bordered
        key={price.id}
        className={cn('p-4 shadow-md rounded-xl w-full', {
          'border-primary': isActive, // Highlight the active plan
          'border-gray-300': !isActive,
        })}
      >
        <div>
          <h1 className="text-2xl font-semibold mb-4">{price.description}</h1>
          <div className="text-lg mb-4">
            ${price.unit_price.amount / 100} / {price.billing_cycle.interval}
          </div>
          <Button
            onClick={() => openCheckout(price.id, plan?.id)}
            className="w-full py-3 bg-primary text-white-0 rounded-xl"
            disabled={isActive}
          >
            {isActive ? 'Active Plan' : 'Choose Plan'}
          </Button>
          <ul className="mb-4 space-y-2">
            {plan.features.map((feature, idx) => (
              <li key={idx} className="text-primary">
                {feature.label}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    );
  };

  const renderSoloPlans = () =>
    plans
      .filter((plan) => plan.name === 'SOLO')
      .flatMap((plan) =>
        plan.paddlePrices.map((price) => renderPlanCard(plan, price)),
      );
  const predefinedValues = [
    5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100, 101,
  ];

  const handleSliderChange = (value) => {
    setUserCount(value[0]);
  };

  const handleContactUs = () => {
    // eslint-disable-next-line no-undef
    window.open(
      'mailto:support@syncsignature.com?subject=Subscription%20Inquiry&body=I%20would%20like%20to%20know%20more%20about%20plans%20for%20more%20than%20101%20users.',
    );
  };

  const renderTeamPlans = () => {
    const teamPlans = plans.find((plan) => plan.name === 'TEAMS');

    if (!teamPlans) return null;

    // Map the signature_limit from custom_data with the corresponding priceId
    const priceMapping = teamPlans.paddlePrices.reduce((acc, price) => {
      let customData = price.custom_data;

      if (typeof customData === 'string') {
        try {
          customData = JSON.parse(customData);
        } catch (e) {
          customData = {};
        }
      }

      const signatureLimit = Number(customData?.signature_limit);
      const perUser = customData?.Per_User || 0;

      if (predefinedValues.includes(signatureLimit)) {
        acc[signatureLimit] = { priceId: price.id, perUser };
      }

      return acc;
    }, {});

    const priceData = priceMapping[userCount] || {
      priceId: teamPlans.paddlePrices[0].id,
      perUser: 0,
    };
    const selectedPrice = teamPlans.paddlePrices.find(
      (price) => price.id === priceData.priceId,
    );

    const totalPrice = selectedPrice?.unit_price.amount || 0;
    const billingInterval = selectedPrice?.billing_cycle.interval || 'month';
    const perUserCost = priceData.perUser;
    const monthlyPrice = perUserCost * userCount;
    const yearlyPriceBeforeDiscount = monthlyPrice * 12;

    const isActive = isActivePlan(priceData.priceId, teamPlans.id);
    const disablePlanSelection = totalSignatureUsed > userCount;

    return (
      <Card bordered className="p-4 shadow-md rounded-xl w-full">
        <div>
          <h1 className="text-2xl font-semibold mb-4">TEAMS</h1>

          <div className="flex flex-col items-center mb-4">
            <span className="font-medium text-lg mb-2">
              {userCount === 101 ? ' 101+' : `${userCount}`} Signatures
            </span>
            <Slider
              range={false}
              value={[userCount]}
              min={5}
              max={101}
              step={1}
              onValueChange={(value) => {
                const closestValue = predefinedValues.reduce((prev, curr) =>
                  Math.abs(curr - value[0]) < Math.abs(prev - value[0])
                    ? curr
                    : prev,
                );
                handleSliderChange([closestValue]);
              }}
              className="w-full"
            />
          </div>

          {userCount !== 101 && (
            <>
              <div className="text-lg mb-1">${perUserCost} / user</div>
              <div className="text-lg mb-1 font-semibold">
                ${monthlyPrice} total / month
              </div>
              <div className="text-lg mb-4">
                <span className="line-through">
                  ${yearlyPriceBeforeDiscount.toFixed(2)}
                </span>{' '}
                ${totalPrice / 100} / {billingInterval} (20% off)
              </div>
            </>
          )}

          {isActive ? (
            <Button
              className="w-full py-3 bg-primary text-white-0 rounded-xl"
              disabled
            >
              Active Plan
            </Button>
          ) : disablePlanSelection ? (
            <div className="text-red-500 font-semibold text-center text-wrap mb-4">
              You have already used the limit of signatures for this plan.
              Please select a higher limit.
            </div>
          ) : userCount === 101 ? (
            <Button
              onClick={handleContactUs}
              className="w-full py-3 bg-primary text-white-0 rounded-xl"
            >
              Contact Us
            </Button>
          ) : (
            <Button
              onClick={() => openCheckout(priceData.priceId, teamPlans?.id)}
              className="w-full py-3 bg-primary text-white-0 rounded-xl"
            >
              Upgrade to TEAMS
            </Button>
          )}

          <ul className="mt-4 space-y-2">
            {teamPlans.features.map((feature, idx) => (
              <li key={idx} className="text-primary">
                {feature.label}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    );
  };

  return (
    <div className="flex flex-col items-center w-full">
      <Tabs
        defaultValue="solo"
        onValueChange={setSelectedTab}
        className="w-fit"
      >
        <TabsList className="bg-primary-foreground px-1 rounded-full mb-4 flex justify-center">
          {/* {activaPlan !== 7 && (
            <TabsTrigger
              value="solo"
              className={cn(
                'rounded-full flex items-center justify-center border-0 p-4 bg-[transparent] cursor-pointer',
                'data-[state=active]:bg-primary data-[state=active]:text-white-0',
              )}
            >
              Personal
            </TabsTrigger>
          )} */}
          <TabsTrigger
            value="solo"
            className={cn(
              'rounded-full flex items-center justify-center border-0 p-4 bg-[transparent] cursor-pointer',
              'data-[state=active]:bg-primary data-[state=active]:text-white-0',
            )}
          >
            Personal
          </TabsTrigger>
          <TabsTrigger
            value="teams"
            className={cn(
              'rounded-full flex items-center justify-center border-0 p-4 bg-[transparent] cursor-pointer',
              'data-[state=active]:bg-primary data-[state=active]:text-white-0',
            )}
          >
            Business
          </TabsTrigger>
        </TabsList>

        <TabsContent value="solo">
          <div className={cn('gap-12 grid', 'grid-cols-2')}>
            {renderSoloPlans()}
          </div>
        </TabsContent>

        <TabsContent value="teams">{renderTeamPlans()}</TabsContent>
      </Tabs>

      {/* Contact Us link below the tabs */}
      <div className="mt-4">
        <div className="mt-4">
          <Button onClick={handleContactUs} className="" variant="link">
            If you have any questions, Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
