/* eslint-disable no-undef */
import { useMutation, useLazyQuery } from '@apollo/client';
import { Separator } from 'components/ui/separator';
import React, { useState, useEffect } from 'react';
import SignupCover from 'assets/images/signup-cover.png';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'components/ui/button';
import {
  DISPLAY_MODE,
  DUMMY_DATA,
  EMAIL_PLACEHOLDER,
  JOURNEYS,
  PRIMARY_COLOR,
  REGEX,
  TOKEN,
} from 'common/constants';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useRouter } from 'hooks/router';
import { GoogleIcon } from 'assets/svg';
import { Loader2, ChevronRight } from 'lucide-react';
import { Input } from 'components/ui/input';
import darkLogo from 'assets/png/logo-dark.png';
import aiSignup from 'assets/images/ai-headhsot-signup.jpg';
import { useAppContext } from 'AppContext';
import { useUserJourney } from 'hooks/auth';
import { cn } from 'lib/utils';
import { Password } from 'components/Inputs';
// const { required, email } = formValidatorRules;
import {
  useGetBrandByUrl,
  useGetBrandDetails,
  useFeatureChecks,
} from 'hooks/brand';
import { LOGIN } from './graphql/Mutations';
import { signInWithGooglePopup } from './firebase';

const formSchema = z.object({
  email: z.string().min(1, { message: 'Email is required.' }).email({
    message: 'Please enter a valid email address.',
  }),
  password: z.string().min(1, { message: 'Password is required.' }),
});

const Login = () => {
  const { logoUrl, name } = useGetBrandDetails();
  const { isRegistrationAllowed, isGoogleLoginAllowed } = useFeatureChecks();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '', password: '' },
  });

  const { navigate } = useRouter();
  const { setIsLogin, initializeAuth } = useAppContext();
  const { journey } = useUserJourney();
  const [UserLogin, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  const [SocialLogin, { loading: socialLoading }] = useMutation(LOGIN, {
    onCompleted({ loginUser }) {
      const { accessToken, data, refreshToken } = loginUser;
      const userData = data;
      const tokenRefresh = refreshToken;
      localStorage.setItem(TOKEN, accessToken);
      initializeAuth(accessToken, userData, tokenRefresh, { isSignUp: true });
      setIsLogin(true);
    },
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    localStorage.setItem(TOKEN, accessToken);
    setIsLogin(true);
  }

  function successCallbackSocialAuth(accessToken) {
    SocialLogin({
      variables: {
        data: { token: accessToken },
      },
    });
  }

  const handleGoogleSignIn = async () => {
    if (loginLoading) return;
    try {
      const { user } = await signInWithGooglePopup();
      if (user?.accessToken) {
        const accessToken = user?.accessToken;
        if (successCallbackSocialAuth) {
          successCallbackSocialAuth(accessToken);
        }
      }
    } catch (error) {
      // do nothing
    }
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await UserLogin({
        variables: { data: { ...formValues } },
      });

      const { accessToken, data, refreshToken } =
        response?.data?.loginUser || {};
      if (response?.data) {
        const userData = data;
        const tokenRefresh = refreshToken;

        if (successCallback) {
          successCallback(accessToken, userData, tokenRefresh);
        }
      } else {
        form?.reset(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="flex h-full min-h-screen  max-h-screen ">
      <div
        className={cn(
          'flex flex-col justify-center w-full bg-primary-foreground p-[16px] overflow-auto',
          { 'bg-[#0A0B0C]': journey === JOURNEYS.AI_HEADSHOT },
        )}
      >
        <div className="w-full max-w-[600px] mx-[auto]">
          <div className="text-center">
            <img
              src={
                journey === JOURNEYS.AI_HEADSHOT
                  ? darkLogo
                  : logoUrl || '/logo.svg'
              }
              height={40}
              width="auto"
              alt="logo"
            />

            <h1
              level={1}
              className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mt-8"
            >
              Login to {name || 'SyncSignature'}
            </h1>
          </div>

          {/* <div
            className={cn(
              'text-darkblue text-medium-l font-primary  bg-primary-foreground mb-2 mt-8',
              {
                'bg-[#0A0B0C] text-white-0 text-[18px]':
                  journey === JOURNEYS.AI_HEADSHOT,
              },
            )}
          >
            Login
          </div> */}
          {isGoogleLoginAllowed && (
            <>
              <Button
                variant="outline"
                onClick={handleGoogleSignIn}
                className={cn(
                  'h-[40px] w-full font-primary text-button-s  bg-white-0  !mt-[16px] flex items-cente justify-center',
                  {
                    ' bg-[#0A0B0C] outline-none px-[16px] py-[8px] border-1 border-primary-400 text-white-0':
                      journey === JOURNEYS.AI_HEADSHOT,
                  },
                )}
                disabled={loginLoading}
              >
                <div className="flex justify-center items-center  ">
                  <GoogleIcon />
                  <span className="ml-2">Continue with Google</span>
                </div>
              </Button>

              <div className="flex items-center my-4 w-full">
                <div className="flex-1">
                  <Separator className="bg-secondary-400 h-px" />
                </div>
                <div className="text-secondary-400 mx-2 text-center text-xs font-semibold leading-[24px] font-primary whitespace-nowrap">
                  OR
                </div>
                <div className="flex-1">
                  <Separator className="bg-secondary-400 h-px" />
                </div>
              </div>
            </>
          )}

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onFinish)}
              form={form}
              initialValues={{ remember: true }}
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem
                    // rules={[
                    //   { required, message: 'Please enter email!' },
                    //   email,
                    // ]}
                    className="text-darkblue font-medium  text-[12px] mb-6"
                  >
                    <FormLabel
                      className={
                        (cn(' text-darkblue'),
                        {
                          ' text-white-0 ': journey === JOURNEYS.AI_HEADSHOT,
                        })
                      }
                    >
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                        placeholder={EMAIL_PLACEHOLDER}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem
                    name="password"
                    className=" text-darkblue font-medium  text-[12px] mb-2"
                  >
                    <FormLabel
                      className={
                        (cn(' text-darkblue'),
                        {
                          ' text-white-0 ': journey === JOURNEYS.AI_HEADSHOT,
                        })
                      }
                    >
                      Password
                    </FormLabel>
                    <FormControl>
                      <Password
                        type="password"
                        className="bg-primary-foreground rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary hover:bg-primary-foreground  hover:shadow-custom py-1"
                        placeholder="Minimum 8 characters"
                        maxLength={16}
                        disabled={socialLoading || loginLoading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={() => (
                  <FormItem className="space-y-0 m-0 text-darkblue font-medium  text-[12px] mb-6 text-right ">
                    <FormControl>
                      <Link to="/auth/forgot-password">
                        <u
                          className={cn(
                            'text-label-s font-primary font-medium bg-primary-foreground text-primary',
                            {
                              ' bg-[#0A0B0C] text-white-0':
                                journey === JOURNEYS.AI_HEADSHOT,
                            },
                          )}
                        >
                          Forgot password?
                        </u>
                      </Link>
                    </FormControl>
                  </FormItem>
                )}
              />

              <Button htmlType="submit" className="h-[40px] w-full mt-2 ">
                {(loginLoading || socialLoading) && (
                  <Loader2 className="animate-spin mr-2 h-5 w-5 " />
                )}
                <div className="flex items-center space-x-1">
                  <span>Login</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
              {isRegistrationAllowed && (
                <p className="font-style text-secondary-400 text-medium-base font-primary mt-4 mb-8 flex justify-center">
                  Don't have an account yet?
                  <div
                    className={cn('text-secondary-400 pl-1 cursor-pointer', {
                      ' text-primary ': journey === JOURNEYS.AI_HEADSHOT,
                    })}
                    onClick={() =>
                      navigate(
                        `/auth/signup${
                          journey === JOURNEYS.AI_HEADSHOT
                            ? `?r=${journey}`
                            : ''
                        }`,
                      )
                    }
                  >
                    <u>Sign up</u>
                  </div>
                </p>
              )}
            </form>
          </Form>

          {/* <Divider>
              <span className="text-secondary-400 text-center text-xs font-semibold leading-[24px] font-primary">
                Or, login in with email
              </span>
            </Divider> */}

          {/*
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required, message: 'Please enter email!' }, email]}
                className="remove-imp text-darkblue font-medium  text-[12px] mb-2"
              >
                <Input
                  type="email"
                  className="bg-primary-0 rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[23px] hover:border-primary-500 hover:bg-primary-0 hover:shadow-custom"
                  placeholder={EMAIL_PLACEHOLDER}
                  disabled={socialLoading || loginLoading}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required, message: 'Please enter password!' },
                  {
                    pattern: REGEX?.PASSWORD,
                    validator: (_, loginPassword) => {
                      if (loginPassword && loginPassword?.length < 8) {
                        return Promise?.reject(
                          new Error('Password must be at least 8 characters'),
                        );
                      }
                      return Promise?.resolve();
                    },
                  },
                ]}
                className=" text-darkblue font-medium  text-[12px] mb-2 remove-imp"
              >
                <Input
                  visibilityToggle
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeCloseIcon />
                  }
                  type="password"
                  className="bg-primary-0 rounded transition duration-300 focus-within hover:border-1  hover:border-solid h-[23px] hover:border-primary-500 hover:bg-primary-0  hover:shadow-custom "
                  placeholder="Minimum 8 characters"
                  maxLength={16}
                  disabled={socialLoading || loginLoading}
                />
              </Form.Item>
              <Form.Item className="text-right">
                <Link to="/auth/forgot-password">
                  <u className="text-label-s font-primary font-medium bg-primary-0 text-primary">
                    Forgot password?
                  </u>
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="h-[40px] w-full mt-2 "
                  loading={loginLoading || socialLoading}
                >
                  <div>
                    Login <ChevronRight />
                  </div>
                </Button>
              </Form.Item>
            </Form> */}
        </div>
      </div>
      {/* <div
          style={{
            backgroundImage: `url(${
              journey === JOURNEYS.AI_HEADSHOT ? aiSignup : SignupCover
            })`,
          }}
          className="lg:hidden flex w-1/2 min-h-full gap-2.5 shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center"
        /> */}
    </div>
  );
};

export default Login;
