import React from 'react';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { useRouter } from 'hooks/router';

const AiHeadshotButton = props => {
  const { navigate } = useRouter();
  const { className = '',onClick } = props; // Corrected destructuring

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
      navigate(`/app/ai-headshot`);
  };

  return (
    <div>
      <Button
        className={cn('h-[40px] rounded px-[16px] py-[12px]', className)}
        {...props}
        onClick={handleClick}
      />
    </div>
  );
};

export default AiHeadshotButton;
