import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useGetRoutes } from 'helpers/useGetRoutes';
import { userRoutes } from 'routes';
import { useGetWorkspaceTeammateByUserId } from 'hooks/workspace-teammate';
import { useAppContext } from 'AppContext';
import Header from './Header';
import UserSideBar from './UserSideBar';

const UserLayout = () => {
  const {
    state: { currentUser },
  } = useAppContext();

  const { getRoutes } = useGetRoutes();
  const [getWorkspaceTeammateByUserId] = useGetWorkspaceTeammateByUserId();

  const userId = currentUser?.id;
  const fetchWorkspaceTeammatesByUserId = async () => {
    await getWorkspaceTeammateByUserId({
      variables: {
        where: {
          userId,
        },
      },
    });
  };

  useEffect(() => {
    if (userId) {
      fetchWorkspaceTeammatesByUserId();
    }
  }, [userId]);



  const wrapperClass = 'grid grid-cols-[227px_1fr]';

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className={wrapperClass}>
        <UserSideBar />
        <div className="bg-primary-foreground">
          <Routes>
            {getRoutes(userRoutes)}
            <Route path="*" element={<Navigate to="/app/user/account" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
