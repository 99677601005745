import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from 'AppContext';
import { useUserPermission } from 'hooks/user';
import { DEFAULT_DESIGN_STYLE, FONT_FAMILY } from 'common/constants';
import Button from 'components/Button';
import { useRouter } from 'hooks/router';
import LoaderComponent from 'components/LoaderComponent';
import { useWorkspaceId } from 'hooks/workspace';
import { useGetSignatureList } from 'hooks/signature';
import AddActionCard from 'components/AddActionCard';
import SignatureActionModal from './SignatureActionModal';
import SignatureCard from './SignatureCard';

function Signatures() {
  const { handleSubscriptionModal } = useAppContext();
  const { workspaceId } = useWorkspaceId();
  const signatureActionModalRef = useRef();

  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);

  const [getSignatureListing, states] = useGetSignatureList();

  const { loading: signatureListingLoading } = states;
  const [listingData, setListingData] = useState([]);
  const { isFreeUser } = useUserPermission();
  const getSignatureListHandler = async (skip = 0) => {
    setLoading(true);
    const res = await getSignatureListing({
      variables: {
        pagination: {
          skip,
          limit: 'max',
        },
        where: {
          workspaceId,
        },
      },
    });

    if (res) {
      const signatures = res?.data?.signatures?.data ?? [];
      setListingData(signatures);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (workspaceId) {
      getSignatureListHandler();
    }
  }, [workspaceId]);

  const actions = {
    onClick: (id) => {
      navigate(`/app/${workspaceId}/signature/${id}/information`);
    },
    handleEdit: (record) => {
      signatureActionModalRef?.current?.onTypeChange('update');
      signatureActionModalRef?.current?.onDataChange(record);
      signatureActionModalRef?.current?.open();
    },
    handleDelete: (record) => {
      signatureActionModalRef?.current?.onTypeChange('delete');
      signatureActionModalRef?.current?.onDataChange(record);
      signatureActionModalRef?.current?.open();
    },
    handleDuplicate: (record) => {
      signatureActionModalRef?.current?.onTypeChange('duplicate');
      signatureActionModalRef?.current?.onDataChange(record);
      signatureActionModalRef?.current?.open();
    },
  };

  const formatListData = (data) => {
    data.forEach((e) => {
      if (!e?.design?.styles) {
        e.design.styles = DEFAULT_DESIGN_STYLE;
      }
      if (!e?.design?.font) {
        e.design.font = FONT_FAMILY;
      }
    });
    return data;
  };

  return (
    <>
      <SignatureActionModal
        listingData={listingData}
        setListingData={setListingData}
        ref={signatureActionModalRef}
      />

      <div className="relative">
        <div className="bg-white-0 w-full h-[calc(100vh_-_120px)] overflow-auto relative">
          {signatureListingLoading || loading ? (
            <LoaderComponent
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <div className="flex flex-wrap gap-2 p-4 ">
              <AddActionCard
                handleOnClick={() => {
                  if (isFreeUser) {
                    handleSubscriptionModal(true);
                    return;
                  }
                  signatureActionModalRef?.current?.onTypeChange('create');
                  signatureActionModalRef?.current?.open();
                }}
              />
              {listingData?.length && formatListData(listingData).map((data) => (
                <SignatureCard
                  key={data?.id}
                  count={listingData?.length || 1}
                  signature={data}
                  actions={actions}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Signatures;
