import { cn } from 'lib/utils';
import { Plus } from 'lucide-react';
import React from 'react';

const AddActionCard = ({ handleOnClick, className }) => (
  <div
    onClick={handleOnClick}
    className={cn(
      'relative flex justify-start bg-primary-foreground w-[350px] h-[220px] rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2 bg-white border-[1px] border-solid border-secondary-400 hover:border-solid hover:border-[1px]  hover:border-primary hover:shadow-signature','bg-primary-foreground',
      className,
    )}
  >
    <div className="flex items-center justify-center w-full">
      <Plus className="h-[50px] w-[50px]" />
    </div>
  </div>
);

export default AddActionCard;
