/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTwentyEight({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  return (
    <table
      // width={tableWidth}
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td paddingRight="2px">
                            <table cellPadding="0" cellSpacing="0">
                              <tbody>
                                <tr align="center">
                                  {getFieldData(fields, 'headshotUrl') &&
                                    HeadshotImage({
                                      data: getFieldData(fields, 'headshotUrl'),
                                      design,
                                      style: {
                                        paddingBottom: '14px',
                                        paddingTop: '9px',
                                      },
                                    })}
                                </tr>
                                <tr align="center">
                                  {getFieldData(fields, 'name') &&
                                    Name({
                                      signature,
                                      data: getFieldData(fields, 'name'),
                                      style: {
                                        fontFamily: 'inherit',

                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                      },
                                    })}
                                </tr>
                                <tr>
                                  {getFieldData(fields, 'position') &&
                                    Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                      },
                                    })}
                                </tr>
                                <tr style={{ textAlign: 'center' }}>
                                  {getFieldData(fields, 'department') &&
                                    Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingLeft: '4px',
                                        textAlign: 'center',
                                      },
                                    })}
                                </tr>
                                <tr>
                                  <td>
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      border="0"
                                      style={{
                                        borderCollapse: 'collapse !important',
                                        fontSize: 'inherit',
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          {getFieldData(
                                            fields,
                                            'companyName',
                                          ) &&
                                            CompanyName({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'companyName',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                              },
                                            })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                            <table
                              cellPadding="0px"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      border="0"
                                      style={{
                                        borderCollapse: 'collapse',
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          {getFieldData(fields, 'phone') &&
                                            Phone({
                                              signature,
                                              showAwsIcons,
                                              design,
                                              data: getFieldData(
                                                fields,
                                                'phone',
                                              ),
                                              style: {
                                                paddingRight: '8px',
                                              },
                                            })}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: { paddingBottom: '2px' },
                                    })}
                                  </tr>
                                )}
                                <td style={{ paddingTop: '4px' }}>
                                  <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    border="0"
                                  >
                                    <tbody>
                                      {!socialFields?.every(
                                        (obj) => obj?.value === '',
                                      ) && (
                                        <tr>
                                          {SocialLinks({
                                            signature,
                                            showAwsIcons,
                                            data: socialFields,
                                            design,
                                            style: { paddingBottom: '8px' },
                                          })}
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table ref={extraContentRef}>
                      <tr>
                        {getFieldData(fields, 'ctaUrl') &&
                          getFieldData(fields, 'ctaTitle') &&
                          CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                            style: {
                              paddingBottom: '8px',
                            },
                            tdProps: { align: 'center' },
                          })}
                      </tr>
                      <tr>
                        {getFieldData(fields, 'ctaBannerImg') &&
                          getFieldData(fields, 'ctaBannerUrl') &&
                          CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left' },
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table width={tableWidth}>
                      <tr>
                        <td>
                          {getFieldData(fields, 'companyLogo') &&
                            CompanyLogo({
                              data: getFieldData(fields, 'companyLogo'),
                              design,
                              style: {
                                paddingBottom: '8px',
                              },
                              toProps: { colSpan: 2, align: 'center' },
                            })}
                        </td>
                        <td>
                          {getFieldData(fields, 'footer') &&
                            Footer({
                              data: getFieldData(fields, 'footer'),
                              design,
                            })}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleTwentyEight;
