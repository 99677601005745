// yahoo.js
// eslint-disable-next-line import/prefer-default-export
export const yahooContent = `<div className="flex items-center">
          <h1 className="ml-2 text-xl font-normal">Install Signature – Yahoo (Web Mail)</h1>
        </div>
      <div class="bg-gray-100 border border-gray-100 p-4 rounded-md mb-4">
      <strong>WARNING:</strong> Unfortunately, Yahoo Mail makes on-the-fly adjustments to the images in your signature, which is out of our control. This can cause rendering issues in certain email clients if your images are being scaled down from their natural size. We've noticed that the following images are being scaled down:
      <ul class="list-disc pl-5">
        <li>Profile Image (291px → 50px)</li>
        <li>Logo (600px → 100px)</li>
        <li>Call to Action Image (1172px → 521px)</li>
      </ul>
      While the signature may look fine in the Yahoo Mail preview, it is likely that recipients using Microsoft email clients such as Outlook 2019 will see any images at their full size (example below). Please test your signature by sending it to a recipient who is using a Windows mail client. If the issue is occurring, you can resolve it by uploading your images at the exact size that they should appear in the signature (and not scale them down). You can use a free online service such as Pixlr to resize your images appropriately.
      <br><br>
      <strong>IMPORTANT:</strong> Once resized, your images will render correctly in Windows email clients; however, they will appear slightly blurry on tablets, phones, and other high-resolution screens. If you are not willing to compromise on this, we recommend using a different email client.
    </div>
      <div class="mt-6 steps">
        <ol class="list-none p-0 m-0">
          <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">1</div>
            <span>Once logged into your Yahoo account, click the Settings Cog in the top right corner.</span>
          </li>
          <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">2</div>
            <span>With the dropdown open click More Settings.</span>
          </li>
          <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">3</div>
            <span>Click Writing Email from the settings options.</span>
          </li>
          <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">4</div>
            <span>Click the + button at the bottom of the middle column.</span>
          </li>
          <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">5</div>
            <span>Copy and paste your new email signature into the signature editor.</span>
          </li>
           <div class="mt-6 bg-gray-100 border border-gray-100 p-4 rounded-md ">
            <strong>WARNING:</strong>  If your signature does not appear in the preview on the right then move the text cursor in signature editor to the top of the pane and hit the enter key.
          </div>
           <li class="flex items-center mb-4">
            <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">5</div>
            <span>Your signature should then save automatically. Close the settings page, compose a new email and enjoy your new signature!</span>
          </li>
        </ol>
      </div>

      `;
