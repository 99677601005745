/* eslint-disable no-undef */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { Progress } from 'components/ui/progress';
import { AI_MODELS } from 'common/constants';
import { useAppContext } from 'AppContext';
import {
  FIRST_TIME_MODEL_V1,
  FIRST_TIME_MODEL_V2,
  V1_MODELS,
  V2_MODELS,
} from 'modules/AiHeadshot/constants';
import { ArrowRight, ChevronLeft } from 'lucide-react';
import { usePosthogEvents } from 'hooks/posthog';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { cn } from 'lib/utils';
import FormVersionOne from './Forms/FormVersionOne';
import FormVersionTwo from './Forms/FormVersionTwo';

export const getPoseImage = (id) => {
  const url = `https://static.dev.sendsig.com/pose-images/${id}.jpeg`;
  return url;
};
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const scrollToDiv = (divId, settings = {}) => {
  const element = document.getElementById(divId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start', ...settings });
  }
};

export const V1_INPUT = {
  width: 768,
  height: 768,
  prompt:
    'Headshot portrait of a male img, wearing suit with tie, background is office, 50mm portrait photography, natural lighting photography, shallow focus',
  n_prompt:
    'nsfw, lowres, bad anatomy, bad hands, text, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry',
  num_samples: 1,
  face_resemblance: 0.5,
  steps: 20,
};

const V2_INPUT = {};

export const INPUTS = {
  [AI_MODELS.v1]: V1_INPUT,
  [AI_MODELS.v2]: V2_INPUT,
};

const HeadshotGenerator = ({ setStep, image, gender, handleProccessModel }) => {
  const {
    state: { aiHeadshot },
  } = useAppContext();
  const { capture } = usePosthogEvents();
  const INITIAL_MODELS = {
    [AI_MODELS.v1]: V1_MODELS,
    [AI_MODELS.v2]: V2_MODELS,
  };
  const [selectedModels, setSelectedModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = React.useState(13);
  const [model, setModel] = useState(AI_MODELS.v2);
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount(prevCount => (prevCount + 1) % 4);
    }, 500); // Change the interval time as needed
    return () => clearInterval(interval);
  }, []);

  const dots = '.'.repeat(dotCount);

  const onTabChange = (value) => {
    localStorage.setItem('model', value);
    setModel(value);
  };

  const prevModel = aiHeadshot?.image?.model;

  useEffect(() => {
    const modelLocal = prevModel || localStorage.getItem('model');

    if (modelLocal) {
      setModel(modelLocal);
    } else {
      localStorage.setItem('model', AI_MODELS.v2);
    }
  }, [prevModel]);

  useEffect(() => {
    const filtedModels = INITIAL_MODELS[model].filter(
      (model) => model.gender === gender,
    );
    setSelectedModels([filtedModels[0]]);
  }, [model, gender]);


  const poseImageCount = selectedModels?.length || 0;

  const generateAiHeadshotWithPoseImages = async (selectedModels) => {
    setLoading(true);
    scrollToDiv('sasasasasasaa');
    setProgress(20);

    await handleProccessModel(selectedModels);
    await delay(2000);

    setProgress(90);

    setProgress(100);
    setLoading(false);
    setStep(4);
    // Presumed cleanup function after all operations
  };

  if (loading) {
    return (
      <div className="w-full overflow-hidden h-[750px] bg-[transparent] rounded-lg shadow-lg  flex items-center justify-center">
          <div className="flex flex-col gap-4 px-5  w-full items-center justify-center py-2">
            <p className=" text-white-0 text-[32px] font-[700] sm:text-[24px]">
              Generating the best headshot for you! This may take a few minutes <span className='w-[30px] inline-block' >{dots}</span>
            </p>
          </div>
        </div>
    );
  }

  const VERSION_TYPE = [
    { id: 1, label: 'Creative', value: AI_MODELS.v2 },
    // { id: 2, label: 'Headshot', value: AI_MODELS.v1 },
  ];

  const isBroke = aiHeadshot?.isPaid
    ? aiHeadshot?.credits === 0
    : aiHeadshot?.credits < 1;

  const isDisabled = !image || !poseImageCount || isBroke;

  return (
    <div className="w-full rounded-lg shadow-lg ">
      <div className="overflow-auto h-[calc(100dvh_-_172px)]  relative max-w-[1440px]  mx-auto px-3 md:px-1 ">
        <div className="p-4 flex flex-col justify-center items-center">
          <div className="relative w-full flex flex-col items-center mt-[40px] justify-center">
            <div
              className="absolute sm:relative sm:w-full sm:mb-4 left-0 text-white-0 leading-[16px] font-[500] text-[16px] flex items-center cursor-pointer justify-start"
              onClick={() => setStep(2)}
            >
              <ChevronLeft size={20} className="mr-2" />
              Change photo
            </div>
            <div className="text-white-0 font-bold text-[30px] leading-[36px] font-primary text-center sm:text-[20px] sm:leading-[36px]">
              Step 3: Select style preference
            </div>
          </div>

          <div className="text-[#AAAAAA] font-[500] text-[20px] leading-[36px] mt-[14px] font-primary text-center sm:text-[14px] sm:mt-0 sm:leading-[24px]">
            Wide range of styles to get the best AI Headshot
          </div>

          <div>
            {/* <div>
              <Tabs
                value={model}
                onValueChange={onTabChange}
                className="flex mx-[auto] w-full max-w-[310px] mt-[32px] bg-primary-foreground shadow-md overflow-hidden rounded-[50px]"
              >
                <TabsList className="grid w-full grid-cols-2 bg-primary-foreground p-0 h-[auto]">
                  {VERSION_TYPE.map(({ id, label, value }) => (
                    <TabsTrigger
                      key={id}
                      className={cn(
                        'cursor-pointer p-2 flex-col  rounded-[50px] bg-[transparent] data-[state=active]:bg-primary data-[state=active]:text-white-0 border-0',
                      )}
                      value={value}
                    >
                      <span className="m-0 text-medium-m">{label}</span>{' '}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            </div> */}

            <div>
              {model === AI_MODELS.v1 ? (
                <FormVersionOne
                  gender={gender}
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                  model={model}
                />
              ) : (
                <FormVersionTwo
                  gender={gender}
                  selectedModels={selectedModels}
                  setSelectedModels={setSelectedModels}
                  model={model}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center  border-solid border-0 border-t bg-white-0 border-primary-300 fixed p-4 inset-x-0 bottom-0"> */}
      <div className="w-full flex justify-center items-center  h-[100px]  border-solid border-0 border-t  border-primary-300">
        <div className="w-full flex mx-2 gap-3 justify-center">
          <Button
            onClick={async () => {
              const finalModel = [...selectedModels];

              generateAiHeadshotWithPoseImages(finalModel);
              capture('Clicks generate headshot');
            }}
            disabled={isDisabled}
            className="w-full max-w-[320px] h-[54px]"
            {...(isDisabled ? { variant: 'ghost' } : {})}
          >
            Generate Headshot <ArrowRight size={20} className="ml-[6px]" />
          </Button>

          {/* <p className="px-1">
            NOTE: Your image output will be depend upon your image input.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default HeadshotGenerator;
