import { useAppContext } from 'AppContext';
import { Dialog, DialogContent } from 'components/ui/dialog';
import React from 'react';
import SubsciptionPlans from './SubsciptionPlans';
import SubsciptionPlansLTD from './SubsciptionPlansLTD';

function SubscriptionModal() {
  const {
    state: { showSubscriptionModal },
    handleSubscriptionModal,
  } = useAppContext();
  return (
    <div>
      <Dialog
        open={showSubscriptionModal}
        onOpenChange={() => {
          handleSubscriptionModal(false);
        }}
      >
        <DialogContent hideCloseButton className="sm:max-w-[425px] max-w-sm">
          <div className="pt-2.5">
            {process.env.REACT_APP_ENV !== 'production' ? (
              <SubsciptionPlans />
            ) : (
              <SubsciptionPlansLTD bordered={false} plansToShow={['2']} />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubscriptionModal;
