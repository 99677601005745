// index.js
import { gmailContent } from './gmail';
import { outlookContent } from './outlook';
import { appleMailContent } from './appleMail';
import { yahooContent } from './yahoo';

// eslint-disable-next-line import/prefer-default-export
export const signatureContents = [
  {
    id: 'gmail',
    content: gmailContent,
  },
  {
    id: 'outlook',
    content: outlookContent,
  },
  {
    id: 'applemail',
    content: appleMailContent,
  },
  {
    id: 'yahoo',
    content: yahooContent,
  },
];
