/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTen({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);

  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS?.map((s) => s.name)].includes(field?.name),
  );

  const section2 =
    !getFieldData(fields, 'name') && !getFieldData(fields, 'position');

  const section3 =
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website') &&
    !getFieldData(fields, 'companyName') &&
    !getFieldData(fields, 'department');

  const section5 = socialFields?.every((obj) => obj?.value === '');

  return (
    <table
      // width={tableWidth}
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td aria-label="main-content">
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            style={{
              borderCollapse: 'collapse',
              fontSize: 'inherit',
              marginBottom: '8px',
            }}
          >
            <tbody>
              <tr>
                <td>
                  <table
                    cellPadding="0px"
                    cellSpacing="0"
                    border="0"
                    ref={mainContentRef}
                  >
                    <tr>
                      <td>
                        {(getFieldData(fields, 'headshotUrl') ||
                          getFieldData(fields, 'companyLogo')) && (
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                              paddingRight: '14px',
                            }}
                          >
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                {HeadshotImage({
                                  data: getFieldData(fields, 'headshotUrl'),
                                  design,
                                  style: {
                                    paddingBottom: '14px',
                                  },
                                })}
                              </tr>
                            )}

                            {getFieldData(fields, 'companyLogo') && (
                              <tr align="center">
                                {CompanyLogo({
                                  data: getFieldData(fields, 'companyLogo'),
                                  design,
                                })}
                              </tr>
                            )}
                          </table>
                        )}
                      </td>

                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          style={{
                            borderCollapse: 'collapse',
                          }}
                        >
                          {!section2 && (
                            <tr>
                              <td>
                                <table cellPadding="0px">
                                  <tr>
                                    {getFieldData(fields, 'name') &&
                                      Name({
                                        signature,
                                        data: getFieldData(fields, 'name'),
                                        style: {
                                          fontWeight: 700,
                                          color: design?.primaryBrandColor,
                                          fontFamily: 'inherit',
                                          paddingRight: '4px',
                                          verticalAlign: 'center',
                                        },
                                      })}

                                    {getFieldData(fields, 'position') &&
                                      Position({
                                        signature,
                                        data: getFieldData(fields, 'position'),
                                        style: {
                                          fontFamily: 'inherit',
                                          verticalAlign: 'center',
                                        },
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}

                          {!(section3 || section2) && (
                            <tr>
                              <Divider
                                signature={signature}
                                style={{ marginTop: 8, marginBottom: 8 }}
                              />
                            </tr>
                          )}

                          {(getFieldData(fields, 'department') ||
                            getFieldData(fields, 'companyName')) && (
                            <tr>
                              <td>
                                <table
                                  cellPadding="0px"
                                  cellSpacing="0"
                                  border="0"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                    paddingBottom: '4px',
                                  }}
                                >
                                  <tr>
                                    {getFieldData(fields, 'department') &&
                                      Department({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'department',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                          paddingRight: '8px',
                                        },
                                      })}

                                    {getFieldData(fields, 'companyName') &&
                                      CompanyName({
                                        signature,
                                        data: getFieldData(
                                          fields,
                                          'companyName',
                                        ),
                                        style: {
                                          fontFamily: 'inherit',
                                        },
                                      })}
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          )}

                          {(getFieldData(fields, 'phone') ||
                            getFieldData(fields, 'email') ||
                            getFieldData(fields, 'website') ||
                            getFieldData(fields, 'companyAddress')) && (
                            <tr>
                              <td align="center">
                                <table
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  role="presentation"
                                  width="max-content"
                                  style={{
                                    borderCollapse: 'collapse !important',
                                    fontSize: 'inherit',
                                  }}
                                >
                                  {(getFieldData(fields, 'phone') ||
                                    getFieldData(fields, 'email')) && (
                                    <tr>
                                      {getFieldData(fields, 'phone') &&
                                        Phone({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'phone'),
                                          style: {
                                            paddingRight: '8px',
                                          },
                                        })}
                                      {getFieldData(fields, 'email') &&
                                        Email({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'email'),
                                        })}
                                    </tr>
                                  )}

                                  {(getFieldData(fields, 'website') ||
                                    getFieldData(fields, 'companyAddress')) && (
                                    <tr>
                                      {getFieldData(fields, 'website') &&
                                        Website({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(fields, 'website'),
                                          style: {
                                            paddingRight: '8px',
                                          },
                                        })}
                                      {getFieldData(fields, 'companyAddress') &&
                                        CompanyAddress({
                                          signature,
                                          showAwsIcons,
                                          design,
                                          data: getFieldData(
                                            fields,
                                            'companyAddress',
                                          ),
                                        })}
                                    </tr>
                                  )}
                                </table>
                              </td>
                            </tr>
                          )}

                          {!(section5 || section3) && (
                            <tr>
                              <Divider
                                signature={signature}
                                style={{ marginTop: 8, marginBottom: 8 }}
                              />
                            </tr>
                          )}

                          {!!socialFields?.length && (
                            <tr>
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
            <tr>
              <td>
                <table width={tableWidth}>
                  {getFieldData(fields, 'ctaUrl') &&
                    getFieldData(fields, 'ctaTitle') && (
                      <tr>
                        {CallToAction({
                          signature,
                          showAwsIcons,
                          design,
                          data: {
                            ctaUrl: getFieldData(fields, 'ctaUrl'),
                            ctaTitle: getFieldData(fields, 'ctaTitle'),
                          },
                          style: {
                            paddingBottom: '8px',
                          },
                        })}
                      </tr>
                    )}

                  {getFieldData(fields, 'ctaBannerImg') &&
                    getFieldData(fields, 'ctaBannerUrl') && (
                      <tr>
                        {CallToActionBanner({
                          data: {
                            ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                            ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                          },
                          design,
                          style: { textAlign: 'left' },
                        })}
                      </tr>
                    )}

                  {getFieldData(fields, 'footer') && (
                    <tr>
                      {Footer({
                        data: getFieldData(fields, 'footer'),
                        design,
                      })}
                    </tr>
                  )}
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <Branding />
      </tr>
    </table>
  );
}

export default TemplateStyleTen;
