import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CropperDialog from 'components/CropperDialog';
import { cn } from 'lib/utils';
import { UploadSimpleIcon } from 'assets/svg';
import { Button } from 'components/ui/button';

const PLACEHOLDER = (
  <div className="flex items-center  justify-center flex-col">
    <UploadSimpleIcon />
    <div className="text-medium-base font-primary  ">
      Drag and drop an image here to upload
    </div>
    <div className="text-medium-base font-primary ">
      or{' '}
      <span className="text-semantic-info ">
        <u>select a file</u>
      </span>
    </div>
  </div>
);

const DEFAULT_DRAG_ACTIVE_CLASS = (
  <p className="text-[black] ">Drop the files here ...</p>
);

const Dragger = ({
  image,
  files = [],
  setFiles,
  setStep,
  setImage,
  DraggerClass,
  wrapperClassName = '',
  dragActiveClass = DEFAULT_DRAG_ACTIVE_CLASS,
  placeholder = PLACEHOLDER,
  handleFinish,
}) => {
  const [open, setOpen] = useState(false);

  const defaultPlaceholder = (
    <div className=" p-4 max-w-[100%]">
      {image ? (
        <div className="">
          <div className="relative rounded-lg overflow-hidden">
            <img
              src={image}

              style={{ height: '200px', width: 'auto', objectFit: 'cover' }}
              alt="CropImage"
              className="rounded-[8px] max-w-[100%]"
            />

            <div className="absolute inset-0 gap-4 flex flex-col justify-center items-center transition-opacity duration-300 backdrop-blur-sm bg-[black]/55 opacity-0 hover:opacity-100">
              <Button
                variant="outline"
                className="w-[60%] bg-[transparent] text-white-0 border-1 border-white-0 hover:text-white-0 hover:border-1 hover:border-white-0"
                onClick={() => {
                  setStep(2);
                }}
              >
                Change Photo
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>{placeholder}</>
      )}
    </div>
  );

  const onDrop = (acceptedFiles) => {
    // Only take the first file from the acceptedFiles array
    const newFile = acceptedFiles[0];

    // If there's a new file, set it as the only file in the files array
    if (newFile) {
      setFiles([
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        }),
      ]);
      setOpen(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.gif', '.webp'],
    },
    onDrop,
    multiple: false,
  });

  const onClose = () => {
    setFiles([]);
    setOpen(false);
  };

  const handleFinalImage = (img) => {
    setImage(img);
    onClose();
  };

  return (
    <div
      className={cn('rounded-md w-full border-primary-100', wrapperClassName)}
    >
      <div
        {...getRootProps()}
        className={cn(
          ` rounded-md py-4 text-center h-[200px] flex items-center justify-center text-[16px] border-[1px] border-dashed border-secondary-400 hover:border-dashed hover:border-[1px]  hover:border-primary-500 bg-transparent  ${
            isDragActive ? 'border-blue-500' : ''
          }`,
          DraggerClass,
        )}
      >
        <input {...getInputProps()} />
        {isDragActive ? dragActiveClass : defaultPlaceholder}
      </div>
      <CropperDialog
        file={files}
        open={open}
        onClose={onClose}
        finalImage={handleFinalImage}
        cropperClass="bg-[#0A0B0C] border-1 border-solid border-[#727272] rounded-[8px]"
        handleFinish={handleFinish}
      />
    </div>
  );
};

export default Dragger;
