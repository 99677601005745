import { TEXT_IDS } from 'common/constants';
import { get } from 'lodash';
import React from 'react';

function Department(
  { style = {},
    tdProps = {},
    signature = {},
    data,

  }) {
  // eslint-disable-next-line no-debugger, no-console
  const { font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  // const defaultTextColor = color;
  const textStyle = {
    fontSize,
    lineHeight,
    color,
  };
  return (
    <td
    id={TEXT_IDS.DEPARTMENT}
    style={{
      fontStyle: 'normal',
      fontWeight: 400,
      ...textStyle,
      ...style,
    }}
    {...tdProps}
  >
    {data?.value}
  </td>

  );

}

export default Department;
