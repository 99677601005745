/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'components/ui/button';
import {
  useAssignGroupToTeammates,
  useCreateTeammate,
  useDeleteTeammate,
  useSendInviteTeammate,
} from 'hooks/teammate';
import { useWorkspaceId } from 'hooks/workspace';
import { ArrowUpDown, Pencil, Trash, Search, PlusIcon } from 'lucide-react';
import { Input } from 'components/ui/input';
import Table from 'components/Table';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { toast } from 'hooks/use-toast';
import { useRouter } from 'hooks/router';
import { get } from 'lodash';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { ROLES } from 'common/constants';
import { useAppContext } from 'AppContext';
import useGoogleWorkspaceUsers from 'hooks/google';
import { useFeatureChecks } from 'hooks/brand';
import { GoogleIcon } from 'assets/svg';
import DataTableRowActions from './components/DataTableRowActions';
import AddTeammateDialog from './components/AddTeammateDialog';
import AssignGroupDialog from './components/AssignGroupDialog';
import BulkDeleteDialog from './components/BulkDeleteDialog';
import ActionDialog from './components/ActionDialog';
import SelectUsersDialog from './components/SelectUsersDialog';

const Teammates = () => {
  const {
    state: { roles, groups, workspaceTeammates },
  } = useWorkspaceContext();
  const {
    state: { currentUser },
  } = useAppContext();

  const [selectedTeammates, setSelectedTeammates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const tableRef = useRef(null);
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const { isGoogleLoginAllowed } = useFeatureChecks();

  const [createTeammate] = useCreateTeammate();
  const [assignGroupToTeammates] = useAssignGroupToTeammates();
  const { workspaceId } = useWorkspaceId();
  const [deleteTeammate] = useDeleteTeammate();
  const [sendInviteTeammate] = useSendInviteTeammate();
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();

  const actionDialogRef = useRef();
  const createTeammateRef = useRef();
  const groupAssignDialogRef = useRef(null);
  const selectUsersDialogRef = useRef();

  const [loading, setLoading] = useState(false);
  const [bulkDeleteDialog, setBulkDeleteDialog] = useState(false);

  const { handleFetchGoogleWorkspaceUsers, users } = useGoogleWorkspaceUsers();

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  useEffect(() => {
    if (workspaceId) {
      fetchWorkspaceTeammates();
    }
  }, [workspaceId]);

  const onSubmit = async (values) => {
    const res = await createTeammate({
      variables: {
        data: values,
        where: { workspaceId },
      },
    });
    const data = get(res, 'data.createTeammate.data', null);

    if (data) {
      // setTeammates([...teammates, data]);
      fetchWorkspaceTeammates();
      createTeammateRef?.current?.close();
    }
    return data;
  };

  const handleAssignGroup = async (values) => {
    const { groupId } = values;
    await assignGroupToTeammates({
      variables: {
        data: {
          groupId,
          workspaceId,
          ids: selectedTeammates.map((t) => t.id),
        },
      },
    });
    setSelectedTeammates([]);
    const { table } = tableRef.current;
    table.toggleAllPageRowsSelected(false);
    groupAssignDialogRef?.current?.close();
  };

  const handleBulkDelete = async () => {
    await deleteTeammate({
      variables: {
        data: {
          ids: selectedTeammates.map((t) => t.id),
          workspaceId,
        },
      },
    });
    setBulkDeleteDialog(false);
    setSelectedTeammates([]);
    const { table } = tableRef.current;
    table.toggleAllPageRowsSelected(false);
  };

  const getTeammateRole = (roleId) => {
    if (workspaceTeammates.length || roles.length) {
      const role = roles?.find((r) => Number(r.id) === roleId);
      return role?.name || '';
    }
    return '';
  };

  const getTeammateGroup = (groupId) => {
    const group = groups.find((group) => group.id === groupId);
    return group || null;
  };
  const getTeammateRoleFull = (roleId) => {
    if (workspaceTeammates.length || roles.length) {
      const role = roles?.find((r) => Number(r.id) === roleId);
      return role || null;
    }
    return null;
  };

  const columns = [
    {
      accessorKey: 'workspaceTeammateDetails.name', // Update this to match the correct nested key if needed
      header: ({ column }) => (
        <div
          className="flex items-center cursor-pointer font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      ),
      cell: ({ row }) => {
        const getInitials = (name) => {
          const parts = name?.split(' ');
          return parts?.map((part) => part[0])?.join('');
        };

        const name = row.original.workspaceTeammateDetails?.name; // Accessing nested key directly
        const initials = getInitials(name);

        return (
          <div className="flex items-center">
            <div className="ml-3">
              <div className="font-medium">{name}</div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'workspaceTeammateDetails.email', // Directly access nested email
      header: ({ column }) => (
        <div
          className="flex items-center cursor-pointer font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      ),
      cell: ({ row }) => (
        <div className="lowercase">
          {row.original.workspaceTeammateDetails?.email}
        </div> // Accessing nested key directly
      ),
    },
    {
      accessorKey: 'group',
      header: ({ column }) => (
        <div
          className="flex items-center cursor-pointer font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Group
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      ),
      cell: ({ row }) => (
        <div className="capitalize">
          {getTeammateGroup(row?.original?.groupId)?.name ??
            'No group assigned'}
        </div>
      ),
    },
    {
      accessorKey: 'role',
      header: ({ column }) => (
        <div
          className="flex items-center cursor-pointer font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Role
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      ),
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2">
            <div className="lowercase">
              {getTeammateRole(row?.original?.roleId)}
            </div>
            {getTeammateRole(row?.original?.roleId) !== ROLES.OWNER ? (
              <Button
                size="sm"
                className="group flex items-center px-2 py-0 bg-gray-100 text-gray-600 rounded-md h-6"
                onClick={() => {
                  actionDialogRef.current.onTypeChange('update-role');
                  const roleId = getTeammateRoleFull(row?.original?.roleId)?.id;
                  actionDialogRef.current.onDataChnage({
                    ...row.original,
                    roleId,
                  });
                  actionDialogRef?.current?.open();
                }}
              >
                <Pencil className="h-3 w-3 text-primary group-hover:text-[white]" />
              </Button>
            ) : (
              <div />
            )}
          </div>
        );
      },
    },
    {
      id: 'installation',
      accessorKey: 'signature.installation',
      header: ({ column }) => (
        <div
          className="flex items-center cursor-pointer font-bold"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          installation
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      ),
      cell: ({ row }) => {
        const installationMap = {
          INSTALLED: 'installed',
          PENDING: 'pending',
          NOT_INSTALLED: 'not installed',
        };
        const installation =
          installationMap[row.getValue('installation')] ?? 'not installed';
        return <div className="capitalize">{installation}</div>;
      },
    },
    {
      id: 'actions',
      cell: ({ row: r }) => (
        <div className="flex justify-center items-center gap-1">
          <div>
            <Button
              size="sm"
              className="group flex items-center px-2 py-0 bg-gray-100 text-gray-600 rounded-md h-6"
              onClick={() => {
                navigate(`${pathname}/${r.original?.id}`);
              }}
            >
              <Pencil className="h-3 w-3 text-primary group-hover:text-[white]" />
            </Button>
          </div>
          <div>
            {getTeammateRole(r?.original?.roleId) !== ROLES.OWNER ||
            getTeammateRole(
              workspaceTeammates?.find((wt) => wt.userId === currentUser.id)
                ?.roleId,
            ) === ROLES.OWNER ? (
              <Button
                size="sm"
                className="flex items-center px-2 py-0 bg-gray-100 text-gray-600 rounded-md h-6"
                onClick={() => {
                  actionDialogRef.current.onTypeChange('delete');
                  actionDialogRef.current.onDataChnage(r.original);
                  actionDialogRef?.current?.open();
                }}
              >
                <Trash className="h-3 w-3 text-[#CF1322]" />
              </Button>
            ) : (
              <div />
            )}
          </div>
          <div>
            <DataTableRowActions
              onRowClick={async ({ type }) => {
                const groupId = getTeammateGroup(r?.original?.groupId)?.id;
                if (type === 'send-invite') {
                  if (!groupId) {
                    toast({
                      closeicn: 'destructive',
                      description: 'Please assign a group',
                    });
                    return;
                  }
                  await sendInviteTeammate({
                    variables: {
                      data: {
                        emails: [r?.original?.workspaceTeammateDetails?.email],
                        workspaceId,
                      },
                    },
                  });
                  return;
                }
                if (type === 'send-signature') {
                  if (!groupId) {
                    toast({
                      closeicn: 'destructive',
                      description: 'Please assign a group',
                    });
                    return;
                  }
                }
                actionDialogRef.current.onTypeChange(type);
                actionDialogRef.current.onDataChnage(r.original);
                actionDialogRef?.current?.open();
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const customHeader = (
    <div className="flex items-center justify-between py-2 gap-4">
      <div className="relative">
        <Input
          onChange={(event) => setSearchQuery(event.target.value)}
          className="bg-white-0 rounded transition duration-300 focus-within hover:border-1 hover:border-solid h-[30px] hover:border-primary hover:bg-primary-foreground hover:shadow-custom py-1 pl-8 w-96"
          placeholder="Search teammates"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
          <Search className="text-gray-400 h-4 w-4" />
        </div>
      </div>

      <div className="flex items-center justify-between gap-4">
        {isGoogleLoginAllowed && (
          <Button
            onClick={async () => {
              handleFetchGoogleWorkspaceUsers(selectUsersDialogRef);
            }}
            className="h-[40px] w-full font-primary text-button-s  bg-white-0 flex items-cente justify-center"
            variant="outline"
          >
            <div className="flex justify-center items-center  ">
              <GoogleIcon />
              <span className="ml-2">Sync with Google</span>
            </div>
          </Button>
        )}
        <Button
          className="h-[40px] w-[186px] text-[16px]"
          onClick={() => createTeammateRef?.current?.open()}
        >
          <PlusIcon className="h-3 w-3 mr-1" />
          Create Teammate
        </Button>
      </div>
    </div>
  );

  const filteredTeammates =
    workspaceTeammates?.filter(
      (teammate) =>
        teammate?.workspaceTeammateDetails?.name
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        teammate?.workspaceTeammateDetails?.email
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        (teammate?.workspaceTeammateDetails?.group?.name &&
          teammate?.workspaceTeammateDetails?.group?.name
            ?.toLowerCase()
            ?.includes(searchQuery?.toLowerCase())),
    ) ?? [];

  return (
    <>
      <AddTeammateDialog ref={createTeammateRef} onSubmit={onSubmit} />
      <SelectUsersDialog ref={selectUsersDialogRef} users={users} />
      <AssignGroupDialog
        ref={groupAssignDialogRef}
        onSubmit={handleAssignGroup}
      />
      <BulkDeleteDialog
        open={bulkDeleteDialog}
        onClose={() => setBulkDeleteDialog(false)}
        onSubmit={handleBulkDelete}
      />
      <ActionDialog ref={actionDialogRef} />
      <div className="bg-primary-foreground w-full h-[calc(100vh_-_71px)] overflow-auto">
        <div className="flex justify-between px-[24px] py-[18px]">
          <h1 className="m-0">Teammates</h1>
        </div>
        <div className="px-[24px]">
          <Table
            ref={tableRef}
            data={filteredTeammates}
            columns={columns}
            loading={loading}
            customHeader={customHeader}
            tableClassName="max-h-[calc(100vh_-_230px)] overflow-auto"
          />
        </div>
      </div>
    </>
  );
};

export default Teammates;
