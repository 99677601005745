/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import LoaderComponent from 'components/LoaderComponent';
import { getLiveUrl } from 'common/utils';
import { useAppContext } from 'AppContext';
import { AI_MODELS } from 'common/constants';
import ShareModal from './ShareModal';

const ImageCard = ({
  id,
  url,
  status,
  handleDownload,
  fetchImage,
  input,
  model = AI_MODELS.v1,
  isSolo = false,
  removeoverlay = false,
}) => {
  const {
    state: { aiHeadshotLoading },
    setAiHeadshotLoading,
  } = useAppContext();
  const [open, setOpen] = useState(false);

  const fetchPendingImage = async (id) => {
    if (aiHeadshotLoading) return;
    setAiHeadshotLoading(true);
    await fetchImage(id);
    setAiHeadshotLoading(false);
  };

  const refetchImage = (id) => {
    const intervalId = setInterval(() => {
      fetchPendingImage(id);
    }, 6000);
    return intervalId;
  };
  useEffect(() => {
    let intervalId = null;
    if (status === 'pending' && !aiHeadshotLoading) {
      intervalId = refetchImage(id);
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [status]);

  return (
    <>
      <ShareModal
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        url={url}
        input={input}
      />
      <div key={id} className="rounded-lg shadow-lg flex flex-col items-center">
        {url && status === 'completed' ? (
          <div className="relative">
            <div className="relative rounded-lg overflow-hidden">
              <img
                alt="Headshot"
                className="rounded-lg w-full max-w-[150px] "
                src={getLiveUrl(url)}
                style={{
                  aspectRatio: model === AI_MODELS.v1 ? '1/1' : '2/3',
                  objectFit: 'contain',
                }}
              />
              <div
                className={`absolute inset-0 gap-4 flex flex-col justify-center items-center transition-opacity duration-300 backdrop-blur-sm bg-[black]/55 opacity-0 ${
                  removeoverlay ? 'hidden' : 'opacity-0 hover:opacity-100'
                }`}
              >
                <Button
                  variant="outline"
                  className="w-[60%] bg-[transparent] text-white-0 border-1 border-white-0 hover:text-white-0 hover:border-1 hover:border-white-0"
                  onClick={() => handleDownload(url)}
                >
                  Download
                </Button>
                <Button
                  variant="outline"
                  className="w-[60%] bg-[transparent] text-white-0 border-1 border-white-0 hover:text-white-0 hover:border-1 hover:border-white-0"
                  onClick={() => setOpen(true)}
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{  aspectRatio: model === AI_MODELS.v1 ? '1/1' : '2/3', objectFit: 'contain' }}
              className="h-full h-min-[300px] w-[150px] mb-4 gap-2 flex flex-col justify-center items-center"
            >
              <div className=" h-full w-full text-white">
                {status === 'error' ? (
                  'Error generating image...'
                ) : isSolo ? (
                  <div
                    key={id}
                    className="h-full w-full flex items-center justify-center bg-white-0 rounded-lg shadow-lg flex-col"
                  >
                    <LoaderComponent text="Processing..." />
                  </div>
                ) : (
                  <div
                    key={id}
                    className=" h-full w-full flex items-center justify-center bg-white-0 rounded-lg shadow-lg flex-col"
                  >
                    <LoaderComponent text="Processing..." />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ImageCard;
