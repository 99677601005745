/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppContext } from 'AppContext';
import SignatureLayout from 'layouts/SignatureLayout';
import OnboardingLayout from 'layouts/OnboardingLayout';
import AiHeadshotLayout from 'layouts/AiHeadshotLayout';
import WorkspaceLayout from 'layouts/WorkspaceLayout';
import UserLayout from 'layouts/UserLayout';
import { useRouter } from 'hooks/router';
import Logout from 'modules/Auth/Logout';
import Redirect from 'modules/Redirect';
import { useGetWorkspaceTeammateByBrandId } from 'hooks/workspace-teammate';
import { useBrandContext } from 'context/BrandContext';
import LoaderComponent from 'components/LoaderComponent';
import { useGetUser } from 'hooks/user';

const AppLayout = () => {
  const { getToken } = useAppContext();
  const {
    state: { brand },
  } = useBrandContext();
  const {
    location: { pathname },
  } = useRouter();
  const [loading, setLoading] = useState(false);
  const [getUser] = useGetUser();
  const [getWorkspaceTeammateByBrandId] = useGetWorkspaceTeammateByBrandId();

  const fetchBrandwiseWorkspaceTeammates = async () => {
    setLoading(true);
    await getWorkspaceTeammateByBrandId({
      variables: {
        where: {
          brandId: brand?.id,
        },
      },
    });
    setLoading(false);
  };

  const getUserProfile = async () => {
    setLoading(true);
    if (loading) return;
    await getUser();
    setLoading(false);
  }

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    if (brand?.id) {
      fetchBrandwiseWorkspaceTeammates();
    }
  }, [brand?.id]);

  const idToken = getToken();
  if (!idToken) {
    const redierctUrl = pathname.includes('ai-headshot')
      ? '/auth/login?r=ai-headshot'
      : '/auth/';
    return <Navigate to={redierctUrl} replace />;
  }

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Routes>
      <Route
        path="/:workspaceId/signature/:id/*"
        element={<SignatureLayout />}
      />
      <Route path="/:workspaceId/onboarding/*" element={<OnboardingLayout />} />
      <Route path="/ai-headshot/*" element={<AiHeadshotLayout />} />
      <Route path="/workspace/:workspaceId/*" element={<WorkspaceLayout />} />
      <Route path="/user/*" element={<UserLayout />} />
      <Route path="/" element={<Redirect />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/app/" />} />
    </Routes>
  );
};

export default AppLayout;
