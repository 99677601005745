import { get } from 'lodash';
import React from 'react';
import { getFieldData, getLiveUrl } from 'common/utils';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleFour({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr>
        <td>
          <table
            ref={mainContentRef}
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
            }}
          >
            <tr>
              <td style={{ paddingRight: '14px' }}>
                <table>
                  <tr>
                    <td style={{ fontSize: '0px', lineHeight: '0px' }}>
                      <table
                        cellPadding="0"
                        cellSpacing="0"
                        border="0"
                        role="presentation"
                        style={{
                          borderCollapse: 'collapse !important',
                          fontSize: 'inherit',
                        }}
                      >
                        {getFieldData(fields, 'headshotUrl') && (
                          <tr>
                            <HeadshotImage
                              data={getFieldData(fields, 'headshotUrl')}
                              design={design}
                              style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                paddingTop: '10px',
                              }}
                            />
                          </tr>
                        )}
                      </table>
                    </td>
                    <td style={{ fontStyle: 'normal' }}>
                      <table>
                        {getFieldData(fields, 'name') && (
                          <tr>
                            <Name
                              signature={signature}
                              data={getFieldData(fields, 'name')}
                              style={{
                                color: design?.primaryBrandColor,
                                fontWeight: 700,
                              }}
                            />
                          </tr>
                        )}
                        {getFieldData(fields, 'position') && (
                          <tr>
                            <Position
                              signature={signature}
                              data={getFieldData(fields, 'position')}
                            />
                          </tr>
                        )}
                        {getFieldData(fields, 'department') && (
                          <tr>
                            <Department
                              signature={signature}
                              data={getFieldData(fields, 'department')}
                            />
                          </tr>
                        )}
                        {getFieldData(fields, 'companyName') && (
                          <tr>
                            <CompanyName
                              signature={signature}
                              data={getFieldData(fields, 'companyName')}
                            />
                          </tr>
                        )}
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
              {(getFieldData(fields, 'phone') ||
                getFieldData(fields, 'email') ||
                getFieldData(fields, 'website') ||
                getFieldData(fields, 'companyAddress')) && (
                <td
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 0,
                    borderLeft: !section1
                      ? `1px solid ${design?.primaryBrandColor}`
                      : 'none',
                    paddingLeft: '14px',
                  }}
                >
                  <table>
                    {getFieldData(fields, 'phone') && (
                      <tr>
                        <Phone
                          signature={signature}
                          showAwsIcons={showAwsIcons}
                          design={design}
                          data={getFieldData(fields, 'phone')}
                        />
                      </tr>
                    )}
                    {getFieldData(fields, 'email') && (
                      <tr>
                        <Email
                          signature={signature}
                          showAwsIcons={showAwsIcons}
                          design={design}
                          data={getFieldData(fields, 'email')}
                        />
                      </tr>
                    )}
                    {getFieldData(fields, 'website') && (
                      <tr>
                        <Website
                          signature={signature}
                          showAwsIcons={showAwsIcons}
                          design={design}
                          data={getFieldData(fields, 'website')}
                        />
                      </tr>
                    )}
                    {getFieldData(fields, 'companyAddress') && (
                      <tr>
                        <CompanyAddress
                          signature={signature}
                          showAwsIcons={showAwsIcons}
                          design={design}
                          data={getFieldData(fields, 'companyAddress')}
                        />
                      </tr>
                    )}
                  </table>
                </td>
              )}
            </tr>
          </table>

          {!allUrlsAreNull && (
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              width={tableWidth}
              role="presentation"
              style={{
                borderCollapse: 'collapse !important',
                borderTop: !allUrlsAreNull
                  ? `1px solid ${design?.primaryBrandColor}`
                  : '',
                marginTop: '10px',
              }}
            >
              <tr>
                {getFieldData(fields, 'companyLogo') && (
                  <CompanyLogo
                    data={getFieldData(fields, 'companyLogo')}
                    design={design}
                    style={{
                      textAlign: 'left',
                      paddingTop: '8px',
                    }}
                  />
                )}
                {!!socialFields?.length && (
                  <SocialLinks
                    signature={signature}
                    showAwsIcons={showAwsIcons}
                    data={socialFields}
                    design={design}
                    tdProps={{
                      align: 'right',
                      valign: 'center',
                    }}
                    style={{
                      paddingLeft: '8px',
                      paddingTop: '8px',
                    }}
                  />
                )}
              </tr>
            </table>
          )}
        </td>
      </tr>
      {getFieldData(fields, 'ctaUrl') && getFieldData(fields, 'ctaTitle') && (
        <tr>
          <table cellPadding="0" cellSpacing="0" border="0" width={tableWidth}>
            <tr>
              <Divider
                signature={signature}
                style={{ marginTop: 8, marginBottom: 12 }}
              />
            </tr>
          </table>
        </tr>
      )}
      <tr>
        <td>
          <table width={tableWidth}>
            {getFieldData(fields, 'ctaUrl') &&
              getFieldData(fields, 'ctaTitle') && (
                <tr>
                  {CallToAction({
                    signature,
                    showAwsIcons,
                    design,
                    data: {
                      ctaUrl: getFieldData(fields, 'ctaUrl'),
                      ctaTitle: getFieldData(fields, 'ctaTitle'),
                    },
                    style: { marginBottom: '10px' },
                    tableprops: { align: 'center' },
                  })}
                </tr>
              )}
          </table>
        </td>
      </tr>
      <tr>
        <td align="center">
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            ref={extraContentRef}
          >
            {getFieldData(fields, 'ctaBannerImg') &&
              getFieldData(fields, 'ctaBannerUrl') && (
                <tr>
                  <CallToActionBanner
                    data={{
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    }}
                    design={design}
                    style={{ textAlign: 'left', paddingTop: '6px' }}
                  />
                </tr>
              )}
          </table>
        </td>
      </tr>
      <tr>
        <td>
          <table width={tableWidth} cellPadding="0" cellSpacing="0" border="0">
            <tr>
              {getFieldData(fields, 'footer') && (
                <Footer data={getFieldData(fields, 'footer')} design={design} />
              )}
            </tr>
            <tr>
              <Branding />
            </tr>
          </table>
        </td>
      </tr>
    </table>
  );
}

export default TemplateStyleFour;
