import React from 'react';
import { SubscriptionIcon, UserCircleIcon, Whiltelabel } from 'assets/svg';
import { ReceiptText } from 'lucide-react';
import { useRouter } from 'hooks/router';
import { useUserPermission } from 'hooks/user';
import { useTheme } from 'hooks/theme';
import { useFeatureChecks, useIsUserBrandOwner } from 'hooks/brand';
import { useAppContext } from 'AppContext';
import { useBrandContext } from 'context/BrandContext';
import { useWorkspaceId } from 'hooks/workspace';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const hiddenItemsByRole = {
  FREE: ['Account', 'Whitelabel'],
  SOLO: ['Account', 'Whitelabel'],
  TEAM: ['Account', 'Whitelabel'],
};

const UserSideBar = () => {
  const {
    location: { pathname },
    navigate,
  } = useRouter();
  const {
    state: { brand },
  } = useBrandContext();
  const {
    state: { currentUser },
  } = useAppContext();
  const { color, foregroundColor } = useTheme();
  const { userRole } = useUserPermission();
  const { isUserBrandOwner } = useIsUserBrandOwner();
  const { workspaceId } = useWorkspaceId();
  const brandOwnerTabs = isUserBrandOwner(currentUser?.id, workspaceId);

  const items = [
    ...(brandOwnerTabs
      ? [
          {
            key: 'Account',
            path: '/app/user/account',
            icon: UserCircleIcon,
            label: 'Account',
          },
        ]
      : []),
    {
      key: 'Profile',
      path: '/app/user/profile',
      icon: UserCircleIcon,
      label: 'Profile',
    },
    ...(brandOwnerTabs
      ? [
          {
            key: 'plan',
            path: '/app/user/subscriptions',
            icon: SubscriptionIcon,
            label: 'Plans',
          },
          ...(process.env.REACT_APP_ENV !== 'production'
            ? [
                {
                  key: 'billing',
                  path: '/app/user/billing',
                  icon: ReceiptText,
                  label: 'Billing',
                },
              ]
            : []),
        ]
      : []),
    ...(brandOwnerTabs &&
    ![
      'https://app.syncsignature.com',
      'https://app.dev.syncsignature.com',
    ].includes(brand?.url)
      ? [
          {
            key: 'Whitelabel',
            path: '/app/user/whitelabel',
            icon: Whiltelabel,
            label: 'WhiteLabel',
          },
        ]
      : []),
  ];

  const filteredItems = items.filter(
    (item) =>
      !(
        hiddenItemsByRole[userRole] &&
        hiddenItemsByRole[userRole].includes(item.key)
      ),
  );

  const isActiveTab = (path) => pathname.includes(path);

  return (
    <div
      className="h-[calc(100vh_-_71px)] flex flex-col"
      collapsible
      theme="light"
      trigger={null}
      width={277}
    >
      {filteredItems.map(({ icon: Icon, path, label }) => {
        const onClick = () => {
          navigate(path);
        };

        const isActive = isActiveTab(path);

        return (
          <div
            className="h-[70px]"
            style={{
              ...itemStyle,
              ...(isActive
                ? {
                    backgroundColor: foregroundColor,
                    borderLeft: `4px solid ${color}`,
                  }
                : {}),
            }}
            key={path}
            onClick={onClick}
          >
            <div
              className="flex items-center w-full "
              style={{ marginLeft: isActive ? '30px' : '35px' }}
            >
              <Icon
                fill={isActive ? color : '#7a7289'}
                className="h-[30px] w-[30px] mr-3"
              />
              <div
                className="text-[18px]"
                style={{ color: isActive ? color : '#7a7289' }}
              >
                {label}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserSideBar;
