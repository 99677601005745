/* eslint-disable no-nested-ternary */
import { socialImages } from 'assets/social-images';
import { TEXT_IDS } from 'common/constants';
import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection, removeHttpPrefix } from 'common/utils';
import { get } from 'lodash';
import React from 'react';

const WebsiteTypeWrapper = ({
  type,
  styles,
  iconDimensions,
  children,
  signature,
  showAwsIcons,
}) => {
  const { fontSize } = styles;
  const child = {
    letter: 'W.',
    word: 'Website.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
      fontSize,
    },
    word: {
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
      fontSize,
    },
    rounded: { borderRadius: '50%', ...iconDimensions },
    square: { borderRadius: '5px', ...iconDimensions },
    none: { display: 'none' },
  };
  const content = (
    <p
      style={{
        ...styles,
        ...(updatedStyle[type] || {}),
        margin: '1px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="website"
        fileName="website"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};
function Website({
  design,
  signature = {},
  style = {},
  tdProps = {},
  data = {},
  showAwsIcons = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = get(icon, 'type', 'rounded');
  const size = get(icon, 'style.size', 16);

  const textStyle = {
    fontSize,
    lineHeight,
    color,
  };
  return (
    <td
      style={{
        ...style,
      }}
      {...tdProps}
    >
      <table cellPadding="0" cellSpacing="0" border="0">
        <tbody>
          <tr>
            <td
              valign="middle"
              style={{ paddingRight: type === 'none' ? '0px' : '6px' }}
            >
              <WebsiteTypeWrapper
                signature={signature}
                showAwsIcons={showAwsIcons}
                type={type}
                iconDimensions={{ height: size, width: size }}
                styles={{
                  height:
                    type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
                  width: '16px',
                  fontSize,
                  backgroundColor: design?.primaryBrandColor,
                }}
              >
                <img
                  src={socialImages['links-circle']}
                  alt="website"
                  style={{ height: '100%', width: '100%' }}
                />
              </WebsiteTypeWrapper>
            </td>

            <td style={{ lineHeight }}>
              <a
                id={TEXT_IDS.WEBSITE}
                href={handleUrlRedirection(data?.shortLink || data?.value)}
                style={{
                  ...textStyle,
                  textAlign: 'left',
                  display: 'inherit',
                  textDecoration: 'none',
                }}
              >
                {removeHttpPrefix(data.value)}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  );
}

export default Website;
