// outlook.js
// eslint-disable-next-line import/prefer-default-export
export const outlookContent = `
  <div className="flex items-center">
    <h1 className="ml-2 text-xl font-normal">Add to Outlook.com</h1>
  </div>
  <div class="mt-6 steps">
    <ol class="list-none p-0 m-0">
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">1</div>
        <span>Once logged into your Outlook.com account, click the Settings Cog in the top right corner.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">2</div>
        <span>With the sidebar settings menu open, click View All Outlook Settings in the bottom right corner.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">3</div>
        <span>Select the Mail tab and then the Compose and Reply tab in the settings modal.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">4</div>
        <span>Copy and paste your new email signature into the signature editor.</span>
      </li>
      <div>
        <span>Please ensure that the "Compose messages in HTML format" setting is correct and that you have checked the appropriate checkboxes around automatically using this signature.</span>
      </div>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">5</div>
        <span>Click Save and exit the settings modal.</span>
      </li>
      <li class="flex items-center mb-4">
        <div class="flex items-center justify-center w-8 h-8 font-bold rounded-full mr-4">6</div>
        <span>Compose a new email and enjoy your new signature!.</span>
      </li>
    </ol>
  </div>
`;
