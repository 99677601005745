/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { JOURNEYS, TOKEN, onboardingStatus } from 'common/constants';
import { GET_SIGNATURE_LISTING } from 'modules/Signature/graphql/Queries';
import { GET_USER } from 'modules/Auth/graphql/Queries';
import { useSearchParams } from 'react-router-dom';
import { get } from 'lodash';
import { useRouter } from './router';
import { useFetchWorkspaces, useWorkspaceId } from './workspace';

/* eslint-disable import/prefer-default-export */
export const useRedirect = () => {
  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);
  const { workspaceId } = useWorkspaceId();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const [getSignatureListing] = useLazyQuery(GET_SIGNATURE_LISTING, {
    fetchPolicy: 'network-only',
  });

  const [getUserProfile] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
  });

  const redirectUser = async (configs = {}) => {
    setLoading(true);
    try {
      const userProfileResponse = await getUserProfile();

      const user = get(userProfileResponse, 'data.me', null);
      const res = await fetchWorkspaces();
      const [workspace] = get(res, 'data.getWorkspaces.data', []);

      const workspaceId = workspace?.id;
      if (!workspaceId) {
        return;
      }

      const storedJourney = localStorage.getItem('journey');
      if (storedJourney === JOURNEYS.AI_HEADSHOT) {
        navigate('/app/ai-headshot');
        return;
      }

      if (user?.onboardingStatus === onboardingStatus.PENDING) {
        navigate(`/app/${workspaceId}/onboarding`);
        return;
      }

      const signatureResponse = await getSignatureListing({
        variables: { where: { workspaceId } },
      });
      const signatureId = signatureResponse?.data?.signatures?.data?.[0]?.id;

      if (user?.onboardingStatus === onboardingStatus.INFORMATION_ADDED) {
        navigate(
          `/app/${workspaceId}/onboarding/professional-headshot/${signatureId}`,
        );
        return;
      }

      if (user?.onboardingStatus === onboardingStatus.COMPLETED) {
        navigate(`/app/workspace/${workspaceId}/assets`);
      }
    } catch (error) {
      navigate('/app/logout');
    } finally {
      setLoading(false);
    }
  };

  const redierctWhenSignaturePending = async (currentUser) => {
    setLoading(true);
    try {
      const user = currentUser;

      if (!workspaceId) {
        return;
      }

      if (user?.onboardingStatus === onboardingStatus.PENDING) {
        navigate(`/app/${workspaceId}/onboarding`);
        return;
      }

      const signatureResponse = await getSignatureListing({
        variables: { where: { workspaceId } },
      });
      const signatureId = signatureResponse?.data?.signatures?.data?.[0]?.id;

      // if (!signatureId) {
      //   navigate(`/app/${workspaceId}/onboarding`);
      //   return;
      // }

      if (user?.onboardingStatus === onboardingStatus.INFORMATION_ADDED) {
        navigate(
          `/app/${workspaceId}/onboarding/professional-headshot/${signatureId}`,
        );
      }
    } catch (error) {
      navigate('/app/logout');
    } finally {
      setLoading(false);
    }
  };

  return { redirectUser, loading, redierctWhenSignaturePending };
};
export const useUserJourney = () => {
  const [searchParams] = useSearchParams();

  const j = searchParams.get('r');
  const [journey, setJourney] = useState(j ?? '');
  useEffect(() => {
    const storedJourney = localStorage.getItem('journey');
    if (!storedJourney || storedJourney === 'null' || !j) {
      setJourney(j);
      localStorage.setItem('journey', j);
    } else {
      setJourney(storedJourney ?? j);
    }
  }, [j]);

  return { journey: journey ?? j };
};
