import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleOne({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tr style={{}}>
        <td>
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
            }}
          >
            <tr>
              <td>
                <table cellPadding="0px" ref={mainContentRef}>
                  <tr align="center">
                    {getFieldData(fields, 'headshotUrl') &&
                      HeadshotImage({
                        data: getFieldData(fields, 'headshotUrl'),
                        design,
                        style: {
                          padding: '0 0 10px 0',
                        },
                      })}
                  </tr>
                  <tr>
                    <td align="center">
                      <table
                        cellPadding="0px"
                        cellSpacing="0"
                        border="0"
                        role="presentation"
                      >
                        <tr align="center">
                          {getFieldData(fields, 'name') &&
                            Name({
                              signature,
                              data: getFieldData(fields, 'name'),
                              style: {
                                color: design?.primaryBrandColor,
                                fontWeight: '700',
                              },
                            })}
                        </tr>
                        <tr align="center">
                          {getFieldData(fields, 'position') &&
                            Position({
                              signature,
                              data: getFieldData(fields, 'position'),
                            })}
                        </tr>
                        <tr align="center">
                          {getFieldData(fields, 'department') &&
                            Department({
                              signature,
                              data: getFieldData(fields, 'department'),
                            })}
                        </tr>
                        <tr align="center">
                          {getFieldData(fields, 'companyName') &&
                            CompanyName({
                              signature,
                              data: getFieldData(fields, 'companyName'),
                            })}
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    {getFieldData(fields, 'phone') && (
                      <Divider signature={signature} style={{ marginTop: 2 }} />
                    )}
                  </tr>
                  {getFieldData(fields, 'phone') && (
                    <tr align="center">
                      {Phone({
                        signature,
                        showAwsIcons,
                        design,
                        data: getFieldData(fields, 'phone'),
                      })}
                    </tr>
                  )}
                  {getFieldData(fields, 'email') && (
                    <tr align="center">
                      {Email({
                        signature,
                        showAwsIcons,
                        design,
                        data: getFieldData(fields, 'email'),
                      })}
                    </tr>
                  )}
                  {getFieldData(fields, 'companyAddress') && (
                    <tr align="center">
                      {CompanyAddress({
                        signature,
                        showAwsIcons,
                        design,
                        data: getFieldData(fields, 'companyAddress'),
                      })}
                    </tr>
                  )}
                  {getFieldData(fields, 'website') && (
                    <tr align="center">
                      {Website({
                        signature,
                        showAwsIcons,
                        design,
                        data: getFieldData(fields, 'website'),
                      })}
                    </tr>
                  )}
                  {!!socialFields?.length && (
                    <tr align="center">
                      {SocialLinks({
                        signature,
                        showAwsIcons,
                        data: socialFields,
                        design,
                        style: { paddingBottom: '8px' },
                      })}
                    </tr>
                  )}
                </table>
              </td>
            </tr>
            <tr align="center">
              {getFieldData(fields, 'companyLogo') &&
                CompanyLogo({
                  data: getFieldData(fields, 'companyLogo'),
                  design,
                  style: { paddingBottom: '8px' },
                })}
            </tr>
            <tr align="center">
              <table ref={extraContentRef}>
                {getFieldData(fields, 'ctaUrl') &&
                  getFieldData(fields, 'ctaTitle') && (
                    <tr align="center">
                      {CallToAction({
                        signature,
                        showAwsIcons,
                        design,
                        data: {
                          ctaUrl: getFieldData(fields, 'ctaUrl'),
                          ctaTitle: getFieldData(fields, 'ctaTitle'),
                        },
                        style: {
                          paddingBottom: '8px',
                        },
                      })}
                    </tr>
                  )}
                <tr>
                  {getFieldData(fields, 'ctaBannerImg') &&
                    getFieldData(fields, 'ctaBannerUrl') &&
                    CallToActionBanner({
                      data: {
                        ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                        ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                      },
                      design,
                      style: { textAlign: 'left' },
                    })}
                </tr>
              </table>
            </tr>
            <tr align="center">
              <table width={tableWidth}>
                <tr align="center">
                  {getFieldData(fields, 'footer') &&
                    Footer({
                      data: getFieldData(fields, 'footer'),
                      design,
                    })}
                </tr>
              </table>
            </tr>
          </table>
        </td>
      </tr>
      <Branding />
    </table>
  );
}

export default TemplateStyleOne;
