/* eslint-disable import/prefer-default-export */

import { useQuery } from '@apollo/client';
import { cloneAndRemoveTypename } from 'common/utils';
import { GET_TEMPLATES } from 'components/TemplatesModel/graphql/Queries';
import { TEMPLATE_MODELS } from 'components/TemplatesModel/utils/constants';

export const useTemplateData = () => {
  const { data = {}, loading } = useQuery(GET_TEMPLATES, {
    variables: {
      pagination: {
        skip: 0, // Start from the beginning
        limit: 'max', // Default to fetching 20 items
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });
  return { data: cloneAndRemoveTypename(data), loading };
};

export const useGetTemplates = () => {
  const { data: { templates: { data } = {} } = {},loading } = useTemplateData();

  const TEMPLATE_MODELS_CLONE = [...TEMPLATE_MODELS];

  data?.forEach((template) => {
    const index = TEMPLATE_MODELS_CLONE.findIndex(
      (model) => model.template.templateId === template.templateId,
    );
    if (index !== -1) {
      TEMPLATE_MODELS_CLONE[index].template = template;
      TEMPLATE_MODELS_CLONE[index].templateId = template.id;
    }
  });
  return { data: TEMPLATE_MODELS_CLONE,loading };
};
