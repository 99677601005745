import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TEAMMATES = gql`
  query GetTeammates($workspaceId: ID!, $where: TeammateWhereInput) {
    getTeammates(workspaceId: $workspaceId, where: $where) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        department
        role
        position
        phone
        isActive
        groupId
        status
        workspaces
        signatureStatus
        informationStatus
      }
      count
    }
  }
`;

export const GET_PROFILE_IMAGE_SIGNED_URL = gql`
  query GetProfileImageUploadSignedUrl(
    $data: GetProfileImageUploadSignedUrlInput!
  ) {
    getProfileImageUploadSignedUrl(data: $data) {
      signedUrl
      key
    }
  }
`;

export const GET_WORKSPACE_TEAMMATE_BY_BRAND_ID = gql`
  query GetWorkspaceTeammateByBrandId($where: WorkspaceTeammateWhereInput) {
    getWorkspaceTeammateByBrandId(where: $where) {
      count
      data {
        id
        workspaceTeammateDetailsId
        workspaceId
        signatureId
        userId
        roleId
        groupId
        brandId
        createdAt
        updatedAt
        deletedAt
        workspaceTeammateDetails
        signature
      }
    }
  }
`;

export const GET_TEAMMATES_BY_GROUP_ID = gql`
  query GetTeammatesByGroupId($where: TeammateWhereInput!) {
    getTeammatesByGroupId(where: $where) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        department
        role
        position
        phone
        isActive
        groupId
        status
        workspaces
        signatureStatus
        informationStatus
      }
      count
    }
  }
`;
export const GET_TEAMMATE_BY_ID = gql`
  query getTeammateById($id: ID!) {
    getTeammateById(id: $id) {
      data {
        id
        email
        firstName
        lastName
        name
        profileImage
        department
        role
        position
        phone
        isActive
        groupId
        status
        workspaces
        signatureStatus
        informationStatus
      }
    }
  }
`;

export const GET_WORKSPACE_TEAMMATE_BY_ID = gql`
  query GetWorkspaceTeammateById($where: WorkspaceTeammateWhereInput) {
    getWorkspaceTeammateById(where: $where) {
      data {
        id
        workspaceTeammateDetailsId
        workspaceId
        signatureId
        userId
        roleId
        groupId
        brandId
        createdAt
        updatedAt
        deletedAt
        workspaceTeammateDetails
        signature
      }
    }
  }
`;

export const GET_WORKSPACE_TEAMNMATE_BY_WORKSPACE_ID = gql`
  query GetWorkspaceTeammateByWorkspaceId($where: WorkspaceTeammateWhereInput) {
    getWorkspaceTeammateByWorkspaceId(where: $where) {
      count
      data {
        id
        workspaceTeammateDetailsId
        workspaceId
        signatureId
        userId
        roleId
        groupId
        brandId
        createdAt
        updatedAt
        deletedAt
        workspaceTeammateDetails
        signature
      }
    }
  }
`;

export const GET_WORKSPACE_TEAMNMATE_BY_USER_ID = gql`
  query getWorkspaceTeammateByUserId($where: WorkspaceTeammateWhereInput) {
    getWorkspaceTeammateByUserId(where: $where) {
      count
      data {
        id
        workspaceTeammateDetailsId
        workspaceId
        signatureId
        userId
        roleId
        groupId
        brandId
        createdAt
        updatedAt
        deletedAt
        workspaceTeammateDetails
        signature
      }
    }
  }
`;

export const UPDATE_WORKSPACE_TEAMMATE_ROLE = gql`
  mutation UpdateWorkspaceTeammateRole(
    $where: UpdateWorkspaceTeammateRoleWhereInput!
    $data: UpdateWorkspaceTeammateRoleInput!
  ) {
    updateWorkspaceTeammateRole(where: $where, data: $data) {
      id
      workspaceTeammateDetailsId
      workspaceId
      signatureId
      userId
      roleId
      groupId
      brandId
      createdAt
      updatedAt
      deletedAt
      workspaceTeammateDetails
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    getAllRoles {
      data {
        id
        name
      }
    }
  }
`;
