/* eslint-disable no-nested-ternary */
import React from 'react';
import WithImageConversion from 'common/helpers/htmlToImage';
import { get, omit } from 'lodash';
import { TEXT_IDS } from 'common/constants';
import { socialImages } from 'assets/social-images';

const PhoneTypeWrapper = ({
  type,
  styles,
  iconDimension,
  children,
  signature,
  showAwsIcons,
}) => {
  const { fontSize, lineHeight } = styles;
  const child = {
    letter: 'P.',
    word: 'Phone.',
    rounded: children,
    square: children,
    none: '',
  };

  const updatedStyle = {
    letter: {
      fontSize,
      lineHeight,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
    },
    word: {
      fontSize,
      lineHeight,
      backgroundColor: 'transparent',
      color: styles?.backgroundColor,
      fontWeight: 700,
      width: '100%',
      maxWidth: '100%',
    },
    rounded: { borderRadius: '50%', ...iconDimension },
    square: { borderRadius: '5px', ...iconDimension },
    none: { display: 'none' },
  };

  const content = (
    <p
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        margin: '1px',
      }}
    >
      {child[type] || ''}
    </p>
  );

  // Only wrap WithImageConversion for 'rounded' and 'square' types
  if (['rounded', 'square'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="phone"
        fileName="phone"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function Phone({
  design,
  style = {},
  tdProps = {},
  signature = {},
  data = {},
  showAwsIcons = false,
}) {
  const { icon, font } = get(signature, 'design.styles', {});
  const { fontSize, lineHeight, color } = get(font, 'style', {});
  const type = get(icon, 'type', 'rounded');
  const size = get(icon, 'style.size', 16);

  const textStyle = {
    fontSize,
    lineHeight,
    color,
  };

  return (
    <td
      style={{
        ...style,
      }}
      {...tdProps}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        border="0"
        style={{
          fontStyle: 'normal',
          fontWeight: 400,
          letterSpacing: '-0.088px',
        }}
      >
        <tbody>
          <tr>
            <td
              valign="middle"
              style={{
                paddingRight: type === 'none' ? '0px' : '6px',
              }}
              {...tdProps}
            >
              <PhoneTypeWrapper
                signature={signature}
                showAwsIcons={showAwsIcons}
                type={type}
                iconDimension={{ width: size, height: size }}
                styles={{
                  height:
                    type === 'letter' || type === 'word' ? 'auto' : '16px', // Conditional height
                  width: '16px',
                  backgroundColor: design?.primaryBrandColor,
                  fontSize,
                }}
              >
                <img
                  src={socialImages?.['phone-circle']}
                  alt="phone"
                  style={{ height: '100%', width: '100%' }}
                />
              </PhoneTypeWrapper>
            </td>
            <td
              id={TEXT_IDS.PHONE}
              style={{
                ...textStyle,
                textAlign: 'left',
              }}
            >
              {data?.value}
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  );
}

export default Phone;
