/* eslint-disable import/prefer-default-export */
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_WORKSPACES } from 'components/WorkspaceSwitcher/graphql/Queries';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { get } from 'lodash';
import {
  CREATE_WORKSPACE_MUTATION,
  GET_WORKSPACE_OWNER,
  UPDATE_WORKSPACE_MUTATION,
} from 'components/WorkspaceSwitcher/graphql/Mutations';
import { cloneAndRemoveTypename } from 'common/utils';
import { useRouter } from './router';

export const useFetchWorkspaces = () => {
  const { setWorkspaces } = useWorkspaceContext();
  const [fetchWorkspaces, states] = useLazyQuery(GET_WORKSPACES, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setWorkspaces(res?.getWorkspaces?.data ?? []);
    },
    onError() {},
  });

  return [fetchWorkspaces, states];
};

export const useWorkspaceId = () => {
  const {
    params: { workspaceId },
  } = useRouter();
  return { workspaceId };
};

export const useGroupId = () => {
  const {
    params: { groupId },
  } = useRouter();
  return { groupId };
};

export const useGetCurrentWorkspace = () => {
  const { workspaceId } = useWorkspaceId();
  const {
    state: { workspaces },
  } = useWorkspaceContext();

  return {
    workspace: cloneAndRemoveTypename(
      workspaces?.find((w) => w?.id === workspaceId),
    ),
  };
};

export const useWorkspaceParamsId = () => {
  const {
    location: { search },
  } = useRouter();
  const queryParams = new URLSearchParams(search);
  const workspaceId = queryParams.get('workspaceId');

  return { workspaceId };
};

export const useCreateWorkspace = () => {
  const {
    state: { workspaces },
    setWorkspaces,
  } = useWorkspaceContext();

  const [createWorkspace, states] = useMutation(CREATE_WORKSPACE_MUTATION, {
    onCompleted: (res) => {
      const newWorkspace = get(res, 'data.getWorkspaces.data', {});
      setWorkspaces([...workspaces, newWorkspace]);
    },
    onError: (err) => {},
  });

  return [createWorkspace, states];
};

export const useUpdateWorkspace = () => {
  const {
    state: { workspaces },
    setWorkspaces,
  } = useWorkspaceContext();

  const [updateWorkspace, state] = useMutation(UPDATE_WORKSPACE_MUTATION, {
    onCompleted: (res) => {
      const updatedWorkspace = get(res, 'updateWorkspace.data', {});
      if (updatedWorkspace) {
        // Update the global workspaces state
        const updatedWorkspaces = workspaces.map((workspace) =>
          workspace.id === updatedWorkspace.id ? updatedWorkspace : workspace,
        );
        setWorkspaces(updatedWorkspaces);
      }
    },
    onError: (err) => {},
  });

  return [updateWorkspace, state];
};

export const useRedirectToWorkspace = () => {
  const { workspaceId } = useWorkspaceId();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const { navigate } = useRouter();

  const redirectToWorkspace = async () => {
    if (workspaceId) {
      navigate(`/app/workspace/${workspaceId}/assets`);
    } else {
      const res = await fetchWorkspaces();
      const [workspace] = get(res, 'data.getWorkspaces.data', []);
      navigate(`/app/workspace/${workspaceId || workspace.id}/assets`);
    }
  };

  return { redirectToWorkspace };
};

export const getTotalSignatureUsed = (workspaces) => {
  if (!workspaces || !Array.isArray(workspaces)) {
    return 0;
  }

  const totalSignatureUsed = workspaces.reduce((total, workspace) => {
    const signatureUsed = workspace?.signatureUsed || 0;
    return total + signatureUsed;
  }, 0);

  return totalSignatureUsed;
};

export const useTotalSignatureWorkspace = () => {
  const {
    state: { workspaces },
  } = useWorkspaceContext();
  const totalSignatureUsed = getTotalSignatureUsed(workspaces);

  return { totalSignatureUsed };
};
