import { useAppContext } from 'AppContext';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import { useRouter } from 'hooks/router';
import { GET_PLANS } from 'modules/SubscriptionPage/graphql/Queries';
import LifeTimeAccessCard from './LifeTimeAccessCard';

const SubsciptionPlansLTD = ({
  bordered = true,
  plansToShow = ['2', '3', '4', '5'],
}) => {
  const {
    state: { plans, currentUser },
    setPlans,
    handleSubscriptionModal,
  } = useAppContext();
  const { navigate } = useRouter();

  const [getPlans] = useLazyQuery(GET_PLANS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const data = res?.getAllPlans;
      setPlans(data);
    },
    onError() {},
  });

  useEffect(() => {
    getPlans();
  }, []);

  const filterdPlans = plans
    ?.filter((plan) => plan?.id !== '1')
    ?.sort((a, b) => a.id.localeCompare(b.id))
    ?.filter((p) => plansToShow.includes(p.id));

  const activatedPlanIndex = filterdPlans?.findIndex(
    (p) => p.id === currentUser?.activePlan?.id,
  );

  const isPlanActivated = activatedPlanIndex !== -1;
  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] overflow-y-visible">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          <div className="mr-4">Plans</div>
        </div>
        <div
          // className={cn('grid gap-4 mx-auto px-4', {
          //   'lg:grid-cols-4': true, // 4 columns from the base (default)
          //   'md:grid-cols-2': true, // 2 columns on medium screens if more than 1 plan
          //   'sm:grid-cols-1': true, // 1 column on small screens
          // })}
          className={cn('grid gap-4 mx-auto px-4', {
            'grid-cols-4': plansToShow.length > 1,
            'sm:grid-cols-1': plansToShow.length > 1,
            'md:grid-cols-2': plansToShow.length > 1,
            'grid-cols-1': plansToShow.length === 1,
          })}
        >
          {filterdPlans?.map((plan) => (
            <LifeTimeAccessCard
              bordered={bordered}
              key={plan?.name}
              plan={plan}
              isPlanActivated={isPlanActivated}
            />
          ))}
          {plansToShow.length === 1 && (
            <div className="px-6 text-center">
              Looking for more signatures?{' '}
              <Button
                className="p-0"
                variant="link"
                onClick={() => {
                  navigate('/app/user/subscriptions');
                  handleSubscriptionModal(false);
                }}
              >
                <u>Explore more plans</u>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubsciptionPlansLTD;
