/* eslint-disable no-restricted-syntax */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { useRouter } from 'hooks/router';
import { MagicWandIcon, MegaPhoneIcon, NoteBookIcon } from 'assets/svg';
import { UserCircleIcon } from 'lucide-react';
import { get, isEqual, omit } from 'lodash';
import {
  useFetchWorkspaceTemplate,
  useUpdateSignatureByWorkspaceTemplate,
  useUpdateWorkspaceTemplate,
} from 'hooks/workspace-template';
import { useWorkspaceId } from 'hooks/workspace';
import { cloneAndRemoveTypename, uploadAllImages } from 'common/utils';
import LoaderComponent from 'components/LoaderComponent';
import { cn } from 'lib/utils';
import { useAppContext } from 'AppContext';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { toast } from 'hooks/use-toast';
import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';
import useComponentToHtml from 'helpers/useComponentToHtml';
import { useSetEmailSignatures } from 'hooks/google-marketplace';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { delay } from 'modules/AiHeadshot/components/HeadshotGenerator/HeadshotGenerator';
import { FIELDS_WITH_ICON } from 'common/constants';
import WorkspaceTemplateSidebar from './components/WorkspaceTemplateSidebar';
import WorkspaceTemplateInformation from './components/WorkspaceTemplateInformation';
import WorkspaceTempatePreview from './components/WorkspaceTempatePreview';
import WorkspaceTemplateDesign from './components/WorkspaceTemplateDesign';
import WorkspaceTemplateCallToAction from './components/WorkspaceTemplateCallToAction';

const items = [
  // {
  //   key: 'headshot',
  //   icon: UserCircleIcon,
  //   label: 'Headshot',
  //   disabled: false,
  // },
  {
    key: 'information',
    icon: NoteBookIcon,
    label: 'Information',
    disabled: false,
  },
  {
    key: 'design',
    icon: MagicWandIcon,
    label: 'Design',
    disabled: false,
  },
  {
    key: 'cta',
    icon: MegaPhoneIcon,
    label: 'Call to action',
    disabled: false,
  },
];

const TemplateDialog = forwardRef((props, ref) => {
  const {
    navigate,
    location: { pathname, search },
  } = useRouter();
  const {
    state: { baseImages },
  } = useAppContext();
  const {
    state: { groups, workspaceTeammates, isGoogleWorkspaceConnected },
  } = useWorkspaceContext();
  const { convertToHtml } = useComponentToHtml();

  const [isOpen, setIsOpen] = useState(false);
  const [workspaceTemplate, setWorkspaceTemplate] = useState(null);
  const [workspaceTemplateOld, setWorkspaceTemplateOld] = useState(null);

  const workspaceTemplateIconCount = workspaceTemplate?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );
  const oldWorkspaceTemplateIconCount = workspaceTemplateOld?.fields?.reduce(
    (acc, field) => {
      if (FIELDS_WITH_ICON.includes(field?.name)) {
        if (field.value) {
          return acc + 1;
        }
      }
      return acc;
    },
    0,
  );

  const isIconsChanged = !isEqual(
    workspaceTemplateIconCount,
    oldWorkspaceTemplateIconCount,
  );
  const isWorkspaceTemplateChanged = !isEqual(
    workspaceTemplate,
    workspaceTemplateOld,
  );
  const isDesignStyleChanged =
    !isEqual(workspaceTemplate?.design, workspaceTemplateOld?.design) ||
    isIconsChanged;

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setWorkspaceTemplate(null);
      setWorkspaceTemplateOld(null);
      setIsOpen(false);
      navigate('?');
    },
  }));

  const [fetchWorkspaceTemplate] = useFetchWorkspaceTemplate();
  const params = new URLSearchParams(search);
  const templateId = params.get('templateId');
  const workspaceTemplateId = params.get('workspaceTemplateId');

  const templateGroups = groups
    .filter((g) => g.templateId === workspaceTemplateId)
    .map((g) => g.id);

  const [activeTab, setActiveTab] = useState('information');
  const [installSignatureLoading, setInstallSignatureLoading] = useState(false);

  const [updateWorkspaceTemplate] = useUpdateWorkspaceTemplate();
  const [updateSignatureByWorkspaceTemplate] =
    useUpdateSignatureByWorkspaceTemplate();

  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const [setEmailSignatures] = useSetEmailSignatures();
  const { workspaceId } = useWorkspaceId();

  const [loading, setLoading] = useState(false);

  const handleSetWorkspaceTemplate = (data) => setWorkspaceTemplate(data);

  const fetchWorkspaceTeammates = async () => {
    const wtres = await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
    const newWt = get(wtres, 'data.getWorkspaceTeammateByWorkspaceId.data', []);
    const newWorkspaceTeammates = cloneAndRemoveTypename(newWt);
    return newWorkspaceTeammates;
  };

  const fetchCurrentWorkspaceTemplate = async () => {
    setLoading(true);
    const currTemplate = await fetchWorkspaceTemplate({
      variables: {
        where: {
          workspaceTemplateId,
        },
      },
    });
    setWorkspaceTemplate({
      ...cloneAndRemoveTypename(currTemplate?.data?.getWorkspaceTemplate?.data),
    });
    setWorkspaceTemplateOld({
      ...cloneAndRemoveTypename(currTemplate?.data?.getWorkspaceTemplate?.data),
    });
    setLoading(false);
  };

  useEffect(() => {
    if (workspaceTemplateId) {
      fetchCurrentWorkspaceTemplate();
    }
  }, [workspaceTemplateId, templateId]);

  const renderActiveComponent = () => {
    switch (activeTab) {
      case 'design':
        return (
          <WorkspaceTemplateDesign
            workspaceTemplate={workspaceTemplate}
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
          />
        );
      case 'cta':
        return (
          <WorkspaceTemplateCallToAction
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
      case 'information':
      default:
        return (
          <WorkspaceTemplateInformation
            handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
            workspaceTemplate={workspaceTemplate}
          />
        );
    }
  };

  // if (!workspaceTemplate) {
  //   return <div>Loading</div>;
  // }
  const handleSaveWorkspaceTemplate = async () => {
    try {
      // Clone the workspace template and remove unnecessary fields
      const formatedWorkspaceTemplate = omit(
        cloneAndRemoveTypename(workspaceTemplate),
        ['id', 'workspaceId'],
      );

      // Upload all images and get the response
      if (isDesignStyleChanged) {
        const response = await uploadAllImages(
          baseImages,
          workspaceTemplate?.id,
        );
        formatedWorkspaceTemplate.design.icons = response;
      }

      // Update the workspace template with the formatted data
      await updateWorkspaceTemplate({
        variables: {
          data: { ...formatedWorkspaceTemplate },
          where: {
            templateId: workspaceTemplateId,
            workspaceId,
          },
        },
      });

      // Close the modal or ref after success
      ref.current.close();

      // Success message (optional)
      toast({
        closeicn: 'success',
        description: 'Workspace template saved successfully!',
      });
    } catch (error) {
      // Handle errors and show an error message
      toast({
        closeicn: 'destructive',
        description: `Failed to save the workspace template`,
      });
    }
    // const newWt = await fetchWorkspaceTeammates();
    // return newWt;
  };

  const handlePublishWorkspaceTemplate = async () => {
    await updateSignatureByWorkspaceTemplate({
      variables: {
        where: {
          templateId: workspaceTemplateId,
        },
      },
    });
    // Close the modal or ref after success
    ref.current.close();
  };

  return (
    <>
      {/* <TemplatesModel
        handleTemplateSelect={handleTemplateRedirect}
        setOpen={setShowTemplateDialog}
        open={showTemplateDialog}
      /> */}
      {isOpen && (
        <Dialog open={isOpen} onOpenChange={() => ref?.current?.close()}>
          <DialogContent
            className={cn(
              'rounded-[6px] max-w-[1360px] max-h-[calc(100vh_-_100px)] h-full grid grid-cols-[100px_400px_1fr] m-0 p-0 border-0 gap-0',
              { 'grid-cols-[100px_1fr]': loading },
            )}
            hideCloseButton
          >
            <div className="overflow-hidden  bg-white-0 rounded-[inherit]">
              <WorkspaceTemplateSidebar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                items={items}
              />
            </div>
            <>
              {loading ? (
                <LoaderComponent />
              ) : (
                <>
                  <div className=" w-full h-full overflow-auto bg-primary-foreground">
                    {workspaceTemplate && renderActiveComponent()}
                  </div>
                  <div className="bg-[#f5f5f5] rounded-[inherit]">
                    {workspaceTemplate && (
                      <WorkspaceTempatePreview
                        isWorkspaceTemplateChanged={isWorkspaceTemplateChanged}
                        handleSetWorkspaceTemplate={handleSetWorkspaceTemplate}
                        workspaceTemplate={workspaceTemplate}
                        installSignatureLoading={installSignatureLoading}
                        handlePublishWorkspaceTemplate={
                          handlePublishWorkspaceTemplate
                        }
                        handleSaveWorkspaceTemplate={async () => {
                          setInstallSignatureLoading(true);
                          await handleSaveWorkspaceTemplate();
                          // if (process.env.REACT_APP_ENV !== 'production') {
                          //   toast({
                          //     closeicn: 'success',
                          //     description: 'Signature installation in progress.',
                          //   });
                          //   await handleInstallSignature(newWt);
                          // }

                          setInstallSignatureLoading(false);
                        }}
                      />
                    )}
                  </div>
                </>
              )}{' '}
            </>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
});

export default TemplateDialog;
