import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useUpdateWorkspace } from 'hooks/workspace';
import { useAppContext } from 'AppContext';
import { toast } from 'hooks/use-toast';
import { useGetUserSignatureDetails } from 'hooks/user';

const EditWorkspaceDialog = forwardRef((_, ref) => {
  const {
    state: { currentUser },
  } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [originalSignatureLimit, setOriginalSignatureLimit] = useState(0);

  const { userUnassignedSignature } = useGetUserSignatureDetails();
  const [updateWorkspace] = useUpdateWorkspace();

  const schema = z.object({
    name: z.string().min(1, 'Workspace name is required'),
    signatureLimit: z
      .string()
      .min(1, 'Signature limit is required')
      .refine((val) => {
        const limit = parseInt(val, 10);
        return limit <= currentUser.signatureLimit;
      }, `Signature limit cannot exceed ${currentUser.signatureLimit}`),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      signatureLimit: '',
    },
  });

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
      setOriginalSignatureLimit(data?.signatureLimit || 0);
    },
    close: () => {
      setIsOpen(false);
      form.reset();
      setOriginalSignatureLimit(0);
    },
    onDataChange: (d) => {
      setData(d);
      setOriginalSignatureLimit(d?.signatureLimit || 0);
      form.reset({
        name: d?.name || '',
        signatureLimit: d?.signatureLimit?.toString() || '',
      });
    },
  }));

  const { handleSubmit, control, watch, reset } = form;

  const signatureLimit = watch('signatureLimit');
  const parsedSignatureLimit = parseInt(signatureLimit, 10) || 0;
  const remainingLimits =
    userUnassignedSignature + originalSignatureLimit - parsedSignatureLimit;

  useEffect(() => {
    if (isOpen) {
      reset({
        name: data?.name || '',
        signatureLimit: data?.signatureLimit?.toString() || '',
      });
    }
  }, [isOpen, data]);

  const handleContinue = async (values = {}) => {
    const res = await updateWorkspace({
      variables: {
        data: {
          ...values,
        },
        where: {
          id: data?.id,
        },
      },
    });
    if (res?.data) {
      toast({
        closeicn: 'success',
        description: 'Edit successfully',
      });
      setIsOpen(false);
    }
  };

  const onSubmit = (formData) => {
    handleContinue({
      name: formData.name,
      signatureLimit: parsedSignatureLimit,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(false)}>
      <DialogContent className="rounded-[6px] max-w-[600px]" hideCloseButton>
        <DialogHeader>
          <DialogTitle>Edit Workspace</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4 py-2 pb-4">
              <FormField
                name="name"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="name">Workspace name</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        className="h-[23px] py-1"
                        placeholder="My Workspace"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="signatureLimit"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="signatureLimit">
                      Signature Limit
                    </FormLabel>
                    <FormControl>
                      <Input
                        id="signatureLimit"
                        className="h-[23px] py-1"
                        placeholder="Signature Limit"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                    <p
                      className={`text-sm font-bold mt-1 text-right ${remainingLimits < 0 ? 'text-destructive' : ''}`}
                    >
                      Remaining limits: {remainingLimits}
                    </p>
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter>
              <div className="flex w-full justify-end gap-3 ">
                <Button
                  type="button"
                  className="w-[max-content]"
                  onClick={() => setIsOpen(false)}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </div>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default EditWorkspaceDialog;
