/* eslint-disable arrow-body-style */
import React from 'react';
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import { cn } from 'lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/ui/command';
import Divider from 'components/TemplatesModel/Templates/components/Divider';
import WorkspaceSetting from 'components/WorkspaceSetting';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';
import {
  BookCopy,
  BookCopyIcon,
  ChevronRight,
  TableProperties,
} from 'lucide-react';

const WorkspacePopover = ({
  open,
  setOpen,
  workspaces,
  selectedWorkspace,
  handleWorkspaceSelect,
  className,
  openCreateDialog,
  openEditDialog,
  navigateToAllWorkspaces, // Add this prop
}) => {
  const { role } = useGetRoleForWorkspace();
  const { isFeaturesAllowed } = useUserPermission();
  const maxVisibleWorkspaces = 5;
  const visibleWorkspaces = workspaces.slice(0, maxVisibleWorkspaces);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          aria-label="Select a team"
          className={cn(
            'w-[204px] h-[44px] rounded-[6px] px-[14px] py-[10px] border-1 border-solid border-secondary-400',
            className,
          )}
        >
          <Avatar className="mr-2 h-6 w-6 rounded bg-primary-foreground text-[12px] text-primary leading-[22px]">
            <AvatarFallback className="bg-primary-foreground">
              {selectedWorkspace?.name
                ?.split(' ')
                ?.slice(0, 2)
                ?.map((word) => word?.charAt(0)?.toUpperCase())
                ?.join('')}
            </AvatarFallback>
          </Avatar>
          {selectedWorkspace?.name}
          <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[204px] rounded-[6px] border-1 border-solid border-secondary-1100 p-0">
        <Command className="text-[14px]">
          {isFeaturesAllowed(FEATURE_KEYS.MULTIPLE_WORKSPACES) && (
            <CommandList>
              <CommandGroup>
                <CommandItem
                  onSelect={() => {
                    setOpen(false);
                    openCreateDialog();
                  }}
                >
                  <PlusCircledIcon className="mr-2 h-5 w-5" />
                  Create Workspace
                </CommandItem>
              </CommandGroup>
            </CommandList>
          )}
          <CommandList>
            <CommandGroup>
              {visibleWorkspaces.map((ws) => (
                <CommandItem
                  value={ws.id}
                  key={ws.id}
                  onSelect={() => handleWorkspaceSelect(ws)}
                  className="text-sm  "
                >
                  <Avatar className="mr-2 h-6 w-6 rounded bg-primary-foreground text-[12px] text-primary leading-[22px]">
                    <AvatarFallback className="bg-primary-foreground">
                      {ws?.name
                        ?.split(' ')
                        ?.slice(0, 2)
                        ?.map((word) => word?.charAt(0)?.toUpperCase())
                        ?.join('')}
                    </AvatarFallback>
                  </Avatar>
                  {ws.name}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      selectedWorkspace?.id === ws?.id
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          <Divider />
          <CommandSeparator />
          {[ROLES.OWNER, ROLES.ADMIN].includes(role) && (
            <CommandList>
              <CommandGroup>
                {workspaces.length > maxVisibleWorkspaces && (
                  <CommandItem
                    className="text-sm text-center cursor-pointer"
                    onSelect={() => {
                      setOpen(false);
                      navigateToAllWorkspaces(); // Navigate to view all workspaces
                    }}
                  >
                    <BookCopyIcon className="mr-2 h-5 w-5" /> All Workspaces
                  </CommandItem>
                )}
                <CommandItem
                  className="cursor-pointer"
                  onSelect={openEditDialog}
                >
                  <WorkspaceSetting />
                </CommandItem>
              </CommandGroup>
            </CommandList>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default WorkspacePopover;
