/* eslint-disable import/prefer-default-export */
import { Route } from 'react-router-dom';
import React from 'react';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { ROLES } from 'common/constants';

const RenderRoute = ({
  children,
  featuresPermission,
  fallbackComponent: Fallback = <div>No access</div>,
}) => {
  const { isFeaturesAllowed, role = 'TEAMMATE' } = useUserPermission();
  const userHasPermission = (requiredPermissions) => {
    if (requiredPermissions.length === 0) {
      return true;
    }

    if (isFeaturesAllowed(...requiredPermissions)) {
      return true;
    }

    return false; // Placeholder for actual permission checking logic
  };

  // eslint-disable-next-line no-nested-ternary
  return userHasPermission(featuresPermission) ? children : <Fallback />;
};
const checkPermission = (routePermission, userRole) => routePermission.includes(ROLES.ALL) || routePermission.includes(userRole);

const generateRoute = ({ path, component: Component, featuresPermission, fallbackComponent: FallbackComponent }) => (
  <Route
    key={path}
    path={path}
    element={
      <RenderRoute
        featuresPermission={featuresPermission}
        fallbackComponent={FallbackComponent}
      >
        <Component />
      </RenderRoute>
    }
  />
);

export const useGetRoutes = () => {
  const { role = 'ALL' } = useGetRoleForWorkspace();

  const getRoutes = (routes) =>
    routes
      .filter(({ permission }) => checkPermission(permission, role))
      .map(route => generateRoute(route));

  return { getRoutes };
};
