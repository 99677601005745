import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'hooks/router';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import { useWorkspaceId } from 'hooks/workspace';
import WorkspacePopover from './components/WorkspacePopover';
import CreateWorkspaceDialog from './components/CreateWorkspaceDialog';
import EditWorkspaceDialog from './components/EditWorkspaceDialog';

const WorkspaceSwitcher = ({ className }) => {
  const {
    state: { workspaces },
  } = useWorkspaceContext();
  const { location, navigate } = useRouter();
  const { workspaceId } = useWorkspaceId();
  const createWorkspaceDialogRef = useRef();
  const editWorkspaceDialogRef = useRef();

  const isUUID = (str) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  };

  const getNewPath = (newId) => {
    const pathSegments = location?.pathname?.split('/');
    const newPathSegments = pathSegments.map((segment) =>
      isUUID(segment) ? newId : segment,
    );
    return newPathSegments.join('/');
  };

  const replaceIdInPath = (newId) => {
    const newPath = getNewPath(newId);
    navigate(newPath);
  };

  const [open, setOpen] = useState(false);
  const initialWorkspace = workspaces?.find((w) => w.id === workspaceId);
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    initialWorkspace || null,
  );

  useEffect(() => {
    setSelectedWorkspace(initialWorkspace);
  }, [initialWorkspace]);

  const handleWorkspaceSelect = (ws) => {
    setSelectedWorkspace(ws);
    navigate(`/app/workspace/${ws?.id}/assets`);
    setOpen(false);
  };

  const handleEditDialog = () => {
    editWorkspaceDialogRef?.current?.open();
    editWorkspaceDialogRef?.current?.onDataChange(selectedWorkspace);
  };

  return (
    <>
      <CreateWorkspaceDialog ref={createWorkspaceDialogRef} />
      <EditWorkspaceDialog ref={editWorkspaceDialogRef} />
      <WorkspacePopover
        openCreateDialog={createWorkspaceDialogRef?.current?.open}
        openEditDialog={handleEditDialog}
        open={open}
        setOpen={setOpen}
        workspaces={workspaces}
        selectedWorkspace={selectedWorkspace}
        handleWorkspaceSelect={handleWorkspaceSelect}
        className={className}
        navigateToAllWorkspaces={() => {
          navigate('/app/user/account');
        }}
      />
    </>
  );
};

export default WorkspaceSwitcher;
