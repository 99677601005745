/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';

function TemplateStyleTwenty({ signature = {}, showAwsIcons = false }) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  return (
    <table
      // width={tableWidth}
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {getFieldData(fields, 'headshotUrl') && (
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  paddingRight: '14px',
                                }}
                              >
                                <tbody>
                                  <tr>
                                    {getFieldData(fields, 'headshotUrl') &&
                                      HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                        style: {
                                          textAlign: 'center',
                                          paddingBottom: '14px',
                                          paddingTop: '9px',
                                        },
                                      })}
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td>
                            <table
                              cellPadding="0"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                {getFieldData(fields, 'name') && (
                                  <tr>
                                    {Name({
                                      signature,
                                      data: getFieldData(fields, 'name'),
                                      style: {
                                        fontWeight: 700,
                                        fontFamily: 'inherit',
                                        textTransform: 'uppercase',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'position') && (
                                  <tr>
                                    {Position({
                                      signature,
                                      data: getFieldData(fields, 'position'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'department') && (
                                  <tr>
                                    {Department({
                                      signature,
                                      data: getFieldData(fields, 'department'),
                                      style: {
                                        fontFamily: 'inherit',
                                        paddingRight: '10px',
                                        borderLeft: '1px',
                                      },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyName') && (
                                  <tr>
                                    {CompanyName({
                                      signature,
                                      data: getFieldData(fields, 'companyName'),
                                      style: {
                                        fontFamily: 'inherit',
                                      },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'phone') &&
                                  getFieldData(fields, 'email') && (
                                    <tr>
                                      <td>
                                        <table
                                          cellPadding="0"
                                          cellSpacing="0"
                                          border="0"
                                          style={{
                                            borderCollapse: 'collapse',
                                            marginTop: '8px',
                                          }}
                                        >
                                          <tbody>
                                            <tr>
                                              {getFieldData(fields, 'phone') &&
                                                Phone({
                                                  signature,
                                                  showAwsIcons,
                                                  design,
                                                  data: getFieldData(
                                                    fields,
                                                    'phone',
                                                  ),
                                                  style: {
                                                    paddingRight: '8px',
                                                  },
                                                })}

                                              {getFieldData(fields, 'email') &&
                                                Email({
                                                  signature,
                                                  showAwsIcons,
                                                  design,
                                                  data: getFieldData(
                                                    fields,
                                                    'email',
                                                  ),
                                                })}
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )}

                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: { paddingBottom: '2px' },
                                    })}
                                  </tr>
                                )}
                                {getFieldData(fields, 'ctaUrl') &&
                                  getFieldData(fields, 'ctaTitle') && (
                                    <tr>
                                      {CallToAction({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: {
                                          ctaUrl: getFieldData(
                                            fields,
                                            'ctaUrl',
                                          ),
                                          ctaTitle: getFieldData(
                                            fields,
                                            'ctaTitle',
                                          ),
                                        },
                                        style: { justifyContent: 'left' },
                                      })}
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                {!allUrlsAreNull && (
                  <tr width={tableWidth}>
                    <Divider
                      signature={signature}
                      style={{ marginBottom: 12 }}
                    />
                  </tr>
                )}

                {!allUrlsAreNull && (
                  <tr>
                    <td colSpan={2}>
                      <table
                        width={tableWidth}
                        cellPadding="0"
                        cellSpacing=""
                        border="0"
                        role="presentation"
                        style={{
                          borderCollapse: 'collapse !important',
                          fontSize: 'inherit',
                        }}
                      >
                        <tr>
                          {!!socialFields?.length &&
                            SocialLinks({
                              signature,
                              showAwsIcons,
                              data: socialFields,
                              design,
                              style: { paddingBottom: '8px' },
                            })}

                          {getFieldData(fields, 'companyLogo') &&
                            CompanyLogo({
                              data: getFieldData(fields, 'companyLogo'),
                              design,
                              style: {
                                paddingBottom: '8px',
                              },
                              toProps: {
                                colSpan: 2,
                                align: 'right',
                                valign: 'center',
                              },
                            })}
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan={2}>
                    <table
                      cellPadding="0"
                      cellSpacing=""
                      border="0"
                      ref={extraContentRef}
                    >
                      <tbody>
                        <tr>
                          {getFieldData(fields, 'ctaBannerImg') &&
                            getFieldData(fields, 'ctaBannerUrl') &&
                            CallToActionBanner({
                              data: {
                                ctaBannerUrl: getFieldData(
                                  fields,
                                  'ctaBannerUrl',
                                ),
                                ctaBannerImg: getFieldData(
                                  fields,
                                  'ctaBannerImg',
                                ),
                              },
                              design,
                              style: { textAlign: 'left' },
                            })}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table
                      width={tableWidth}
                      cellPadding="0"
                      cellSpacing=""
                      border="0"
                      role="presentation"
                      style={{
                        borderCollapse: 'collapse !important',
                        fontSize: 'inherit',
                      }}
                    >
                      <tr>
                        {getFieldData(fields, 'footer') &&
                          Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleTwenty;
