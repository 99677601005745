import { useAppContext } from 'AppContext';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import Button from 'components/Button';
import { Input } from 'components/ui/input';
import { useSignatureId, useUpsertSignature } from 'hooks/signature';
import { useWorkspaceId } from 'hooks/workspace';
import { VERIFY_COUPON } from './graphql/Mutations';

const formSchema = z.object({
  code: z.string().nonempty({ message: 'Enter coupon code' }),
});

const ApplyCoupon = () => {
  const {
    state: { signature },
    setCurrentUser,
  } = useAppContext();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { code: '' },
  });
  const [upsertSignature] = useUpsertSignature();
  const [open, setOpen] = useState(false);
  const { signatureId } = useSignatureId();
  const { workspaceId } = useWorkspaceId()

  const [verifyCoupon] = useMutation(VERIFY_COUPON, {
    onCompleted(res) {
      setCurrentUser(res?.verifyCoupon?.data);
    },
    onError() { },
  });

  const closeModal = () => {
    setOpen(false);
    form.reset();
  };

  const onFinish = async (values) => {
    const { id, ...data } = signature;
    await upsertSignature({
      variables: {
        data,
        where: { signatureId ,workspaceId},
      },
    });
    const response = await verifyCoupon({
      variables: {
        data: {
          code: values.code,
        },
      },
    });
    if (response?.data) {
      closeModal();
    }
  };

  return (
    <div>
      <Button
        variant="outline"
        onClick={() => {
          setOpen(true);
        }}
        className="h-[40px]"
      // loading={signupLoading || socialLoading}
      >
        Redeem Code
      </Button>
      <Dialog open={open} onOpenChange={closeModal}>
        <DialogContent hideCloseButton className="sm:max-w-[400px] bg-[#f3edff] p-5 max-w-sm">
          <DialogHeader className="text-left">
            <DialogTitle className="text-medium-l font-primary mb-0">
              Get Lifetime access
            </DialogTitle>
          </DialogHeader>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onFinish)}
              form={form}
              initialValues={{ remember: true }}
              className="space-y-[27px]"
            >
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem className=" text-darkblue font-medium  text-[12px]">
                    <FormControl>
                      <Input
                        className="bg-primary-foreground py-1 rounded h-[23px] transition duration-300 focus-within hover:border-1 hover:border-primary hover:bg-primary-foreground pr-1 hover:shadow-custom"
                        placeholder="Enter coupon code"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                htmlType="submit"
                className="h-[40px] w-full  mt-2 "
              // loading={signupLoading || socialLoading}
              >
                Apply
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApplyCoupon;
