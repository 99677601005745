import React from 'react';
import { Button } from 'components/ui/button';
import pngBg from 'assets/png/png-bg.png';
import { cn } from 'lib/utils';

const ImageUploadPreview = ({
  imageUrl,
  onChange,
  className,
  text = 'Change Photo',
}) => (
  <div
    className={cn('relative rounded-lg h-[150px] overflow-hidden', className)}
  >
    <div
      style={{ backgroundImage: `url('${pngBg}')` }}
      className="flex  justify-center items-center h-full"
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="UploadedImage"
          className="h-[auto]  w-full max-w-[100px]"
        />
      ) : null}

      {imageUrl && (
        <div className="absolute inset-0  flex  justify-center items-center transition-opacity duration-300 backdrop-blur-sm bg-[black]/55 opacity-0 hover:opacity-100">
          <Button
            variant="outline"
            className="w-fit bg-[transparent] text-white-0 border-1 border-white-0 hover:text-white-0 hover:border-1 hover:border-white-0"
            onClick={onChange}
          >
            {text}
          </Button>
        </div>
      )}
    </div>
  </div>
);

export default ImageUploadPreview;
