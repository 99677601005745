import React from 'react';

import { dynamicTemplate } from 'components/TemplatesModel/utils/dynamicTemplate';

const SignatureTemplate = ({ signature = {}, showAwsIcons = false }) => {
  const TemplateComponent = dynamicTemplate({
    templateKey: signature?.template?.key,
  });

  return TemplateComponent ? (
    <TemplateComponent signature={signature} showAwsIcons={showAwsIcons} />
  ) : (
    ''
  );
};

export default SignatureTemplate;
