/* eslint-disable no-undef */
/* eslint-disable no-console */
import { useLazyQuery, useMutation } from '@apollo/client';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import {
  CONNECT_GOOGLE_MARKETPLACE_APP,
  REMOVE_ACCESS_TOKEN,
  REMOVE_EMAIL_SIGNATURES,
  SET_EMAIL_SIGNATURES,
} from 'modules/GoogleMarketplace/graphql/Mutations';
import {
  GET_VALID_ACCESS_TOKEN,
  GET_WORKSPACE_CONNECTION_STATUS,
} from 'modules/GoogleMarketplace/graphql/Queries';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

// Hook to get a valid access token
export const useGetValidAccessToken = () => {
  const [getValidAccessToken, { data, loading, error }] = useLazyQuery(
    GET_VALID_ACCESS_TOKEN,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        // setAccessToken(res?.getValidAccessToken);
      },
      onError() {
        // Handle error, optionally redirect or show a message
      },
    },
  );

  return [getValidAccessToken, { data, loading, error }];
};

export const useWorkspaceConnectionStatus = () => {
  const { setIsGoogleWorkspaceConnected } = useWorkspaceContext(); // Assuming your context has a setter for this state
  const [getWorkspaceConnectionStatus, { data, loading, error }] = useLazyQuery(
    GET_WORKSPACE_CONNECTION_STATUS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const isConnected = res?.getWorkspaceConnectionStatus?.isConnected;
        setIsGoogleWorkspaceConnected(isConnected);
      },
      onError: (err) => {
        console.error('Failed to check workspace connection status', err);
        setIsGoogleWorkspaceConnected(false); // Optionally handle the error state
      },
    },
  );

  return [getWorkspaceConnectionStatus, { data, loading, error }];
};

export const useRemoveAccessToken = () => {
  const [removeAccessToken, { data, loading, error }] = useMutation(
    REMOVE_ACCESS_TOKEN,
    {
      onCompleted: (res) => {
        console.log('Access token removed successfully', res);
      },
      onError: (err) => {
        console.error('Failed to remove access token', err);
      },
    },
  );

  return [removeAccessToken, { data, loading, error }];
};

// Hook to connect to Google Marketplace App
export const useConnectGoogleMarketplaceApp = () => {
  const [connectGoogleMarketplaceApp, { data, loading, error }] = useMutation(
    CONNECT_GOOGLE_MARKETPLACE_APP,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        console.log('Connection successful', res);
      },
      onError: (err) => {
        console.error('Connection failed', err);
      },
    },
  );

  return [connectGoogleMarketplaceApp, { data, loading, error }];
};

export const useSetEmailSignatures = () => {
  const [setEmailSignatures, { data, loading, error }] = useMutation(
    SET_EMAIL_SIGNATURES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        console.log('Email signatures set successfully', res);
      },
      onError: (err) => {
        console.error('Failed to set email signatures', err);
      },
    },
  );

  return [setEmailSignatures, { data, loading, error }];
};

export const useRemoveEmailSignatures = () => {
  const [removeEmailSignatures, { data, loading, error }] = useMutation(
    REMOVE_EMAIL_SIGNATURES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        console.log('Email signatures removed successfully', res);
      },
      onError: (err) => {
        console.error('Failed to remove email signatures', err);
      },
    },
  );

  return [removeEmailSignatures, { data, loading, error }];
};
export const useConvertComponentToDOM = () => {
  const containerRef = useRef(null);

  const convertComponentToDOM = (Component, props = {}) => {
    // Create a new DOM element to mount the component
    containerRef.current = document.createElement('div');

    // Render the component into the container
    ReactDOM.render(<Component {...props} />, containerRef.current);

    return containerRef.current;
  };

  const cleanup = () => {
    if (containerRef.current) {
      ReactDOM.unmountComponentAtNode(containerRef.current);
      containerRef.current.remove();
      containerRef.current = null;
    }
  };

  return { convertComponentToDOM, cleanup };
};
