import React from 'react';


function Footer({ data, style = {}, tdProps = {} }) {
  return (
    <td
      style={{
        color: '#7A7289',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '12px',
        // maxWidth: '500px',
        ...style,
      }}
      {...tdProps}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: data?.value }}
   />

  );
}

export default Footer;
